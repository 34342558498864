import IPage from "../../../../core/interfaces/page";
import SearchMain from "../../../components/search/SearchMain";




const SearchPage: React.FunctionComponent<IPage> = (props) => {

        
    return (
      <>
        {/* Register context provider will be located here  */}
  
        {/* <RegisterProvider initialState={initialState}>
          <RegisterMain></RegisterMain>
        </RegisterProvider> */}

        <SearchMain></SearchMain>
      </>
    );
  };
  export default SearchPage;