import { Radio } from "@mui/material";
import { styled } from "@mui/material/styles";
import { RadioProps } from "@mui/material/Radio";
import React from "react";

const BpIcon = styled("span")(({ theme }) => ({
  border: "1px solid rgba(203, 213, 225, 1)",
  borderRadius: "50%",
  width: 20,
  height: 20,
  "input:hover ~ &": {
    backgroundColor: "rgba(215, 225, 254, 1)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  border: "1px solid rgba(86, 86, 217, 1)",
  backgroundColor: "rgba(215, 225, 254, 1)",
  // backgroundImage: "rgba(215, 225, 254, 1)",
  "&::before": {
    display: "block",
    width: 18,
    height: 18,
    backgroundImage:
      "radial-gradient(rgba(86, 86, 217, 1),rgba(86, 86, 217, 1) 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "rgba(187, 201, 251, 1)",
  },
});
export function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
