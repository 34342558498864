import React, { PropsWithChildren } from "react";

type Props = PropsWithChildren & { className?: string };

const HomePageSpan: React.FunctionComponent<Props> = ({
  children,
  className,
}) => {
  return (
    <span
      style={{
        borderRadius: "5px",
        maxWidth: "max-content",
        cursor: "pointer",
      }}
      className={`hover-primary-effect border px-2 font-xsss fw-500 ${className}`}
    >
      {children}
    </span>
  );
};

export default HomePageSpan;
