import { Http } from "../../../core/network/network_manager";
import { ProfileInstructorReqModel } from "../../models/profile/profile_instructor_update_req_model";
import { ProfileInstructorUpdateResModel } from "../../models/profile/profile_instructor_update_res_model";
import { ProfileInstructorResModel } from "../../models/profile/profile_instructor_res_model";
import useAxiosPrivate from "../../../hooks/Auth/useAxiosPrivate";
//import { axiosPrivate } from "../../../core/network/axios";
import { ProfileStudentUpdateResModel } from "../../models/profile/profile_student_update_res_model";
import { ProfileStudentReqModel } from "../../models/profile/profile_student_update_req_model";
import { ProfileStudentResModel } from "../../models/profile/profile_student_res_model";
import { ProfileInstructorDescriptionDetailUpdateReqModel } from "../../models/profile/profile_instructor_description_detail_update_req_model";
import { ProfileInstructorSchoolHistoryUpdateReqModel } from "../../models/profile/profile_instructor_school_history_update_req_model";
import { ProfileInstructorWorkExperiencesUpdateReqModel } from "../../models/profile/profile_instructor_work_experiences_update_req_model";
import { ProfileStudentClassDetailUpdateReqModel } from "../../models/profile/profile_student_class_detail_update_req_model";
import { ProfileEmailConfirmationReqModel } from "../../models/profile/profile_email_confirmation_req_model";
import { ProfileEmailConfirmationCheckReqModel } from "../../models/profile/profile_email_confirmation_check_req_model";


  export default function useRemoteProfileDataSource() {
    const axiosPrivate = useAxiosPrivate(); 
     
    async function updateInstructorProfile(model: ProfileInstructorReqModel): Promise<ProfileInstructorUpdateResModel>  {
        const res = await axiosPrivate.post<ProfileInstructorUpdateResModel>("/Teacher/PostIDInfoUpdate",model);
        console.log(res);
        return res.data;
        // res.data.map((x) => this.mapToModel(x));
    }

    async function getMyProfileInstructor(): Promise<ProfileInstructorResModel>  {
        const res = await axiosPrivate.get<ProfileInstructorResModel>("/Teacher/GetMyProfile");
        console.log(res);
        return res.data;
        // res.data.map((x) => this.mapToModel(x));
    }

    async function updateStudentProfile(model: ProfileStudentReqModel): Promise<ProfileStudentUpdateResModel>  {
        const res = await axiosPrivate.post<ProfileStudentUpdateResModel>("/Student/PostIDInfoUpdate",model);
        console.log(res);
        return res.data;
        // res.data.map((x) => this.mapToModel(x));
    }

    async function getMyProfileStudent(): Promise<ProfileStudentResModel>  {
        const res = await axiosPrivate.get<ProfileStudentResModel>("/Student/GetMyProfile");
        console.log(res);
        return res.data;
        // res.data.map((x) => this.mapToModel(x));
    }
   

    async function updateInstructorProfileDesription(model: ProfileInstructorDescriptionDetailUpdateReqModel): Promise<any> {
        const res = await axiosPrivate.post<any>("/Teacher/PostDescriptionDetailUpdate",model);
        console.log(res);
        return res.data;
    }
    async function  updateInstructorProfileSchoolHistory(model: ProfileInstructorSchoolHistoryUpdateReqModel): Promise<any> {
        const res = await axiosPrivate.post<any>("/Teacher/PostTeacherSchoolHistory",model);
        console.log(res);
        return res.data;
    }
    async function updateInstructorProfileWorkExpriences(model: ProfileInstructorWorkExperiencesUpdateReqModel): Promise<any> {
        const res = await axiosPrivate.post<any>("/Teacher/PostTeacherWorkExperiences",model);
        console.log(res);
        return res.data;
    }
    async function updateStudentClassDetail(model: ProfileStudentClassDetailUpdateReqModel): Promise<any> {
        const res = await axiosPrivate.post<any>("/Student/PostClassDetailUpdate",model);
        console.log(res);
        return res.data;
    }
    async function updateProfileEmailConfirmation(model: ProfileEmailConfirmationReqModel, path: string): Promise<any> {
        const res = await axiosPrivate.post<any>("/"+path+"/PostEmailConfirmation",model);
        console.log(res);
        return res.data;
    }
    async function updateProfileEmailConfirmationCheck(model: ProfileEmailConfirmationCheckReqModel, path: string): Promise<any> {
        const res = await axiosPrivate.post<any>("/"+path+"/PostEmailConfirmationCheck",model);
        console.log(res);
        return res.data;
    }


      
  
    return {
        updateInstructorProfile,
        getMyProfileInstructor,
        updateStudentProfile,
        getMyProfileStudent,
        updateInstructorProfileDesription,
        updateInstructorProfileSchoolHistory,
        updateInstructorProfileWorkExpriences,
        updateStudentClassDetail,
        updateProfileEmailConfirmation,
        updateProfileEmailConfirmationCheck



    };
  }