import { ProfileInstructorSchoolHistoryUpdateReq } from "../../../domain/entities/profile/profile_instructor_school_history_update_req";

export class ProfileInstructorSchoolHistoryUpdateReqModel implements ProfileInstructorSchoolHistoryUpdateReq {
    
  schoolName: string;
  branch: string;
  level: number;

   constructor(schoolName:string,branch:string,level:number)
   {
    this.branch=branch;
    this.level=level;
    this.schoolName=schoolName;
   }
   
  }