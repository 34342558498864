import React from "react";
import { useState } from "react";

import { BorderlessButton } from "../Button";

import { HomePageSpan } from "./index";

type TMetric = {
  project: number;
  investment: number;
  globalDownload: number;
  fiveStarReviews: number;
};

const initialState: TMetric = {
  project: 400,
  investment: 600,
  globalDownload: 10,
  fiveStarReviews: 200,
};

const Metric: React.FunctionComponent = () => {
  const [metricstate, updateMetricState] = useState<TMetric>(initialState);

  return (
    <>
      <article className="metric-wrapper container mt-5 mt-sm-0">
        <div className="row mb-3">
          <div className="col-12 text-center text-sm-left">
            <HomePageSpan>A platform that delivers results</HomePageSpan>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-sm-6 text-center text-sm-left">
            <h1 className=" display1-size fw-600 ">
              Lorem ipsum dolor sit amet
            </h1>
            <p className="mt-3 font-sm ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              <br /> do eiusmod tempor incididunt ut labore et dolore magna
              aliqua.
            </p>
            <BorderlessButton className="border py-2 px-3 mt-5 text-neutral-600">
              Hakkimizda &rarr;
            </BorderlessButton>
          </div>

          <div className="col-12 col-sm-6 pl-2">
            {/* Metric First Line */}

            <div className="row mt-4 mt-sm-0">
              <div className="col-6">
                {/* Metrics-1  */}
                <div className="border-left">
                  <div className="ml-3">
                    <h1 className="text-primary-600 fw-600 fs-40">
                      {metricstate.project}+
                    </h1>
                    <h2 className="fw-600 font-xs">Projects completed</h2>
                    <p className="text-neutral-600 ">
                      We’ve helped build over 400 amazing projects.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                {/* Metric-2 */}

                <div className="border-left">
                  <div className="ml-3">
                    <h1 className="text-primary-600 fw-600 fs-40">
                      {metricstate.investment}%
                    </h1>
                    <h2 className="fw-600 font-xs">Return on investment</h2>
                    <p className="text-neutral-600 ">
                      Our customers have reported an average of ~600% ROI.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Metric Second Line */}

            <div className="row mt-5">
              <div className="col-6">
                {/* Metrics-3  */}
                <div className="border-left">
                  <div className="ml-3">
                    <h1 className="text-primary-600 fw-600 fs-40">
                      {metricstate.globalDownload}k
                    </h1>
                    <h2 className="fw-600 font-xs">Global downloads</h2>
                    <p className="text-neutral-600 ">
                      Our product has been downloaded over 100k times.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                {/* Metric-4 */}

                <div className="border-left">
                  <div className="ml-3">
                    <h1 className="text-primary-600 fw-600 fs-40">
                      {metricstate.fiveStarReviews}+
                    </h1>
                    <h2 className="fw-600 font-xs">5-star reviews</h2>
                    <p className="text-neutral-600 ">
                      We’re proud of our 5-star rating with over 200 reviews.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Metric;
