import React from "react";

import IPage from "../../core/interfaces/page";

// import { Outlet } from "react-router-dom";

// Children components
import PasswordResetRequest from "../components/password/PasswordResetRequest";
import PasswordResetNav from "../components/password/PasswordResetNav";
import PasswordResetForm from "../components/password/PasswordResetForm";
import PasswordResetSuccess from "../components/password/PasswordResetSuccess";

const PasswordPage: React.FunctionComponent<IPage> = (props) => {
  return (
    <>
      <div className="container-fluid bg-light vh-100 pt-10">
        <PasswordResetRequest />
        <PasswordResetNav />
        <PasswordResetForm />
        <PasswordResetSuccess />
      </div>
    </>
  );
};

export default PasswordPage;
