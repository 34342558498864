import { PrimaryButton } from "../Button";


// const SearchResult = () => {
  
//     return (
//       <>
//    {/* <div className="results-container">
    
   
      
//       <div className="results-results pr-5">
//         <div className="results-frame-listingsearchresultdesktop">
//           <div className="results-image">
//             <div className="results-badge">
//                <img
//                 src="/assets/images/figma-icon/award-02.svg"
//                 alt="award02I196"
//                 className="results-award02"
//               /> 
//               <span className="results-text TextxsMedium">
//                 <span>Rare find</span>
//               </span>
//             </div>
//           </div>
//           <div className="results-content">
//             <div className="results-textandbutton">
//               <div className="results-textandcategory">
//                 <div className="results-categories">
//                   <div className="results-badge1">
//                     <span className="results-text02 ">
//                       <span>Matematik</span>
//                     </span>
//                   </div>
//                   <div className="results-badge1">
//                     <span className="results-text02 TextsmMedium">
//                       <span>Geometri</span>
//                     </span>
//                   </div>
//                   <div className="results-badge1">
//                     <span className="results-text02 TextsmMedium">
//                       <span>Fizik</span>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="results-titleandsupportingtext">
//                   <div className="results-titleandbadge">
//                     <span className="results-text08">
//                       <span>Gülsüm Y.</span>
//                     </span>
//                     <img
//                       src="/assets/images/figma-icon/Verified_tick.svg"
//                       alt="Verifiedtick1967"
//                       className="results-verifiedtick"
//                     />
//                   </div>
//                   <span className="results-text10 TextmdRegular">
//                     <span>Unvan ve nerde çalıştığı yazar</span>
//                   </span>
//                 </div>
//               </div>
//               <div className="results-actions">
//                 <button className="results-button">
//                   <img
//                      src="/assets/images/figma-icon/Heart_icon.svg"
//                     alt="heartI196"
//                     className="results-heart"
//                   />
//                 </button>
                
//           <PrimaryButton className="px-3 py-2">Deneme Dersi Ayırt</PrimaryButton>
      
//               </div>
//             </div>
//             <div className="results-rating">
//               <div className="results-stars">
//                 <div className="results-staricon">
                 
//                   <div className="results-star">
//                     <img
//                       src="/assets/images/figma-icon/Star_icon.svg"
//                       alt="StarI196"
//                       className="results-star1"
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="results-textandsupportingtext">
//                 <span className="results-text14 TextmdMedium">
//                   <span>4.8</span>
//                 </span>
//                 <span className="results-text10 TextmdRegular">
//                   <span>44 reviews değerlendirme</span>
//                 </span>
//               </div>
//             </div>
//             <div className="results-details">
//               <div className="results-detailswrap">
//                 <div className="results-textandicon">
//                   <div className="results-briefcase02">
//                     <img
//                       src="/assets/images/figma-icon/Read_icon.svg"
//                       alt="briefcase02I196"
//                       className="results-briefcase021"
//                     />
//                   </div>
//                   <span className="results-text18 TextmdMedium">
//                     <span>8 yıl deneyim</span>
//                   </span>
//                 </div>
//                 <div className="results-textandicon1">
//                   <div className="results-bookopen01">
//                     <img
//                        src="/assets/images/figma-icon/Book_icon.svg"
//                       alt="bookopen01I196"
//                       className="results-bookopen011"
//                     />
//                   </div>
//                   <span className="results-text18 TextmdMedium">
//                     <span>Yüzyüze/Online</span>
//                   </span>
//                 </div>
//                 <div className="results-textandicon2">
//                   <img
//                     src="/assets/images/figma-icon/Comments_icon.svg"
//                     alt="comments21967"
//                     className="results-comments2"
//                   />
//                   <span className="results-text18 TextmdMedium1">
//                     <span>28 aktif öğrenci • 184 ders</span>
//                   </span>
//                 </div>
//               </div>
//               <div className="results-textandsupportingtext1">
//                 <span className="results-text08 TextxlSemibold">
//                   <span>$540</span>
//                 </span>
//                 <div className="results-textwrap">
//                   <span className="results-text10 TextmdRegular">
//                     <span>50 dakikalık ders</span>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="results-frame-listingsearchresultdesktop">
//           <div className="results-image">
//             <div className="results-badge">
//               {/* <img
//                 src="/assets/images/figma-icon/award-02.svg"
//                 alt="award02I196"
//                 className="results-award02"
//               /> 
//               <span className="results-text TextxsMedium">
//                 <span>Rare find</span>
//               </span>
//             </div>
//           </div>
//           <div className="results-content">
//             <div className="results-textandbutton">
//               <div className="results-textandcategory">
//                 <div className="results-categories">
//                   <div className="results-badge1">
//                     <span className="results-text02 ">
//                       <span>Matematik</span>
//                     </span>
//                   </div>
//                   <div className="results-badge1">
//                     <span className="results-text02 TextsmMedium">
//                       <span>Geometri</span>
//                     </span>
//                   </div>
//                   <div className="results-badge1">
//                     <span className="results-text02 TextsmMedium">
//                       <span>Fizik</span>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="results-titleandsupportingtext">
//                   <div className="results-titleandbadge">
//                     <span className="results-text08">
//                       <span>Gülsüm Y.</span>
//                     </span>
//                     <img
//                       src="/assets/images/figma-icon/Verified_tick.svg"
//                       alt="Verifiedtick1967"
//                       className="results-verifiedtick"
//                     />
//                   </div>
//                   <span className="results-text10 TextmdRegular">
//                     <span>Unvan ve nerde çalıştığı yazar</span>
//                   </span>
//                 </div>
//               </div>
//               <div className="results-actions">
//                 <button className="results-button">
//                   <img
//                      src="/assets/images/figma-icon/Heart_icon.svg"
//                     alt="heartI196"
//                     className="results-heart"
//                   />
//                 </button>
                
//           <PrimaryButton className="px-3 py-2">Deneme Dersi Ayırt</PrimaryButton>
      
//               </div>
//             </div>
//             <div className="results-rating">
//               <div className="results-stars">
//                 <div className="results-staricon">
                 
//                   <div className="results-star">
//                     <img
//                       src="/assets/images/figma-icon/Star_icon.svg"
//                       alt="StarI196"
//                       className="results-star1"
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="results-textandsupportingtext">
//                 <span className="results-text14 TextmdMedium">
//                   <span>4.8</span>
//                 </span>
//                 <span className="results-text10 TextmdRegular">
//                   <span>44 reviews değerlendirme</span>
//                 </span>
//               </div>
//             </div>
//             <div className="results-details">
//               <div className="results-detailswrap">
//                 <div className="results-textandicon">
//                   <div className="results-briefcase02">
//                     <img
//                       src="/assets/images/figma-icon/Read_icon.svg"
//                       alt="briefcase02I196"
//                       className="results-briefcase021"
//                     />
//                   </div>
//                   <span className="results-text18 TextmdMedium">
//                     <span>8 yıl deneyim</span>
//                   </span>
//                 </div>
//                 <div className="results-textandicon1">
//                   <div className="results-bookopen01">
//                     <img
//                        src="/assets/images/figma-icon/Book_icon.svg"
//                       alt="bookopen01I196"
//                       className="results-bookopen011"
//                     />
//                   </div>
//                   <span className="results-text18 TextmdMedium">
//                     <span>Yüzyüze/Online</span>
//                   </span>
//                 </div>
//                 <div className="results-textandicon2">
//                   <img
//                     src="/assets/images/figma-icon/Comments_icon.svg"
//                     alt="comments21967"
//                     className="results-comments2"
//                   />
//                   <span className="results-text18 TextmdMedium1">
//                     <span>28 aktif öğrenci • 184 ders</span>
//                   </span>
//                 </div>
//               </div>
//               <div className="results-textandsupportingtext1">
//                 <span className="results-text08 TextxlSemibold">
//                   <span>$540</span>
//                 </span>
//                 <div className="results-textwrap">
//                   <span className="results-text10 TextmdRegular">
//                     <span>50 dakikalık ders</span>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="results-frame-listingsearchresultdesktop">
//           <div className="results-image">
//             <div className="results-badge">
//               {/* <img
//                 src="/assets/images/figma-icon/award-02.svg"
//                 alt="award02I196"
//                 className="results-award02"
//               /> 
//               <span className="results-text TextxsMedium">
//                 <span>Rare find</span>
//               </span>
//             </div>
//           </div>
//           <div className="results-content">
//             <div className="results-textandbutton">
//               <div className="results-textandcategory">
//                 <div className="results-categories">
//                   <div className="results-badge1">
//                     <span className="results-text02 ">
//                       <span>Matematik</span>
//                     </span>
//                   </div>
//                   <div className="results-badge1">
//                     <span className="results-text02 TextsmMedium">
//                       <span>Geometri</span>
//                     </span>
//                   </div>
//                   <div className="results-badge1">
//                     <span className="results-text02 TextsmMedium">
//                       <span>Fizik</span>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="results-titleandsupportingtext">
//                   <div className="results-titleandbadge">
//                     <span className="results-text08">
//                       <span>Gülsüm Y.</span>
//                     </span>
//                     <img
//                       src="/assets/images/figma-icon/Verified_tick.svg"
//                       alt="Verifiedtick1967"
//                       className="results-verifiedtick"
//                     />
//                   </div>
//                   <span className="results-text10 TextmdRegular">
//                     <span>Unvan ve nerde çalıştığı yazar</span>
//                   </span>
//                 </div>
//               </div>
//               <div className="results-actions">
//                 <button className="results-button">
//                   <img
//                      src="/assets/images/figma-icon/Heart_icon.svg"
//                     alt="heartI196"
//                     className="results-heart"
//                   />
//                 </button>
                
//           <PrimaryButton className="px-3 py-2">Deneme Dersi Ayırt</PrimaryButton>
      
//               </div>
//             </div>
//             <div className="results-rating">
//               <div className="results-stars">
//                 <div className="results-staricon">
                 
//                   <div className="results-star">
//                     <img
//                       src="/assets/images/figma-icon/Star_icon.svg"
//                       alt="StarI196"
//                       className="results-star1"
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="results-textandsupportingtext">
//                 <span className="results-text14 TextmdMedium">
//                   <span>4.8</span>
//                 </span>
//                 <span className="results-text10 TextmdRegular">
//                   <span>44 reviews değerlendirme</span>
//                 </span>
//               </div>
//             </div>
//             <div className="results-details">
//               <div className="results-detailswrap">
//                 <div className="results-textandicon">
//                   <div className="results-briefcase02">
//                     <img
//                       src="/assets/images/figma-icon/Read_icon.svg"
//                       alt="briefcase02I196"
//                       className="results-briefcase021"
//                     />
//                   </div>
//                   <span className="results-text18 TextmdMedium">
//                     <span>8 yıl deneyim</span>
//                   </span>
//                 </div>
//                 <div className="results-textandicon1">
//                   <div className="results-bookopen01">
//                     <img
//                        src="/assets/images/figma-icon/Book_icon.svg"
//                       alt="bookopen01I196"
//                       className="results-bookopen011"
//                     />
//                   </div>
//                   <span className="results-text18 TextmdMedium">
//                     <span>Yüzyüze/Online</span>
//                   </span>
//                 </div>
//                 <div className="results-textandicon2">
//                   <img
//                     src="/assets/images/figma-icon/Comments_icon.svg"
//                     alt="comments21967"
//                     className="results-comments2"
//                   />
//                   <span className="results-text18 TextmdMedium1">
//                     <span>28 aktif öğrenci • 184 ders</span>
//                   </span>
//                 </div>
//               </div>
//               <div className="results-textandsupportingtext1">
//                 <span className="results-text08 TextxlSemibold">
//                   <span>$540</span>
//                 </span>
//                 <div className="results-textwrap">
//                   <span className="results-text10 TextmdRegular">
//                     <span>50 dakikalık ders</span>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
       
//       </div>
//     </div>
//     <div className="row mt-3 mb-1">
//         <div className="col-12 col-md-12">
//     <div className="d-flex justify-content-between flex-row bd-highlight mb-3">
//      <div className="bd-highlight">
//         <span className="pagination-text">
//         &larr; Previous
//         </span>
        
//         </div>
//         <div className=" bd-highlight">
//         <span className="pagination-active-text pl-1 pr-1">1</span>
//         <span className="pagination-text pl-2">2</span>
//         <span className="pagination-text pl-2">3</span>
//         <span className="pagination-text pl-2">...</span>
//         <span className="pagination-text pl-2">8</span>
//         <span className="pagination-text pl-2">9</span>
//         <span className="pagination-text pl-2">10</span>
//         </div>
//          <div className="pr-5 bd-highlight"><span className="pagination-text">Next &rarr;</span></div>
//       </div>
//       </div>
//     </div>
//      */}
    
     
//       </>
//     );
//   };

const SearchResult = () => {
  
    return (
        <>
<div className="row mt-4">
       
  <div className="col-12 col-md-12 pl-0 pr-5">
    <div className="card card-new" >
       
    <div className="d-flex flex-column bd-highlight">
              <div className="bd-highlight">
              <div className="d-flex justify-content-between p-2">

                 <div className="d-flex justify-content-start">
                 <img
                      src="https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?h=350&auto=compress&cs=tinysrgb"
                      alt="Verifiedtick1967"
                      className="search-results-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight">
                     <div className="d-flex flex-row bd-highlight">
                         <div className="bd-highlight"> <span className="results-text02 TextsmMedium">
                              <span>Geometri</span>
                            </span></div>
                             <div className="bd-highlight"><span className="results-text02 TextsmMedium">
                              <span>Geometri</span>
                            </span></div>
                        <div className="bd-highlight"><span className="results-text02 TextsmMedium">
                              <span>Geometri</span>
                            </span></div>
                            <div className="d-flex flex-wrap-reverse">
 
                         <div className="flex-shrink-1 bd-highlight ml-5">
                           <div className="d-flex bd-highlight ml-5">
                           <div className="flex-grow-1 bd-highlight ml-5">   
                          <div className="dflex align-items-center ml-4 ">
                   
                        <button className="results-button">
                             <img
                                  src="/assets/images/figma-icon/Heart_icon.svg"
                                    alt="heartI196"
                                    className=""
                                     />
                                    </button>
                          </div>
                             </div>
                     <div className="bd-highlight"> 
                     <PrimaryButton className="px-3 py-2">Deneme Dersi Ayırt</PrimaryButton>
                     </div>
                 </div>
                         </div>
  
                </div>
                        </div>
                        <div className="d-flex flex-row bd-highlight pl-2">
                         <div className="bd-highlight"> <span className="text-search-ex"> <span className="results-text08">
                                 <span>Gülsüm Y.</span>
                                  </span></span>
                                </div>
                             <div className="bd-highlight">
                                       <img
                         src="/assets/images/figma-icon/Verified_tick.svg"
                         alt="Verifiedtick1967"
                         className="pb-2 pl-1"
                         />
                            
                            </div>
                      
                      
                        </div>
                        <div className="d-flex flex-row bd-highlight pl-2">
                        
                             <div className="bd-highlight">
                                <span className="results-text10 TextmdRegular">
                    <span>Unvan ve nerde çalıştığı yazar</span>
                        </span>
                                
                                </div>
                      
                      
                        </div>
                    
                        <div className="d-flex flex-row bd-highlight mb-1">
                        
                             <div className="bd-highlight">
                             <img
                      src="/assets/images/figma-icon/Star_icon.svg"
                       alt="StarI196"
                     className="pb-1 pl-1"
                    />
                            
                            </div>
                            <div className="bd-highlight"> 
                                       <span className="results-text14">
                  <span>4.8 </span>
                </span>
                <span className="results-text10 pl-2">
                  <span>44 reviews değerlendirme</span>
                   </span>
                                
                                </div>
                      
                      
                        </div>

                           <div className="d-flex flex-row bd-highlight mb-1">    
                        <img
                        src="/assets/images/figma-icon/Book_icon.svg"
                      alt="bookopen01I196"
                       className="pl-2"
                        />
                        <span className="results-text18 pl-2">
                    <span>8 yıl deneyim</span>
                        </span>

                        <img
                       src="/assets/images/figma-icon/Book_icon.svg"
                       alt="bookopen01I196"
                        className="pl-2"
                       />
                            <span className="results-text18 pl-2">
                    <span>Yüzyüze/Online</span>
                   </span>
                   <img
                     src="/assets/images/figma-icon/Comments_icon.svg"
                   alt="comments21967"
                   className="pl-2"
                 />
                 <span className="results-text18 pl-2">
                    <span>28 aktif öğrenci • 184 ders</span>
                  </span>
                  <div className="d-flex flex-wrap pl-2">
                     <div className="flex-shrink-1 bd-highlight pl-2">  <span className="results-text08 TextxlSemibold">
                   <span>$540</span>
                  </span></div>
                       <div className="flex-shrink-1 bd-highlight pl-2"> <span className="results-text10 TextmdRegular">
                    <span>50 dakikalık ders</span>
                   </span></div>
                       
  
                      </div>
                        </div>
                     </div>
                    </div>

                 
              
                </div>
                </div>

                
               
            </div>
       
       
         
    </div>
    <div className="card card-new mt-2" >
       
       <div className="d-flex flex-column bd-highlight">
                 <div className="bd-highlight">
                 <div className="d-flex justify-content-between p-2">
   
                    <div className="d-flex justify-content-start">
                    <img
                         src="https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?h=350&auto=compress&cs=tinysrgb"
                         alt="Verifiedtick1967"
                         className="search-results-image mr-2"
                       />
                        <div className="d-flex flex-column bd-highlight">
                        <div className="d-flex flex-row bd-highlight">
                            <div className="bd-highlight"> <span className="results-text02 TextsmMedium">
                                 <span>Geometri</span>
                               </span></div>
                                <div className="bd-highlight"><span className="results-text02 TextsmMedium">
                                 <span>Geometri</span>
                               </span></div>
                           <div className="bd-highlight"><span className="results-text02 TextsmMedium">
                                 <span>Geometri</span>
                               </span></div>
                               <div className="d-flex flex-wrap-reverse">
    
                            <div className="flex-shrink-1 bd-highlight ml-5">
                              <div className="d-flex bd-highlight ml-5">
                              <div className="flex-grow-1 bd-highlight ml-5">   
                             <div className="dflex align-items-center ml-4 ">
                      
                           <button className="results-button">
                                <img
                                     src="/assets/images/figma-icon/Heart_icon.svg"
                                       alt="heartI196"
                                       className=""
                                        />
                                       </button>
                             </div>
                                </div>
                        <div className="bd-highlight"> 
                        <PrimaryButton className="px-3 py-2">Deneme Dersi Ayırt</PrimaryButton>
                        </div>
                    </div>
                            </div>
     
                   </div>
                           </div>
                           <div className="d-flex flex-row bd-highlight pl-2">
                            <div className="bd-highlight"> <span className="text-search-ex"> <span className="results-text08">
                                    <span>Gülsüm Y.</span>
                                     </span></span>
                                   </div>
                                <div className="bd-highlight">
                                          <img
                            src="/assets/images/figma-icon/Verified_tick.svg"
                            alt="Verifiedtick1967"
                            className="pb-2 pl-1"
                            />
                               
                               </div>
                         
                         
                           </div>
                           <div className="d-flex flex-row bd-highlight pl-2">
                           
                                <div className="bd-highlight">
                                   <span className="results-text10 TextmdRegular">
                       <span>Unvan ve nerde çalıştığı yazar</span>
                           </span>
                                   
                                   </div>
                         
                         
                           </div>
                       
                           <div className="d-flex flex-row bd-highlight mb-1">
                           
                                <div className="bd-highlight">
                                <img
                         src="/assets/images/figma-icon/Star_icon.svg"
                          alt="StarI196"
                        className="pb-1 pl-1"
                       />
                               
                               </div>
                               <div className="bd-highlight"> 
                                          <span className="results-text14">
                     <span>4.8 </span>
                   </span>
                   <span className="results-text10 pl-2">
                     <span>44 reviews değerlendirme</span>
                      </span>
                                   
                                   </div>
                         
                         
                           </div>
   
                              <div className="d-flex flex-row bd-highlight mb-1">    
                           <img
                           src="/assets/images/figma-icon/Book_icon.svg"
                         alt="bookopen01I196"
                          className="pl-2"
                           />
                           <span className="results-text18 pl-2">
                       <span>8 yıl deneyim</span>
                           </span>
   
                           <img
                          src="/assets/images/figma-icon/Book_icon.svg"
                          alt="bookopen01I196"
                           className="pl-2"
                          />
                               <span className="results-text18 pl-2">
                       <span>Yüzyüze/Online</span>
                      </span>
                      <img
                        src="/assets/images/figma-icon/Comments_icon.svg"
                      alt="comments21967"
                      className="pl-2"
                    />
                    <span className="results-text18 pl-2">
                       <span>28 aktif öğrenci • 184 ders</span>
                     </span>
                     <div className="d-flex flex-wrap pl-2">
                        <div className="flex-shrink-1 bd-highlight pl-2">  <span className="results-text08 TextxlSemibold">
                      <span>$540</span>
                     </span></div>
                          <div className="flex-shrink-1 bd-highlight pl-2"> <span className="results-text10 TextmdRegular">
                       <span>50 dakikalık ders</span>
                      </span></div>
                          
     
                         </div>
                           </div>
                        </div>
                       </div>
   
                    
                 
                   </div>
                   </div>
   
                   
                  
               </div>
          
          
            
       </div>
       <div className="card card-new mt-2" >
       
       <div className="d-flex flex-column bd-highlight">
                 <div className="bd-highlight">
                 <div className="d-flex justify-content-between p-2">
   
                    <div className="d-flex justify-content-start">
                    <img
                         src="https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?h=350&auto=compress&cs=tinysrgb"
                         alt="Verifiedtick1967"
                         className="search-results-image mr-2"
                       />
                        <div className="d-flex flex-column bd-highlight">
                        <div className="d-flex flex-row bd-highlight">
                            <div className="bd-highlight"> <span className="results-text02 TextsmMedium">
                                 <span>Geometri</span>
                               </span></div>
                                <div className="bd-highlight"><span className="results-text02 TextsmMedium">
                                 <span>Geometri</span>
                               </span></div>
                           <div className="bd-highlight"><span className="results-text02 TextsmMedium">
                                 <span>Geometri</span>
                               </span></div>
                               <div className="d-flex flex-wrap-reverse">
    
                            <div className="flex-shrink-1 bd-highlight ml-5">
                              <div className="d-flex bd-highlight ml-5">
                              <div className="flex-grow-1 bd-highlight ml-5">   
                             <div className="dflex align-items-center ml-4 ">
                      
                           <button className="results-button">
                                <img
                                     src="/assets/images/figma-icon/Heart_icon.svg"
                                       alt="heartI196"
                                       className=""
                                        />
                                       </button>
                             </div>
                                </div>
                        <div className="bd-highlight"> 
                        <PrimaryButton className="px-3 py-2">Deneme Dersi Ayırt</PrimaryButton>
                        </div>
                    </div>
                            </div>
     
                   </div>
                           </div>
                           <div className="d-flex flex-row bd-highlight pl-2">
                            <div className="bd-highlight"> <span className="text-search-ex"> <span className="results-text08">
                                    <span>Gülsüm Y.</span>
                                     </span></span>
                                   </div>
                                <div className="bd-highlight">
                                          <img
                            src="/assets/images/figma-icon/Verified_tick.svg"
                            alt="Verifiedtick1967"
                            className="pb-2 pl-1"
                            />
                               
                               </div>
                         
                         
                           </div>
                           <div className="d-flex flex-row bd-highlight pl-2">
                           
                                <div className="bd-highlight">
                                   <span className="results-text10 TextmdRegular">
                       <span>Unvan ve nerde çalıştığı yazar</span>
                           </span>
                                   
                                   </div>
                         
                         
                           </div>
                       
                           <div className="d-flex flex-row bd-highlight mb-1">
                           
                                <div className="bd-highlight">
                                <img
                         src="/assets/images/figma-icon/Star_icon.svg"
                          alt="StarI196"
                        className="pb-1 pl-1"
                       />
                               
                               </div>
                               <div className="bd-highlight"> 
                                          <span className="results-text14">
                     <span>4.8 </span>
                   </span>
                   <span className="results-text10 pl-2">
                     <span>44 reviews değerlendirme</span>
                      </span>
                                   
                                   </div>
                         
                         
                           </div>
   
                              <div className="d-flex flex-row bd-highlight mb-1">    
                           <img
                           src="/assets/images/figma-icon/Book_icon.svg"
                         alt="bookopen01I196"
                          className="pl-2"
                           />
                           <span className="results-text18 pl-2">
                       <span>8 yıl deneyim</span>
                           </span>
   
                           <img
                          src="/assets/images/figma-icon/Book_icon.svg"
                          alt="bookopen01I196"
                           className="pl-2"
                          />
                               <span className="results-text18 pl-2">
                       <span>Yüzyüze/Online</span>
                      </span>
                      <img
                        src="/assets/images/figma-icon/Comments_icon.svg"
                      alt="comments21967"
                      className="pl-2"
                    />
                    <span className="results-text18 pl-2">
                       <span>28 aktif öğrenci • 184 ders</span>
                     </span>
                     <div className="d-flex flex-wrap pl-2">
                        <div className="flex-shrink-1 bd-highlight pl-2">  <span className="results-text08 TextxlSemibold">
                      <span>$540</span>
                     </span></div>
                          <div className="flex-shrink-1 bd-highlight pl-2"> <span className="results-text10 TextmdRegular">
                       <span>50 dakikalık ders</span>
                      </span></div>
                          
     
                         </div>
                           </div>
                        </div>
                       </div>
   
                    
                 
                   </div>
                   </div>
   
                   
                  
               </div>
          
          
            
       </div>
    </div>
   </div>             
       <div className="row mt-3 mb-1">
        <div className="col-12 col-md-12">
     <div className="d-flex justify-content-between flex-row bd-highlight mb-3">
     <div className="bd-highlight">
        <span className="pagination-text">
        &larr; Previous
        </span>
        
        </div>
        <div className=" bd-highlight">
        <span className="pagination-active-text pl-1 pr-1">1</span>
        <span className="pagination-text pl-2">2</span>
        <span className="pagination-text pl-2">3</span>
        <span className="pagination-text pl-2">...</span>
        <span className="pagination-text pl-2">8</span>
        <span className="pagination-text pl-2">9</span>
        <span className="pagination-text pl-2">10</span>
        </div>
         <div className="pr-5 bd-highlight"><span className="pagination-text">Next &rarr;</span></div>
                </div>
       </div>
    </div>
        
 
        
        </>

    );

};

  
  export default SearchResult;


 
