import { useState } from "react";
import useLoading from "../../../../hooks/userLoading";
import { useNavigate } from "react-router-dom";
import ProfileRepositoryImpl from "../../../../infrastructure/repositories/profile_repository_impl";
import { ProfileInstructorReqModel } from "../../../../infrastructure/models/profile/profile_instructor_update_req_model";
import { ProfileStudentReqModel } from "../../../../infrastructure/models/profile/profile_student_update_req_model";
import useAuth from "../../../../hooks/Auth/useAuth";
import RemoteProfileDataSource from "../../../../infrastructure/data_sources/profile/remote_profile_data_source";
import useRemoteProfileDataSource from "../../../../infrastructure/data_sources/profile/remote_profile_data_source";
import { ProfileInstructorDescriptionDetailUpdateReqModel } from "../../../../infrastructure/models/profile/profile_instructor_description_detail_update_req_model";
import { ProfileInstructorSchoolHistoryUpdateReqModel } from "../../../../infrastructure/models/profile/profile_instructor_school_history_update_req_model";
import { ProfileInstructorWorkExperiencesUpdateReqModel } from "../../../../infrastructure/models/profile/profile_instructor_work_experiences_update_req_model";
import { ProfileStudentClassDetailUpdateReqModel } from "../../../../infrastructure/models/profile/profile_student_class_detail_update_req_model";
import { FormValues } from "../../../components/onboarding/OnBoardingForm";

export default function OnBoardingViewModel() {
    const { setLoading } = useLoading();
    const navigate = useNavigate();
    const {auth}  = useAuth();
    const profile = useRemoteProfileDataSource();
     
    async function updateInstructorProfile(model:ProfileInstructorReqModel) {
      setLoading({isLoading: true,isError: false,message:""} );
      return await profile.updateInstructorProfile(model).then((res)=>{
        setLoading({isLoading: false,isError: res.isError,message:res.message+" "+res.obj??"" });
        //!res.isError ? navigate("/profile") : navigate("/onboarding");
         return res;
      });
    }

    async function updateStudentProfile(model:ProfileStudentReqModel) {
      setLoading({isLoading: true,isError: false,message:""} );
        return await profile.updateStudentProfile(model).then((res)=>{
          setLoading({isLoading: false,isError: res.isError,message:res.message+" "+res.obj??"" });
          !res.isError ? navigate("/profile",{state:res}) : navigate("/onboarding");
          return res;
        });
      }


      async function updateInstructorProfileDesription(model:ProfileInstructorDescriptionDetailUpdateReqModel) {
        setLoading({isLoading: true,isError: false,message:""} );
        return await profile.updateInstructorProfileDesription(model).then((res)=>{
          setLoading({isLoading: false,isError: res.isError,message:res.message+" "+res.obj??"" });
          //!res.isError ? navigate("/profile") : navigate("/onboarding");
           return res;
        });
      }

      async function updateInstructorProfileSchoolHistory(model:ProfileInstructorSchoolHistoryUpdateReqModel) {
        setLoading({isLoading: true,isError: false,message:""} );
        return await profile.updateInstructorProfileSchoolHistory(model).then((res)=>{
          setLoading({isLoading: false,isError: res.isError,message:res.message+" "+res.obj??"" });
          !res.isError ? navigate("/profile") : navigate("/onboarding");
           return res;
        });
      }

      async function updateInstructorProfileWorkExpriences(model:ProfileInstructorWorkExperiencesUpdateReqModel) {
        setLoading({isLoading: true,isError: false,message:""} );
        return await profile.updateInstructorProfileWorkExpriences(model).then((res)=>{
          setLoading({isLoading: false,isError: res.isError,message:res.message+" "+res.obj??"" });
          !res.isError ? navigate("/profile") : navigate("/onboarding");
           return res;
        });
      }

      async function updateStudentClassDetail(model:ProfileStudentClassDetailUpdateReqModel) {
        setLoading({isLoading: true,isError: false,message:""} );
        return await profile.updateStudentClassDetail(model).then((res)=>{
          setLoading({isLoading: false,isError: res.isError,message:res.message+" "+res.obj??"" });
          !res.isError ? navigate("/profile") : navigate("/onboarding");
           return res;
        });
      }

   
    const updateProfile=async (data:FormValues)=>{
         
          if(auth?.roles[0]=="Teacher"){

            await updateInstructorProfile(new ProfileInstructorReqModel(data.name_,data.surname,String(data.tckn),new Date(data.birhtDay),Number(data.country),Number(data.gender)));
            await updateInstructorProfileDesription(new ProfileInstructorDescriptionDetailUpdateReqModel(data.title,data.about));
            await updateInstructorProfileSchoolHistory(new ProfileInstructorSchoolHistoryUpdateReqModel(data.school,data.title,data.experience.year))
            //await updateInstructorProfileWorkExpriences(new ProfileInstructorWorkExperiencesUpdateReqModel(data.school,data.title,data.about,data.experience.year,data.experience.month,data.experience.year,data.experience.month))
   
          }
          else{

            await updateStudentProfile(new ProfileStudentReqModel(data.name_,data.surname,String(data.tckn),new Date(data.birhtDay),Number(data.country),Number(data.gender)));
            await updateStudentClassDetail(new ProfileStudentClassDetailUpdateReqModel(Number(data.class),data.field,data.school))
          }
           


          
      }

   
      
  
    return {
        updateProfile

    };
  }