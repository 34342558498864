import React, { useState } from "react";

import { CreateNewEposide } from "../sections/CreateNewEposide";
import { EpisodePresentation } from "../sections/EpisodePresentation";

import { CourseEpisodeType } from "../../../pages/course/create-course";

export interface CreateCourseSectionProps {
  episodesState: CourseEpisodeType[];
  episodesDispatch: React.Dispatch<
    React.SetStateAction<CourseEpisodeType[] | []>
  >;
}

const CreateCourseSection: React.FunctionComponent<
  CreateCourseSectionProps
> = ({ episodesState, episodesDispatch }) => {
  // While tutor creating new section , this state is updated to true
  //  while state is true user see create episode section
  const [isCreateNewSectionActive, setIsCreateNewSectionActive] =
    useState<boolean>(false);

  //   if createepisode active show create new episode component otherwise show episode presentation if there is no episode created yet user will see noepisode component or else see the episode list
  return (
    <>
      {!isCreateNewSectionActive ? (
        <EpisodePresentation
          episodesState={episodesState}
          episodesDispatch={episodesDispatch}
          setIsCreateNewSectionActive={setIsCreateNewSectionActive}
        />
      ) : (
        <CreateNewEposide
          episodesState={episodesState}
          episodesDispatch={episodesDispatch}
          setIsCreateNewSectionActive={setIsCreateNewSectionActive}
        />
      )}
    </>
  );
};

export default CreateCourseSection;
