import React from "react";
import {
  Accordion as MAUAccordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
} from "@mui/joy";

type TAccordionArray = {
  summary: string;
  details: string;
};

interface Props {
  sourceArray: TAccordionArray[];
}

const Accordion: React.FunctionComponent<Props> = ({ sourceArray }) => {
  const [index, setIndex] = React.useState<number | null>(0);
  const [dynamicsx, setDynamicSx] = React.useState({
    marginBottom: "3rem",
    backgroundColor: "white",
  });
  return (
    <>
      <AccordionGroup disableDivider>
        {sourceArray.map((item, _localIndex) => {
          return (
            <MAUAccordion
              key={_localIndex}
              sx={dynamicsx}
              expanded={index === _localIndex}
              onChange={(event, expanded) => {
                // expanded triggers to color
                setDynamicSx((previous) =>
                  expanded
                    ? {
                        ...previous,
                        backgroundColor: "hsla(210, 40%, 98%, 1)",
                      }
                    : {
                        ...previous,
                        backgroundColor: "white",
                      }
                );

                setIndex(expanded ? _localIndex : null);
              }}
            >
              <AccordionSummary>{item.summary}</AccordionSummary>
              <AccordionDetails>{item.details}</AccordionDetails>
            </MAUAccordion>
          );
        })}
      </AccordionGroup>
    </>
  );
};

export default Accordion;
