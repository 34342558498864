import { Button, Dropdown, IconButton, Menu, MenuButton, MenuItem, ToggleButtonGroup } from "@mui/joy";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useToogleGroupButton } from "../../../hooks/useToogleGroupButton";
import { BorderlessButton, PrimaryButton } from "../Button";
import Navbar from "../NavHeader";
import List from '@mui/icons-material/List';
import ViewCozy from '@mui/icons-material/ViewCozy';
import SearchResult from "./SearchResult";
import SearchCard from "./SearchCard";

const SearchMain = () => {
  
  return (
    <>
    <Navbar></Navbar>
   <nav className="container">
        <div className="row">
            <div className="col-xs-12 pt-5">
                <p className="text-dark-new">
                  Matematik alanında 1.000'den fazla öğretmen
                </p>
                 <p className="text-dark-support m-0 py-2">
                            Öğrenme deneyiminizi onaylı öğretmenlerimiz ile güçlendirin.
                 </p>
            </div>
         
          
        </div>
     
        
      <div className="row mt-4">
          <div className="col-12 col-md-9 pl-0" >
          <Dropdown>
                  <MenuButton
                    sx={{
                  
                    borderRadius: "10px" ,
                    border: "2px solid var(--clr-neutral-300)" ,
                    color:"var (--clr-neutral-300)"
                }}
                    variant="plain"
                   
                  >
                    <div className="row">
                        <div className="col-12">
                    <span className="text-dark-support" style={{color:"var(--clr-neutral-500)"}}> &#36; Ders Ücreti</span>
                    <span className="feather-chevron-down" style={{color:"var(--clr-neutral-500)"}}> </span>
                    </div>
                    </div>
                  </MenuButton>
                  <Menu variant="plain" color="neutral">
                  <MenuItem >
                          <Link
                             style={{ color: "inherit", textDecoration: "none" }}
                             to="/"
                          >
                    
                          </Link>
                        </MenuItem>
                  </Menu>
          </Dropdown>
          <Dropdown >
                  <MenuButton
                    sx={{
                    
                        left:"3px",
                    borderRadius: "10px" ,
                    border: "2px solid var(--clr-neutral-300)" ,
                    color:"var (--clr-neutral-300)"
                }}
                    variant="plain"
                   
                  >
                    <div className="row">
                        <div className="col-12">
                    <span className="text-dark-support" style={{color:"var(--clr-neutral-500)"}}> &#9734; Puan</span>
                    <span className="feather-chevron-down" style={{color:"var(--clr-neutral-500)"}}> </span>
                    </div>
                    </div>
                  </MenuButton>
                  <Menu variant="plain" color="neutral">

                      <div className="col-12 col-md-12 card-body  card-new">

                          <MenuItem >
                          <Link
                            style={{ color: "inherit", textDecoration: "none" }}
                            to="/"
                          >
                                    <div className="form-check">
  <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
  <label className="form-check-label">
  <div className="bd-highlight">
                             <img
                      src="/assets/images/figma-icon/Star_icon.svg"
                       alt="StarI196"
                     className="pb-1 pl-1"
                    />
                      <span className="results-text14">
                  <span>4.8 </span>
                 
                </span>
                <span> (200)</span>
          
                                
                                </div>
  </label>
                          </div>
                          </Link>
                          </MenuItem>
                          <MenuItem >
                          <Link
                            style={{ color: "inherit", textDecoration: "none" }}
                            to="/"
                          >
                                    <div className="form-check">
  <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
  <label className="form-check-label">
  <div className="bd-highlight">
                             <img
                      src="/assets/images/figma-icon/Star_icon.svg"
                       alt="StarI196"
                     className="pb-1 pl-1"
                    />
                      <span className="results-text14">
                  <span>4.8 </span>
                </span>
          
                                
                                </div>
  </label>
                          </div>
                          </Link>
                          </MenuItem>
                          <MenuItem >
                          <Link
                            style={{ color: "inherit", textDecoration: "none" }}
                            to="/"
                          >
                                    <div className="form-check">
  <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
  <label className="form-check-label">
  <div className="bd-highlight">
                             <img
                      src="/assets/images/figma-icon/Star_icon.svg"
                       alt="StarI196"
                     className="pb-1 pl-1"
                    />
                      <span className="results-text14">
                  <span>4.8 </span>
                </span>
          
                                
                                </div>
  </label>
                          </div>
                          </Link>
                          </MenuItem>
                          <MenuItem >
                          <Link
                            style={{ color: "inherit", textDecoration: "none" }}
                            to="/"
                          >
                                    <div className="form-check">
  <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
  <label className="form-check-label">
  <div className="bd-highlight">
                             <img
                      src="/assets/images/figma-icon/Star_icon.svg"
                       alt="StarI196"
                     className="pb-1 pl-1"
                    />
                      <span className="results-text14">
                  <span>4.8 </span>
                </span>
          
                                
                                </div>
  </label>
                          </div>
                          </Link>
                          </MenuItem>
                          <hr style={{}}/>
                          <div className="d-flex bd-highlight ">
  <div className="pr-5 bd-highlight">Temizle</div>
  <div className="bd-highlight"><BorderlessButton className="text-neutral-600 border px-2 py-2 ">
                        <div className="d-flex">
                      
                      <p>iptal</p>
                        </div>
              
           </BorderlessButton></div>
  <div className="pl-3 bd-highlight">  <PrimaryButton className="px-2 py-2">Uygula</PrimaryButton></div>
                          </div>
                          </div>
                  </Menu>
          </Dropdown>
          <Dropdown >
                  <MenuButton
                    sx={{
                    
                        left:"6px",
                    borderRadius: "10px" ,
                    border: "2px solid var(--clr-neutral-300)" ,
                    color:"var (--clr-neutral-300)"
                }}
                    variant="plain"
                   
                  >
                    <div className="row">
                        <div className="col-12">
                    <span className="text-dark-support" style={{color:"var(--clr-neutral-500)"}}> Seviye</span>
                    <span className="feather-chevron-down" style={{color:"var(--clr-neutral-500)"}}> </span>
                    </div>
                    </div>
                  </MenuButton>
                  <Menu variant="plain" color="neutral">
                  <MenuItem >
                          <Link
                            style={{ color: "inherit", textDecoration: "none" }}
                            to="/"
                          >
                            menu1
                          </Link>
                        </MenuItem>
                  </Menu>
          </Dropdown>
          <Dropdown >
                  <MenuButton
                    sx={{
                        left:"9px",
                  
                    borderRadius: "10px" ,
                    border: "2px solid var(--clr-neutral-300)" ,
                    color:"var (--clr-neutral-300)"
                }}
                    variant="plain"
                   
                  >
                    <div className="row">
                        <div className="col-12">
                    <span className="text-dark-support" style={{color:"var(--clr-neutral-500)"}}> Online/Yüz Yüze</span>
                    <span className="feather-chevron-down" style={{color:"var(--clr-neutral-500)"}}> </span>
                    </div>
                    </div>
                  </MenuButton>
                  <Menu variant="plain" color="neutral">
                  <MenuItem >
                          <Link
                            style={{ color: "inherit", textDecoration: "none" }}
                            to="/"
                          >
                            menu1
                          </Link>
                        </MenuItem>
                  </Menu>
          </Dropdown>
          <Dropdown >
                  <MenuButton
                    sx={{
                        left:"12px",
                   
                    borderRadius: "10px" ,
                    border: "2px solid var(--clr-neutral-300)" ,
                    color:"var (--clr-neutral-300)"
                }}
                    variant="plain"
                   
                  >
                    <div className="row">
                        <div className="col-12">
                    <span className="text-dark-support" style={{color:"var(--clr-neutral-500)"}}> +1</span>
                  
                    </div>
                    </div>
                  </MenuButton>
                  {/* <Menu variant="plain" color="neutral">
                  <MenuItem >
                          <Link
                            style={{ color: "inherit", textDecoration: "none" }}
                            to="/"
                          >
                            menu1
                          </Link>
                        </MenuItem>
                  </Menu> */}
          </Dropdown>
            
            </div>
          <div className="col-6 col-md-3">
          
            
                    <BorderlessButton className="text-neutral-600 border px-3 ml-2 mr-3">
                        <div className="d-flex">
                        <img
                      src="/assets/images/figma-icon/filter-lines.svg"
                      alt="Verifiedtick1967"
                      className="mt-0 pr-1"
                    />
                      <p>Daha Fazla Filtre</p>
                        </div>
              
           </BorderlessButton>
          
          

         
       
          </div>
     </div>
     <div className="row mt-4">
       <div className="col-12 col-md-10 pl-0">
         <div className="position-relative">
         <div className="position-absolute">
         <img
                      src="/assets/images/figma-icon/search-lg.svg"
                      alt="Verifiedtick1967"
                      className="pl-3 pt-2"
                    />
        
         </div>

         <div className="translate-middle-x">
         
            <input
              placeholder="İsim/anahtar kelime ile ara"
              style={{ borderRadius: "10px",
                }}
              className="w-100 border pl-6 pl-sm-5 py-2"
            >      
            </input>
         </div>
                 
         </div>
       
       </div>
       <div className="col-6 col-md-2">
          <PrimaryButton className="px-3 py-2 ml-3 " >Ara</PrimaryButton>
       </div>
     </div>

     <div className="row mt-4">
       <div className="col-12 col-md-10 pl-0">
         <ToggleButtonGroup
           //   variant={value || undefined}
         //   value={value}
       onChange={(event, newValue) => {
          // setValue(newValue);
       }}
      >
       <Button value="plain">Puan göre sırala</Button>
       <Button value="outlined">Fiyata göre sırala</Button>
     
      </ToggleButtonGroup>
       </div>
       <div className="col-6 col-md-2">
       <ToggleButtonGroup
           //   variant={value || undefined}
         //   value={value}
       onChange={(event, newValue) => {
          // setValue(newValue);
       }}
      >
        <IconButton value={0}>
        <List/>
      </IconButton>
      <IconButton value={1}>
        <ViewCozy />
      </IconButton>
      
      </ToggleButtonGroup>
       </div>
     </div>
     
    
     <SearchResult/>
       
       {/* <SearchCard/> */}

    </nav>
    </>
  );
};

export default SearchMain;