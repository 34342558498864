import React, { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import useCarousel from "../../../../hooks/useCarousel";
import LoginViewModel from "../../../pages/login/view_model/login_view_model";
import { LoginReqModel } from "../../../../infrastructure/models/auth/login_req_model";
import IPage from "../../../../core/interfaces/page";
import Button from "../../../components/Button";
import useAuth from "../../../../hooks/Auth/useAuth";

import { Link } from "react-router-dom";

const Login: React.FunctionComponent<IPage> = () => {
  const [phonenumber, setPhoneNumber] = useState<number>(90);
  const [password, setPassword] = useState<string>("");
  const apiCall = LoginViewModel();
  const { setAuth } = useAuth();

  const handlerSubmit = () => {
    var res = apiCall.login(
      new LoginReqModel(String(phonenumber).substring(2, 12), password)
    );
    res.then((re) => {
      if (!re.isError) {
        const accessToken = re?.obj.accessToken;
        const roles = re?.obj.userType === 2 ? ["Teacher"] : ["Teacher"];
        setAuth({ roles, accessToken });
      }
    });
  };

  const carouselcontents = [
    {
      featurename: "Feature Name-1",
      imgsource: "assets/images/SignUp/slider-1.svg",
      contentheader: "Feature Name-1",
      contentdescription: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br />{" "}
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </>
      ),
    },
    {
      featurename: "Feature Name-2",
      imgsource: "assets/images/SignUp/slider-2.svg",
      contentheader: "Feature Name-2",
      contentdescription: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br />{" "}
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </>
      ),
    },
    {
      featurename: "Feature Name-3",
      imgsource: "assets/images/SignUp/slider-3.svg",
      contentheader: "Feature Name-3",
      contentdescription: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br />{" "}
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </>
      ),
    },
  ];

  const carousel = useCarousel({
    carouselcontents,
    responsivequantity: {
      itemformobile: 1,
      itemfortablet: 1,
      itemfordesktop: 1,
    },
  });

  return (
    <>
      <div className="main-wrap row vh-100 classic-transition">
        <div className="col-12 col-md-6 bg-white rounded-lg overflow-hidden d-flex flex-column justify-content-between ">
          <div className="row">
            <div className="col-2 text-sm-center mx-auto col-md-12 text-md-left mt-4  ml-md-3">
              <img src="/assets/images/figma-icon/Logo.svg" alt="Logo"></img>
            </div>
          </div>
          <div className="row">
            <div className="align-self-center card shadow-none border-0 ml-auto mr-auto login-card">
              <div className="card-body rounded-0 text-left mb-5 ">
                <div className="mb-4">
                  <h2 className="fw-600 fs-30 ls-3">Hesabınıza Giriş Yapın</h2>
                  <p className="font-xs m-0">
                    Hesabınız yok mu?
                    <Link className="text-primary-600 fw-600" to="/register">
                      {" "}
                      Hesap Oluştur
                    </Link>
                  </p>
                </div>

                <div className="col-sm-12 p-0 text-left">
                  <div className="d-flex flex-column ">
                    <label className="m-0">Telefon Numarası</label>
                    <div
                      style={{ borderRadius: "10px" }}
                      className="d-flex border  p-2"
                    >
                      <select
                        style={{ width: "50px", border: "none" }}
                        className=""
                        defaultValue={"+90"}
                      >
                        <option value="+90">TR</option>
                        <option value="+44">UK</option>
                        <option value="+44">USA</option>
                      </select>
                      <input
                        id="phone-input"
                        type="number"
                        placeholder="+90 (555) 000-0000"
                        value={phonenumber}
                        onChange={(e) => {
                          setPhoneNumber(Number(e.target.value));
                        }}
                        className="border-0 ml-2"
                        // onWheel={handleWheel}
                      ></input>
                    </div>
                  </div>

                  <div className="d-flex flex-column mt-3">
                    <label className="m-0">Şifre</label>
                    <div
                      style={{ borderRadius: "10px" }}
                      className="d-flex border  p-2"
                    >
                      <input
                        id="phone-input"
                        type="password"
                        placeholder="******"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        className="border-0 ml-2"
                        // onWheel={handleWheel}
                      ></input>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between font-xsss mt-3 ">
                    <div className="d-flex">
                      <input type="checkbox"></input>
                      <span className="ml-3 fw-500 ">Beni Hatırla</span>
                    </div>
                    <span className="text-primary-600 fw-600">
                      Şifremi Unuttum?
                    </span>
                  </div>

                  <Button
                    onClick={() => {
                      handlerSubmit();
                    }}
                    sx={{ borderRadius: "10px" }}
                    className="bg-primary-600 text-white  fw-600 border py-2  mt-3 mb-3 w-100"
                  >
                    Giriş Yap
                  </Button>
                  <Button
                    onClick={() => {
                      //setLoading(true);
                      //var s=apiCall.login(new LoginReqModel(String(phonenumber).substring(2,12),password));
                      //onClick(phonenumber,password);
                    }}
                    sx={{ borderRadius: "10px" }}
                    className="color-white  fw-600 border py-2  w-100"
                  >
                    <img
                      className="mr-2"
                      src="/assets/images/figma-icon/icon-google.svg"
                      alt="google"
                    ></img>
                    Google ile Üye Ol
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="row pb-4">
            <div className="col-12 d-flex justify-content-between px-5 font-xssss ">
              <span>© EduİKO 2024</span>
              <span>help@eduiko.com</span>
            </div>
          </div>
        </div>

        <div className="carousel-transition col-12 col-md-6 d-none d-sm-flex vh-100 justify-content-center align-items-center bg-light">
          {carousel}
        </div>
      </div>
    </>
  );
};

export default Login;
