import React, { CSSProperties, useEffect, useState } from "react";
import { SeriesStateType, InputType } from "../../pages/course/create-course";

// Example Category and sub category

const category = [
  { id: 1, name: "Matematik" },
  { id: 2, name: "Türkçe" },
  { id: 3, name: "Fen" },
];
const subcategory = [
  {
    category: "Matematik",
    subcategory: [
      {
        id: 1,
        name: "10. sinif Matematik",
      },
      {
        id: 2,
        name: "11. sinif Matematik",
      },
      {
        id: 3,
        name: "12. sinif Matematik",
      },
    ],
  },
  {
    category: "Türkçe",
    subcategory: [
      {
        id: 1,
        name: "10. sinif Türkçe",
      },
      {
        id: 2,
        name: "11. sinif Türkçe",
      },
      {
        id: 3,
        name: "12. sinif Türkçe",
      },
    ],
  },
  {
    category: "Fen Bilgisi",
    subcategory: [
      {
        id: 1,
        name: "10. sinif Fen Bilgisi",
      },
      {
        id: 2,
        name: "11. sinif Fen Bilgisi",
      },
      {
        id: 3,
        name: "12. sinif Fen Bilgisi",
      },
    ],
  },
];

enum ElementTypeEnum {
  INPUT = "input",
  TEXTAREA = "textarea",
  ARRAYSINPUT = "arraysinput",
}
type ElementType = "input" | "textarea" | "arraysinput";

interface InputProps {
  elementType: ElementType;
  placeholder: string;
  characterLimitation?: number;
  stateAddress: keyof SeriesStateType;
  seriesState: SeriesStateType;
  dispatch: React.Dispatch<React.SetStateAction<SeriesStateType>>;
  className?: string;
  sx?: CSSProperties;
  childrenIndexIdentity?: string;
}

const Input: React.FunctionComponent<InputProps> = ({
  elementType,
  placeholder,
  characterLimitation,
  stateAddress,
  seriesState,
  dispatch,
  className,
  sx,
  childrenIndexIdentity,
}) => {
  // Focus Feature
  const [isFocus, setIsFocus] = useState<boolean>(false);

  // Remaining Chracter state defination
  const [remainingLimit, setRemainingLimit] = useState<number | undefined>(
    characterLimitation
  );

  // addressed state in formState
  const addressedStateMember = seriesState[stateAddress];

  //  type assertion to narrow down state member to string
  const stringStateValue =
    typeof addressedStateMember === "string" ? addressedStateMember : "";

  //  type assertion to narrow down state member to InputArray
  let inputTypeStateArray: InputType[] = [];
  const isInputTypeArray = (arr: any[]): arr is InputType[] => {
    return arr.every((obj) => "value" in obj);
  };

  if (Array.isArray(addressedStateMember)) {
    if (isInputTypeArray(addressedStateMember)) {
      inputTypeStateArray = addressedStateMember;
    }
  }
  const childrenOfInputArray =
    inputTypeStateArray[Number(childrenIndexIdentity) - 1];

  //   showing remaning chracter
  useEffect(() => {
    // calculate remaining chracter
    if (characterLimitation && typeof addressedStateMember === "string") {
      const remaining = characterLimitation - addressedStateMember.length;
      setRemainingLimit(remaining);
    }
  }, [characterLimitation, addressedStateMember]);

  // mutate the state
  const handleChange = (newValue: string) => {
    dispatch((previous) => {
      return { ...previous, [stateAddress]: newValue };
    });
  };

  // mutate the state number if it is member of an array
  const handleArrayInputChange = (newValue: string) => {
    const inputName = `input-${childrenIndexIdentity}`;
    dispatch((previous) => {
      if (Array.isArray(addressedStateMember)) {
        const updatedGains = addressedStateMember.map((input) =>
          input.name === inputName ? { ...input, value: newValue } : input
        );
        return { ...previous, [stateAddress]: updatedGains };
      }
      return { ...previous };
    });
  };

  return (
    <>
      <div
        style={{ borderRadius: "8px" }}
        className={`p-1 w-75 shadow-sm ${
          !isFocus ? "form-input " : "focused-form-input"
        }`}
      >
        {/* If Element Type is Input */}
        {elementType === ElementTypeEnum.INPUT ? (
          <input
            style={sx}
            onChange={(e) => handleChange(e.target.value)}
            onFocus={(e) => {
              setIsFocus(true);
            }}
            onBlur={(e) => {
              setIsFocus(false);
            }}
            value={stringStateValue}
            type="text"
            placeholder={placeholder}
            className={`border-0 px-2 w-100 ${className}`}
            maxLength={characterLimitation}
          />
        ) : //   If Element Type is TextArea
        elementType === ElementTypeEnum.TEXTAREA ? (
          <textarea
            style={sx}
            onChange={(e) => handleChange(e.target.value)}
            onFocus={(e) => {
              setIsFocus(true);
            }}
            onBlur={(e) => {
              setIsFocus(false);
            }}
            value={stringStateValue}
            placeholder={placeholder}
            className={`border-0 px-2 w-100 ${className}`}
            maxLength={characterLimitation}
          ></textarea>
        ) : (
          //   If element type is a children of array
          elementType === ElementTypeEnum.ARRAYSINPUT &&
          Array.isArray(addressedStateMember) && (
            <input
              style={sx}
              onChange={(e) => handleArrayInputChange(e.target.value)}
              onFocus={(e) => {
                setIsFocus(true);
              }}
              onBlur={(e) => {
                setIsFocus(false);
              }}
              value={childrenOfInputArray?.value}
              type="text"
              placeholder={placeholder}
              className={`border-0 px-2 w-100 ${className}`}
              maxLength={characterLimitation}
            />
          )
        )}
      </div>

      {characterLimitation ? (
        <label className="font-xsss text-neutral-600">
          {remainingLimit} karakter hakkınız kaldı.
        </label>
      ) : null}
    </>
  );
};

type AddNewInputProps = Pick<
  InputProps,
  Exclude<
    keyof InputProps,
    | "elementType"
    | "placeholder"
    | "characterLimitation"
    | "seriesState"
    | "className"
    | "sx"
    | "childrenIndexIdentity"
  >
>;

const AddNewInput: React.FunctionComponent<AddNewInputProps> = ({
  stateAddress,
  dispatch,
}) => {
  // functionInfo: This function helps to manipulate state and add new member to specific state address therefore dynamic input can be createble by user and form will be extended function needs two props first one stateAddress , target state member needs to be writed here , in order to function finds target state member , dispacth is the function is provided by react native module in order to achieve state manipulation.

  // Parent state manipulation
  const AddNewInputHandler = () => {
    dispatch((previous: SeriesStateType) => {
      if (Array.isArray(previous[stateAddress])) {
        // make sure key is an array in order to achieve array itteration
        const previousArray = previous[stateAddress];

        // Intermediate Layer for purify states from strings
        if (Array.isArray(previousArray)) {
          const onlyArrayStateMembers = previousArray;

          // create new input
          const newInputValue = {
            name: `input-${onlyArrayStateMembers.length + 1}`,
            value: "",
          };
          return {
            ...previous,
            [stateAddress]: [...onlyArrayStateMembers, newInputValue],
          };
        }
      }

      return { ...previous };
    });
  };

  return (
    <button
      onClick={AddNewInputHandler}
      style={{ color: "rgba(71, 85, 105, 1)" }}
      className="border-0 bg-transparent d-flex font-xsss fw-600"
    >
      {" "}
      + Daha fazla yanit ekle
    </button>
  );
};

interface CreateCourseDetailsProps {
  seriesState: SeriesStateType;
  seriesDispatch: React.Dispatch<React.SetStateAction<SeriesStateType>>;
}
const CreateCourseDetails: React.FunctionComponent<
  CreateCourseDetailsProps
> = ({ seriesState, seriesDispatch }) => {
  // props distructure
  const { gains, requirements, audiences } = seriesState;

  return (
    <>
      <div className="row classic-transition">
        {/* Title */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Başlık</label>
              <p className="w-75">
                Başlığınız ilgi çekici, bilgilendirici ve arama için uygun
                olmalıdır. Daha sonra değiştirebilirsiniz.
              </p>
            </div>
            <div className="col-7">
              <Input
                elementType={ElementTypeEnum.INPUT}
                placeholder="örn. TYT Matematik Eğitim Serisi"
                characterLimitation={60}
                stateAddress="title"
                seriesState={seriesState}
                dispatch={seriesDispatch}
              />
            </div>
          </div>
          <hr />
        </div>
        {/* SubTitle */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Alt Başlık</label>
              <p className="w-75">
                Konuyla ilgili 1 veya 2 anahtar kelime kullanın ve kursunuzun
                içeriğindeki en önemli 3-4 alandan bahsedin.
              </p>
            </div>
            <div className="col-7">
              <Input
                elementType={ElementTypeEnum.INPUT}
                placeholder="örn"
                characterLimitation={60}
                stateAddress="subtitle"
                seriesState={seriesState}
                dispatch={seriesDispatch}
              />
            </div>
          </div>
          <hr />
        </div>
        {/* Description */}
        <div className="col-12 mt-2">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">İçerik Açıklaması</label>
              <p className="w-75">Kısa bir tanıtım yazın.</p>
            </div>
            <div className="col-7">
              <Input
                elementType={ElementTypeEnum.TEXTAREA}
                placeholder="Aciklama"
                characterLimitation={500}
                stateAddress="description"
                seriesState={seriesState}
                dispatch={seriesDispatch}
                sx={{ height: "200px" }}
              />
            </div>
          </div>
          <hr />
        </div>

        {/* Category */}
        <div className="col-12 mt-2">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Kategori</label>
              <p className="w-75">Daha sonra değiştirebilirsiniz.</p>
            </div>
            <div className="col-7">
              <div
                style={{ gap: "8px" }}
                className="d-flex w-75 justify-content-between"
              >
                {/* Category */}
                <div
                  style={{ borderRadius: "8px" }}
                  className="w-50 p-1 border"
                >
                  <select className="w-100" style={{ border: "none" }}>
                    {category.map((category: any) => (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* SubCategory */}
                <div
                  style={{ borderRadius: "8px" }}
                  className="w-50 p-1 border"
                >
                  <select className="w-100" style={{ border: "none" }}>
                    {subcategory[0].subcategory.map((subcat: any) => (
                      <option key={subcat.id} value={subcat.name}>
                        {subcat.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>

        {/* Gains */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Öğrenciler, ne öğrenecek?</label>
              <p className="w-75">
                Öğrencilerin, içeriğinizi tamamladıktan sonra başarabilecekleri
                en az 4 öğrenim hedefi veya sonuç girmelisiniz. Tamamlandığında
                hangi becerilerin, ürünlerin ya da kazanımların elde edileceğini
                açıklayın.
              </p>
            </div>
            <div className="col-7">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {gains.map((_: any, index: number) => {
                  return (
                    <Input
                      key={index}
                      elementType={ElementTypeEnum.ARRAYSINPUT}
                      childrenIndexIdentity={String(Number(index + 1))}
                      placeholder="Öğrencilerin Kurs Sonu Kazanımlarını Ekleyiniz"
                      stateAddress="gains"
                      seriesState={seriesState}
                      dispatch={seriesDispatch}
                    />
                  );
                })}
                <AddNewInput dispatch={seriesDispatch} stateAddress="gains" />
              </div>
            </div>
          </div>
          <hr />
        </div>

        {/* Requirement */}

        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">
                İçeriğinizi almak için gereksinimler veya ön koşullar nelerdir?
              </label>
              <p className="w-75">
                Öğrencilerin içeriğinizi almadan önce sahip olması gereken
                yetkinlikleri, deneyimleri, araçları veya ekipmanları
                listeleyin. Hiçbir gereksinim yoksa bunu, yeni başlayanlar için
                bariyeri düşürmeye yönelik bir fırsat olarak kullanın.
              </p>
            </div>
            <div className="col-7">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {requirements.map((_: any, index: number) => {
                  return (
                    <Input
                      key={index}
                      elementType={ElementTypeEnum.ARRAYSINPUT}
                      childrenIndexIdentity={String(Number(index + 1))}
                      placeholder="örn. Deneyim gerekmez. Bilmeniz gereken her şeyi öğreneceksiniz"
                      stateAddress="requirements"
                      seriesState={seriesState}
                      dispatch={seriesDispatch}
                    />
                  );
                })}
                <AddNewInput
                  dispatch={seriesDispatch}
                  stateAddress="requirements"
                />
              </div>
            </div>
          </div>
          <hr />
        </div>

        {/* Audience */}

        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Bu içerik kime yönelik?</label>
              <p className="w-75">
                İçeriğinizi değerli bulacak hedef öğrenciler için net bir
                açıklama girin.Bu, doğru öğrencileri içeriğinize çekmenize
                yardımcı olur.
              </p>
            </div>
            <div className="col-7">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {audiences.map((_: any, index: number) => {
                  return (
                    <Input
                      key={index}
                      elementType={ElementTypeEnum.ARRAYSINPUT}
                      childrenIndexIdentity={String(Number(index + 1))}
                      placeholder="örn. Veri bilimine meraklı, başlangıç düzeyindeki geliştiriciler"
                      stateAddress="audiences"
                      seriesState={seriesState}
                      dispatch={seriesDispatch}
                    />
                  );
                })}
                <AddNewInput
                  dispatch={seriesDispatch}
                  stateAddress="audiences"
                />
              </div>
            </div>
          </div>
          <hr />
        </div>

        {/* Welcome&Congrat Messages */}
        <div className="col-12 mt-2">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">İçerik mesajları (isteğe bağlı)</label>
              <p className="w-75">
                İçerik ile etkileşime geçmeleri için teşvik etmek üzere
                öğrenciler içeriğinize katıldığında veya içeriğinizi
                tamamladığında otomatik olarak gönderilecek mesajlar yazın. Bir
                karşılama veya tebrik mesajı göndermek istemiyorsanız metin
                kutusunu boş bırakın.
              </p>
            </div>
            <div className="col-7">
              <Input
                elementType={ElementTypeEnum.TEXTAREA}
                placeholder="Karşılama Mesajı"
                characterLimitation={500}
                stateAddress="msgwelcome"
                seriesState={seriesState}
                dispatch={seriesDispatch}
                sx={{ height: "200px" }}
              />
              <Input
                elementType={ElementTypeEnum.TEXTAREA}
                placeholder="Tebrik Mesajı"
                characterLimitation={500}
                stateAddress="msgcongrat"
                seriesState={seriesState}
                dispatch={seriesDispatch}
                sx={{ height: "200px" }}
              />
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default CreateCourseDetails;
