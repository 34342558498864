import React, { useState } from "react";
// Library Imports
import { Button } from "@mui/joy";
// Components
import { PrimaryButton } from "../Button";
// hooks
import { useToogleGroupButton } from "../../../hooks/useToogleGroupButton";
// types
import { TChildrenToogleButtons } from "../../../core/types";
import SearchCard from "../search/SearchCard";

const radioButtonArray: TChildrenToogleButtons[] = [
  {
    buttonName: "Öğrenci",
    value: "Student",
  },
  {
    buttonName: "Öğretmen",
    value: "Teacher",
  },
];

const Hero: React.FunctionComponent = () => {
  // const { toogleValue, ToogleGroupButton } = useToogleGroupButton([]);
  const { ToogleGroupButton } = useToogleGroupButton([]);
  const[search,setSearch]=useState(false);

  return (
    <>
      <section className="hero-wrapper container  pt-0 pt-sm-5">
        <div className="row  mt-5 ">
          <div className="col-12  d-flex justify-content-center justify-content-sm-start">
            <ToogleGroupButton
              toggleButtonArray={radioButtonArray}
            ></ToogleGroupButton>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-sm-8 text-center text-sm-left">
            <h1 className="hero-h1 display4-size fw-600 ">
              Build an career with expert teachers today
            </h1>
            <p className="pt-2">
              Book and meet over +2000 teachers for 1:1 mentorship in our
              community.
            </p>
          </div>
          <div className="col-12 col-sm-4 mt-4 mt-sm-0 text-center text-sm-left">
            <ul className="">
              <li>
                <img
                  src="assets/images/figma-icon/icon-hero-check.svg"
                  alt="check"
                ></img>
                <span className="ml-2">
                  Tune in and level up at your own pace
                </span>
              </li>
              <li className="mt-3">
                <img
                  src="assets/images/figma-icon/icon-hero-check.svg"
                  alt="check"
                ></img>
                <span className="ml-2">
                  Go from dabbler to master in a matter
                </span>
              </li>
              <li className="d-flex justify-content-center justify-content-sm-start mt-3 mr-2 mr-sm-0 ">
                <img
                  className="align-self-start"
                  src="assets/images/figma-icon/icon-hero-check.svg"
                  alt="check"
                ></img>
                <span className="ml-2">
                  Connect with a global community of <br />
                  curious creatives.
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/* Custom Search component will be created*/}
        <div className="row mt-4">

          <div className="col-sm-8 d-flex justify-content-between">

            <input
              placeholder="What do you want to get better at?"
              style={{ borderRadius: "6px" }}
              className="w-100 border pl-1 pl-sm-2"
            ></input>

            <PrimaryButton className="px-3  ml-2 "
            onClick={()=>{
              setSearch(true);
            }}
            >Explore</PrimaryButton>
          </div>
          {search?<div className="autocomplete z-3 position-absolute"><SearchCard/></div>:null}

          <div className="col-sm-12 d-flex justify-content-center justify-content-sm-start mt-4 mt-sm-3">
            <Button
              sx={{
                minHeight: 0,
                minWidth: 0,
                padding: 0,
                paddingX: "10px",
                paddingY: "1px",
                borderRadius: "20px",
              }}
              variant="soft"
            >
              Design
            </Button>
            <Button
              sx={{
                minHeight: 0,
                minWidth: 0,
                padding: 0,
                paddingX: "10px",
                paddingY: "1px",
                borderRadius: "20px",
              }}
              variant="soft"
              className="ml-2"
            >
              Research
            </Button>
            <Button
              sx={{
                minHeight: 0,
                minWidth: 0,
                padding: 0,
                paddingX: "10px",
                paddingY: "1px",
                borderRadius: "20px",
              }}
              variant="soft"
              className="ml-2"
              color="danger"
            >
              Presentation
            </Button>
          </div>
        </div>
        {/* Custom Search component will be created*/}

        {/* Illustration */}
        <div className="row mt-5">
          <div className="col-12">

            <img
              style={{ borderRadius: "16px" }}
              className="w-100"
              src="/assets/images/Hero/illustration.svg"
              alt="illustration"
            ></img>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
