import React from "react";
import Logo from "../../components/Logo";

const Footer: React.FunctionComponent = () => {
  return (
    <>
      <div className="w-100 bg-primary-800 py-5">
        <div className="container">
          <div className="border-bottom pb-4 pb-sm-5">
            <div
              style={{ gap: "32px" }}
              className=" d-flex flex-column align-items-center align-items-sm-start   "
            >
              <Logo size="48px" />
              {/* <ul className="row d-flex fw-600 text-white ml-auto">
                <li className="col-6 col-sm-2 ">Overview</li>
                <li className="col-6 col-sm-2">Features</li>
                <li className="col-6 col-sm-2">Pricing</li>
                <li className="col-6 col-sm-2">Careers</li>
                <li className="col-6 col-sm-2">Help</li>
                <li className="col-6 col-sm-2">Privacy</li>
              </ul> */}
              <ul
                style={{ gap: "32px" }}
                className="d-flex flex-wrap fw-600 text-white "
              >
                <li className="">Overview</li>
                <li className="">Features</li>
                <li className="">Pricing</li>
                <li className="">Careers</li>
                {/* <li className="">Help</li>
                <li className="">Privacy</li> */}
              </ul>
            </div>
          </div>
          <div className="mt-3 text-primary-300  d-flex flex-sm-row justify-content-sm-between flex-column align-items-center">
            <span className="fw-400">© 2024 Eduİko. All rights reserved.</span>

            <ul style={{ gap: "16px" }} className="d-flex">
              <li>Terms</li>
              <li>Privacy</li>
              <li>Cookies</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
