import React, { WheelEvent, useState, useContext, useEffect } from "react";
import Button from "../Button";
import { useForm, SubmitHandler } from "react-hook-form";

// Hooks
import useCarousel from "../../../hooks/useCarousel";

// Context Architecture
import { RegisterContext } from "../../../context/useRegisterContext";
import { RegisterModel } from "../../../infrastructure/models/auth/register_model";
import RegisterViewModel from "../../pages/auth/view_model/registerViewModel";

type Inputs = {
  phoneNumber: string;
  password: string;
  passwordRe: string;
};
const SignUp: React.FunctionComponent = () => {
  // Initial state will be changable depends on selected country
  const handlerSubmit = RegisterViewModel();

  const [gsmNO, setGsmNO] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordReply, setPasswordReply] = useState<string>("");
  const [checked, setChecked] = useState(false);

  const { StepIncrement, DataPicker, StepDicrement, state } =
    useContext(RegisterContext);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    DataPickerHandler();
  };
  // Local Handler
  const DataPickerHandler = () => {
    // if validation true triggerdatapicker
    if (gsmNO && password && passwordReply) {
      DataPicker(gsmNO, password);
      var res = handlerSubmit.createRegister(
        new RegisterModel(gsmNO, password),
        state.form.userType!
      );
      res.then((re) => {
        re.isError ? StepDicrement() : StepIncrement();
      });
      //StepIncrement();
    }
  };

  // prevent Defaults
  const handleWheel = (e: WheelEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  // useCarousel Hooks
  const carouselcontents = [
    {
      featurename: "Feature Name-1",
      imgsource: "assets/images/SignUp/slider-1.svg",
      contentheader: "Feature Name-1",
      contentdescription: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br />{" "}
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </>
      ),
    },
    {
      featurename: "Feature Name-2",
      imgsource: "assets/images/SignUp/slider-2.svg",
      contentheader: "Feature Name-2",
      contentdescription: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br />{" "}
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </>
      ),
    },
    {
      featurename: "Feature Name-3",
      imgsource: "assets/images/SignUp/slider-3.svg",
      contentheader: "Feature Name-3",
      contentdescription: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br />{" "}
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </>
      ),
    },
  ];
  const carousel = useCarousel({
    carouselcontents,
    responsivequantity: {
      itemformobile: 1,
      itemfortablet: 1,
      itemfordesktop: 1,
    },
  });

  return (
    <>
      <div className="main-wrap row vh-100 classic-transition">
        <div className="col-12 col-md-6 bg-white rounded-lg overflow-hidden d-flex flex-column justify-content-between ">
          <div className="row">
            <div className="col-2 text-sm-center mx-auto col-md-12 text-md-left mt-4  ml-md-3">
              <img src="/assets/images/figma-icon/Logo.svg" alt="Logo"></img>
            </div>
          </div>
          <div className="row">
            <div className="align-self-center card shadow-none border-0 ml-auto mr-auto login-card">
              <div className="card-body rounded-0 text-left mb-5 ">
                <div className="mb-4">
                  <h2 className="fw-600 fs-30">Hesap Oluştur</h2>
                  <p className="font-xs m-0">
                    Zaten hesabınız var mı?
                    <span className="text-primary-600 fw-600"> Giriş Yap</span>
                  </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-sm-12 p-0 text-left">
                    {/* Phone Input */}
                    <div className="d-flex flex-column ">
                      <label className="fw-500 m-0 font-xsss">
                        Telefon Numarası
                      </label>
                      <div
                        style={{ borderRadius: "10px" }}
                        className="d-flex border p-2 shadow-sm"
                      >
                        <select
                          style={{ width: "50px", border: "none" }}
                          className=""
                          defaultValue={"+90"}
                        >
                          <option value="+90">TR</option>
                          <option value="+44">UK</option>
                          <option value="+44">USA</option>
                        </select>

                        <input
                          {...register("phoneNumber", {
                            required: "Bu alan boş geçilemez",
                            pattern: {
                              value:
                                /([(]?)([5])([0-9]{2})([)]?)([\s]?)([0-9]{3})([\s]?)([0-9]{2})([\s]?)([0-9]{2})$/g,
                              message: "(555) 000-0000 bu formatta olmalıdır",
                            },
                          })}
                          id="phone-input"
                          type="number"
                          placeholder="(555) 000-0000"
                          value={gsmNO}
                          onChange={(e) => {
                            setValue("phoneNumber", e.target.value);
                            setGsmNO(e.target.value);
                          }}
                          className="border-0 ml-2"
                          onWheel={handleWheel}
                        ></input>
                      </div>

                      <p
                        className={`text-danger font-xsss m-0 h-0 ${
                          errors.phoneNumber?.message
                            ? "classic-transition"
                            : ""
                        }`}
                      >
                        {errors.phoneNumber?.message}
                        <br></br>
                      </p>
                    </div>

                    {/* Password Input */}
                    <div className="d-flex flex-column mt-3">
                      <label className="fw-500 m-0 font-xsss">Şifre</label>
                      <div
                        style={{ borderRadius: "10px" }}
                        className="border p-2 shadow-md"
                      >
                        <input
                          {...register("password", {
                            required: "Bu alan boş geçilemez",
                            minLength: {
                              value: 8,
                              message: "En az 8 karekter içermelidir.",
                            },
                          })}
                          onChange={(e) => {
                            setValue("password", e.target.value);

                            setPassword(e.target.value);
                          }}
                          value={password}
                          name="sifre"
                          type="password"
                          placeholder="Şifre oluşturun"
                          className="border-0 px-2"
                        ></input>
                      </div>
                      <p
                        className={`text-danger font-xsss m-0 h-0 ${
                          errors.password?.message ? "classic-transition" : ""
                        }`}
                      >
                        {errors.password?.message}
                        <br></br>
                      </p>
                    </div>

                    <div className="d-flex flex-column mt-4">
                      <label className="fw-500 m-0 font-xsss">
                        Şifre Tekrar
                      </label>
                      <div
                        style={{ borderRadius: "10px" }}
                        className="border p-2 shadow-md"
                      >
                        <input
                          {...register("passwordRe", {
                            required: "Bu alan boş geçilemez",
                            validate: (val: string) => {
                              if (watch("password") != val) {
                                return "Şifreler farklı!!";
                              }
                            },
                          })}
                          onChange={(e) => {
                            setValue("passwordRe", e.target.value);

                            setPasswordReply(e.target.value);
                          }}
                          value={passwordReply}
                          name="sifreTekrar"
                          type="password"
                          placeholder="Şifre Tekrar"
                          className="border-0 px-2"
                        ></input>
                      </div>
                      <p
                        className={`text-danger font-xsss m-0 h-0 ${
                          errors.passwordRe?.message ? "classic-transition" : ""
                        }`}
                      >
                        {errors.passwordRe?.message}
                        <br></br>
                      </p>
                    </div>

                    <Button
                      onClick={() => {
                        handleSubmit(onSubmit);
                        //DataPickerHandler();
                      }}
                      sx={{ borderRadius: "10px" }}
                      className="bg-primary-600 text-white  fw-600 border py-2  mt-4 mb-3 w-100"
                    >
                      Telefon numarası ile devam et
                    </Button>
                    <Button
                      // onClick={() => {
                      //   onClick(phonenumber);
                      // }}
                      sx={{ borderRadius: "10px" }}
                      className="color-white  fw-600 border py-2  w-100"
                    >
                      <img
                        className="mr-2"
                        src="/assets/images/figma-icon/icon-google.svg"
                        alt="google"
                      ></img>
                      Google ile Üye Ol
                    </Button>

                    <div className="d-flex">
                      <input
                        type="checkbox"
                        defaultChecked={checked}
                        onChange={() => setChecked((state) => !state)}
                      ></input>
                      <h6 className="text-grey-500 font-xssss fw-500 mt-0 mb-0 lh-32 ml-2">
                        Sözleşmeleri okudum.{" "}
                        <a href="#" className="fw-700 ml-1">
                          K.V.K.K
                        </a>
                      </h6>
                    </div>

                    <div className="mt-3">
                      <p className="fw-500 font-xsss">
                        Devam ederek{" "}
                        <span className="text-primary-600">
                          Hizmet Şartları{" "}
                        </span>
                        ve{" "}
                        <span className="text-primary-600">
                          Gizlilik Politikası
                        </span>{" "}
                        ’nı kabul etmiş olursunuz.
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="row pb-4">
            <div className="col-12 d-flex justify-content-between px-5 font-xssss ">
              <span>© EduİKO 2024</span>
              <span>help@eduiko.com</span>
            </div>
          </div>
        </div>

        <div className="carousel-transition col-12 col-md-6 d-none d-sm-flex vh-100 justify-content-center align-items-center bg-light">
          {carousel}
        </div>
      </div>
    </>
  );
};

export default SignUp;
