import React from "react";

// manifesto
// carousel custom hooks help us to create quick carousel everyhere in only one centerialized hook
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

type TCaruselScreenItemQuantity = {
  itemformobile: number;
  itemfortablet: number;
  itemfordesktop: number;
};

type TCarouselContent = {
  featurename: string;
  imgsource: string;
  contentheader: string;
  contentdescription: React.ReactNode;
};

interface CarouselArguments {
  carouselcontents: TCarouselContent[];
  responsivequantity: TCaruselScreenItemQuantity;
}

interface ICarousel {
  ({ carouselcontents, responsivequantity }: CarouselArguments): JSX.Element;
}

const useCarousel: ICarousel = ({
  carouselcontents,
  responsivequantity,
}: CarouselArguments): JSX.Element => {
  // CustomDot
  const CustomDot = ({ onMove, index, onClick, active }: any) => {
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <button
        className={`rounded-circle p-1 ml-2  ${
          active ? "bg-primary-600 border-primary-600" : "border"
        }`}
        onClick={() => onClick()}
      ></button>
    );
  };

  // Custom LeftArrow
  const CustomLeftArrow = ({ onClick, ...rest }: any) => {
    // const {
    //   onMove,
    //   carouselState: { currentSlide, deviceType },
    // } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <button
        style={{
          width: "30px",
          height: "30px",
          border: "none",
          backgroundColor: "transparent",
          position: "absolute",
          left: "35%",
          bottom: "5px",
          backgroundImage: "url('/assets/images/figma-icon/chevron-left.svg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className=""
        onClick={() => {
          onClick();
        }}
      ></button>
    );
  };

  // Custom Right Arrow

  const CustomRightArrow = ({ onClick, ...rest }: any) => {
    // const {
    //   onMove,
    //   carouselState: { currentSlide, deviceType },
    // } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <button
        style={{
          width: "30px",
          height: "30px",
          border: "none",
          backgroundColor: "transparent",
          position: "absolute",
          right: "35%",
          bottom: "5px",
          backgroundImage: "url('/assets/images/figma-icon/chevron-right.svg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className=""
        onClick={() => {
          onClick();
        }}
      ></button>
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 6000, min: 1024 },
      items: responsivequantity.itemfordesktop,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: responsivequantity.itemfortablet,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: responsivequantity.itemformobile,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  //   Dynamic Children Contents

  const renderedCarouselItems = carouselcontents.map(
    (content: TCarouselContent, index: number) => {
      return (
        <div key={index} className="Item-1">
          <div>
            <img className="w-100" src={content.imgsource} alt="slider-1"></img>
          </div>
          <div className="text-center">
            <h2>{content.contentheader}</h2>
            <p>{content.contentdescription}</p>
          </div>
        </div>
      );
    }
  );

  return (
    <Carousel
      containerClass={"p-4"}
      itemClass={" d-flex justify-content-center mb-5"}
      dotListClass={"pb-2 mb-2 carousel-dot-parent-class"}
      customDot={<CustomDot />}
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      swipeable={false}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={false} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2500}
      keyBoardControl={true}
      customTransition=""
      transitionDuration={500}
      // removeArrowOnDeviceType={["tablet", "mobile"]}
    >
      {renderedCarouselItems}
    </Carousel>
  );
};

export default useCarousel;
