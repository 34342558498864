import React, { useEffect, useState } from "react";
import { RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import { BpRadio } from "./BpRadio";

interface PriceInputProps {
  price: string | number;
  setPrice: React.Dispatch<React.SetStateAction<string | number>>;
  disable: boolean;
}
const PriceInput: React.FunctionComponent<PriceInputProps> = ({
  price,
  setPrice,
  disable,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div
      style={{ borderRadius: "8px" }}
      className={`d-flex  p-1 w-100 shadow-sm ${
        !isFocus ? "form-input " : "focused-form-input"
      }`}
    >
      <label className="m-0">₺</label>
      <input
        onChange={(e) => {
          setPrice(Number(e.target.value));
        }}
        onFocus={(e) => {
          setIsFocus(true);
        }}
        onBlur={(e) => {
          setIsFocus(false);
        }}
        value={price}
        disabled={disable}
        type="number"
        placeholder="Fiyat"
        className={`border-0 px-1 w-100`}
      />
    </div>
  );
};

interface CreateCoursePriceProps {
  priceDispatch: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const CreateCoursePrice: React.FunctionComponent<CreateCoursePriceProps> = ({
  priceDispatch,
}) => {
  const [price, setPrice] = useState<string | number>(0);
  const [radiovalue, setRadioValue] = useState<number>(-1);

  //   Update the disable feature depens on radio button
  const [disable, setDisable] = useState<boolean>(true);

  useEffect(() => {
    if (radiovalue === -1) {
      setPrice(0); // Set price to 0 when "Ücretsiz" radio button is selected
      setDisable(true);
    } else {
      setDisable(false);
      setPrice(""); // Clear the input field when "Ücretli" radio button is selected
    }
  }, [radiovalue]);

  useEffect(() => {
    priceDispatch(Number(price));
  }, [price, priceDispatch]);

  return (
    <>
      <div className="row classic-transition">
        {/* Title */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Fiyat</label>
              <p className="w-75">
                Lütfen içeriğiniz için para birimi seçin ve fiyat
                belirleyin.Öğrenciler belirlenen fiyatı kendi yerel para
                birimlerinde görecektir.
              </p>
            </div>
            <div className="col-7">
              <FormControl>
                <RadioGroup
                  aria-labelledby=""
                  defaultValue="-1"
                  name="radio-buttons-group"
                  value={radiovalue}
                  onChange={(e) => {
                    setRadioValue(Number(e.target.value));
                  }}
                >
                  <FormControlLabel
                    value="-1"
                    control={<BpRadio />}
                    label="Ücretsiz"
                    sx={{
                      ".MuiTypography-root": {
                        fontFamily: "inherit",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: `${radiovalue === -1 && "rgba(86, 86, 217, 1)"}`,
                      },
                    }}
                  />
                  <FormControlLabel
                    value="1"
                    control={<BpRadio />}
                    label="Sabit Ücret"
                    sx={{
                      ".MuiTypography-root": {
                        fontFamily: "inherit",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: `${radiovalue === 1 && "rgba(86, 86, 217, 1)"}`,
                      },
                    }}
                  />
                  <PriceInput
                    price={price}
                    setPrice={setPrice}
                    disable={disable}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default CreateCoursePrice;
