import { useState } from "react";
import { RegisterModel,RegisterRecordCheckReqModel } from "../../../../infrastructure/models/auth/register_model";
import { AuthRepositoryImpl } from "../../../../infrastructure/repositories/auth_repository_impl";
import useLoading from "../../../../hooks/userLoading";
import { useNavigate } from "react-router-dom";

export default function RegisterViewModel() {
    const [callRegisterInstructorState, callRegisterInstructor] = useState<RegisterModel[]>();
    const { setLoading } = useLoading();
    const navigate = useNavigate();

    const auth = new AuthRepositoryImpl();
     
    async function createRegisterInstructor(model:RegisterModel) {
      setLoading({isLoading: true,isError: false,message:""} );
      return await auth.createRegisterInstructor(model).then((res)=>{
        setLoading({isLoading: false,isError: res.isError,message:res.message+" "+res.obj??"" });
        //!res.isError ? navigate("/") : navigate("/register");
         return res;
      });
    }

    async function createRegisterStudent(model:RegisterModel) {
      setLoading({isLoading: true,isError: false,message:""} );
        return await auth.createRegisterStudent(model).then((res)=>{
          setLoading({isLoading: false,isError: res.isError,message:res.message+" "+res.obj??"" });
          //!res.isError ? navigate("/") : navigate("/register");
          return res;
        });
      }

      async function checkRegisterRecordStudent(model:RegisterRecordCheckReqModel) {
        setLoading({isLoading: true,isError: false,message:""} );
        return await auth.checkRegisterStudent(model).then((res)=>{
          setLoading({isLoading: false,isError: res.isError,message:res.message });
          !res.isError ? navigate("/onboarding") : navigate("/register");
          return res;
        });
      }

      async function checkRegisterRecordInstructor(model:RegisterRecordCheckReqModel) {
        setLoading({isLoading: true,isError: false,message:""} );
        return await auth.checkRegisterInstructor(model).then((res)=>{
          setLoading({isLoading: false,isError: res.isError,message:res.message });
          !res.isError ? navigate("/onboarding") : navigate("/register");
          return res;
        });
      }
    const createRegister=async (model:RegisterModel,userType:string)=>{
          var res=userType=="Teacher"?await createRegisterInstructor(model):await createRegisterStudent(model);
          return res;
      }

      const checkRecordRegister=async (model:RegisterRecordCheckReqModel,userType:string)=>{
        var res=userType=="Teacher"?await checkRegisterRecordInstructor(model):await checkRegisterRecordStudent(model);
        return res;
    }
      
  
    return {
      createRegister,
      checkRecordRegister
      
      // createRegisterInstructor,
      //  createRegisterStudent,
      //  checkRegisterRecordStudent,
      // // createRegisterRecordCheckStudent,
      // checkRegisterInstructor,
      // // updateInstructorProfile
    };
  }