import { useContext, useDebugValue } from "react";
import LoadingContext from "../core/context/LoadingContext";

const useLoading = () => {
    const isLoading = useContext(LoadingContext);
    if(!isLoading)
    {
        throw new Error('isLoading context error!!')
    }
    // useDebugValue(auth, auth => auth?.user ? "Logged In" : "Logged Out");
     console.log(isLoading);
    // auth?.accessToken!=null?localStorage.setItem('accessToken', JSON.stringify(auth?.accessToken)):console.log(auth);
    
    return isLoading;
}

export default useLoading;