import React, { useState, useRef, useEffect, useContext } from "react";
import Button from "../Button";

// Context Architecture
import { RegisterContext } from "../../../context/useRegisterContext";
import RegisterViewModel from "../../pages/auth/view_model/registerViewModel";
import { RegisterRecordCheckReqModel } from "../../../infrastructure/models/auth/register_model";
import useAuth from "../../../hooks/Auth/useAuth";

const UserVerification: React.FunctionComponent = () => {
  //   Inputs
  const [otpInputs, setOtpInputs] = useState<string[]>(Array(4).fill(""));
  //   References
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(4).fill(null));
  const handlerSubmit=RegisterViewModel();
  const {
    state,
    OTPPicker,
    StepIncrement,StepDicrement 
  } = useContext(RegisterContext);
  const { setAuth } = useAuth();

  // Local Handler
  const handleInputChange = (value: string, index: number) => {
    // index maps to relevant inputs index number
    // value inputs value itself

    // This is how to upgrade
    setOtpInputs((previous) => {
      previous[index] = value;
      // always send new array or object
      return [...previous];
      // We have to return new array
    });

    if (value !== "" && index < inputRefs.current.length - 1) {
      const nextRef = inputRefs.current[index + 1];
      if (nextRef) {
        nextRef.focus();
      }
    }
  };
  const validateCheckHandler = () => {
    // Join input and send to the context
    const convertedOtpInputs = otpInputs.join("");
    OTPPicker(convertedOtpInputs);
    var res=handlerSubmit.checkRecordRegister(new RegisterRecordCheckReqModel(state.form.gsmNO!,convertedOtpInputs),state.form.userType!);
      res.then((re)=>{
        if(re.isError){
          StepDicrement();StepIncrement();
        }
        else{
          const accessToken = re?.obj?.accessToken;
            const roles = [state.form.userType!];
            setAuth({ roles, accessToken });

          }
      });
  };
  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, [inputRefs]);

  return (
    <>
      <div className="container-fluid bg-light vh-100  d-flex justify-content-center align-items-center classic-transition">
        <div>
          <div
            style={{ borderRadius: "10px" }}
            className="bg-white p-3 shadow-md "
          >
            {/* Header */}
            <div className="d-flex pt-3">
              <div className="mx-auto">
                <img
                  style={{ borderRadius: "10px" }}
                  className="w-100 p-2 border"
                  src="/assets/images/figma-icon/icon-phone.svg"
                  alt="phone"
                />
              </div>
              <button className="bg-transparent border-0 align-self-start">
                X
              </button>
            </div>
            {/* Main */}
            <div className="d-flex flex-column ">
              <h2 className="fw-600 font-xs mx-auto mt-3">
                Telefon numaranızı doğrulayın
              </h2>
              <p className="mx-auto text-center">
                Güvenilir bir deneyimi sürdürmek için{" "}
                <span className="fw-600 font-xs">{state.form.gsmNO!}</span>
                <br></br>
                numarasına doğrulama kodu gönderdik, lütfen <br></br>
                telefonunuzu kontrol edin.
              </p>
              <div className="d-flex justify-content-center ">
                {otpInputs.map((ref, index) => (
                  <input
                    placeholder=""
                    maxLength={1}
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "10px",
                    }}
                    className={`ml-2 text-center border-primary-600 text-primary-600 fw-500 display3-size  `}
                    key={index}
                    value={otpInputs[index]}
                    ref={(element) => {
                      return (inputRefs.current[index] = element);
                    }}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    // other input props...
                  />
                ))}
              </div>
            </div>
            <Button
              onClick={() => {
                validateCheckHandler();
              }}
              sx={{ borderRadius: "10px" }}
              className="bg-primary-600 text-white  fw-600 border py-2  mt-3 mb-3 w-100"
            >
              Doğrula
            </Button>
          </div>
          <p className="text-center mt-4">
            Zaten hesabiniz var mı ?{" "}
            <a className="text-primary-600 fw-600" href="/">
              Giriş Yap
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default UserVerification;