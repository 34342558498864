import React, { useEffect, useState } from "react";
import IPage from "../../../../core/interfaces/page";

import {
  CoverColor,
  UserInfo,
  InfoPanel,
  ProfileInteraction,
  ProfileStatistics,
  WorkExperience,
} from "../../../components/profile";

import { Courses } from "../../../components/course";

import { Tabs, TabList, TabPanel } from "@mui/joy";
import Tab, { tabClasses } from "@mui/joy/Tab";
// import Layout from "../../layout/layout";
import ProfileViewModel from "../view_model/profile_view_model";
import { TUser } from "../../../components/profile/UserInfo";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

const workExperienceDetailsData = [
  {
    position: "Lead Product Designer",
    company: "Eduiko Inc.",
    stardate: "May 2020",
    enddate: "Present",
  },
  {
    position: "Senior Developer",
    company: "Tech Solutions",
    stardate: "January 2018",
    enddate: "December 2019",
  },
  {
    position: "Lead Product Designer",
    company: "Layers",
    stardate: "May 2020",
    enddate: "Present",
  },
  {
    position: "Senior Developer",
    company: "Tech Solutions",
    stardate: "January 2018",
    enddate: "December 2019",
  },
];

const ProfilePage: React.FunctionComponent<IPage> = (props) => {
  // Component use active skeleton therefore dynamic loading logic is expected from backend
  const [temperoryLoading] = useState(false);
  const [userInitial, setUser] = useState<TUser>();

  const handlerSubmit = ProfileViewModel();
  useEffect(() => {
    console.log("api");
    handlerSubmit.getProfile().then((res) => {
      if (!res.isError) {
        const userInitial2 = {
          name: res.obj.name,
          surname: res.obj.surName,
          usertype: "Teacher",
          title: res.obj.description,
          school: res.obj.workExperiences[0],
          expertness: res.obj.workExperiences,
          //[
          //   "UX,UI Design",
          //   "Brand Strategy",
          //   "Product Design",
          //   "Product Design",
          //   "Product Design",
          //   "Product Design",
          //   "Product Design",
          // ],
          about: res.obj.biography,
          socialLink: {
            x: "",
            linkedin: "",
            website: "sss.com",
          },
          location: {
            city: "Melbourne",
            country: "Australia",
          },
          experience: {
            month: 1,
            year: 13,
          },
          email: "hello@rileyomoore.com",
          imgSource: "/assets/images/figma-icon/avatar.svg",
        };

        setUser(userInitial2);
        console.log(res.obj.name);
      }
    });
  }, [temperoryLoading]);

  return (
    <>
      <Header />

      <div className="container">
        <div className="row px-0 full-width">
          <div className="col-12 px-0 ">
            <CoverColor />
          </div>
        </div>
        <div className="row ">
          {/* Main Left */}
          <div className="col-12 col-sm-7 position-relative">
            {/* ONLY mobile Version */}
            <div
              style={{ left: "0", top: "-10%" }}
              className="d-block d-sm-none position-absolute p-3"
            >
              <ProfileInteraction></ProfileInteraction>
            </div>

            <div className="mt-3 mt-sm-0">
              <UserInfo shadowInitial={true} user={userInitial} />
            </div>
            <div className="mt-3 pb-4 border-bottom">
              <InfoPanel userdetail={userInitial} />
            </div>
            {/* Statistic */}
            <div className="mt-3 pb-4 border-bottom">
              <h2 className="fw-500 font-xss">Statistics</h2>
              <div className="mt-3">
                <ProfileStatistics loading={temperoryLoading} />
              </div>
            </div>
            {/* Work Experience */}
            <div className="mt-3 pb-4 ">
              <h2 className="fw-500 font-xss">Work Experience</h2>
              <div className="mt-3">
                <div
                  style={{ gap: "16px" }}
                  className="d-flex flex-wrap justify-content-center"
                >
                  <WorkExperience
                    workExperienceDetailsCard={workExperienceDetailsData}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Main Right */}
          <div className="col-5 mt-3">
            <div className="d-none d-sm-flex justify-content-sm-end">
              <ProfileInteraction></ProfileInteraction>
            </div>
          </div>
        </div>

        {/* Bottom Line */}
        <div className="row">
          <div className="col-12">
            <h2 className="fw-500 font-xss">Öğretmen Detay</h2>
          </div>

          {/* Tab Section */}
          <div className="col-12">
            <Tabs
              aria-label="tabs"
              defaultValue={0}
              sx={{ bgcolor: "transparent" }}
            >
              <TabList
                disableUnderline
                sx={{
                  // Tab Customation
                  display: "flex",
                  justifyContent: "center",
                  // Tab Customation
                  p: 0.5,
                  gap: 0.5,
                  borderRadius: "xl",
                  bgcolor: "background.level1",
                  [`& .${tabClasses.root}[aria-selected="true"]`]: {
                    boxShadow: "sm",
                    bgcolor: "background.surface",
                  },
                }}
              >
                <Tab disableIndicator>Kurslarım</Tab>
                <Tab disableIndicator>Yorumlar</Tab>
                <Tab disableIndicator>Eğitimlerim</Tab>
                <Tab disableIndicator>Sertifikalarım</Tab>
              </TabList>

              <TabPanel
                value={0}
                sx={{
                  minHeight: "500px",
                  backgroundColor: "rgba(248, 250, 252, 1)",
                }}
              >
                {/* Course Section */}
                <Courses />
              </TabPanel>
              <TabPanel
                value={1}
                sx={{
                  minHeight: "500px",
                  backgroundColor: "rgba(248, 250, 252, 1)",
                }}
              >
                <b>Second</b> tab panel
              </TabPanel>
              <TabPanel
                value={2}
                sx={{
                  minHeight: "500px",
                  backgroundColor: "rgba(248, 250, 252, 1)",
                }}
              >
                <b>Third</b> tab panel
              </TabPanel>
              <TabPanel
                value={3}
                sx={{
                  minHeight: "500px",
                  backgroundColor: "rgba(248, 250, 252, 1)",
                }}
              >
                <b>Fourth</b> tab panel
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfilePage;
