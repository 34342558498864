import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/joy/Button";

import {
  Drawer,
  ModalClose,
  Menu,
  MenuButton,
  MenuItem,
  Dropdown,
} from "@mui/joy";

// Components
import { PrimaryButton, BorderlessButton } from "./Button";

type NavigationItem = {
  index: number;
  listname: string;
  to: string;
  children?: NavigationItem[];
};

const navigationList: NavigationItem[] = [
  {
    index: 1,
    listname: "Home",
    to: "/",
    children: [
      {
        index: 11,
        listname: "Children1",
        to: "/somewhere",
      },
      {
        index: 12,
        listname: "Children2",
        to: "/somewhere2",
      },
    ],
  },
  {
    index: 2,
    listname: "Navigation Item",
    to: "/",
    children: [
      {
        index: 11,
        listname: "Children1",
        to: "/somewhere",
      },
      {
        index: 12,
        listname: "Children2",
        to: "/somewhere2",
      },
    ],
  },
  {
    index: 3,
    listname: "Navigation Item",
    to: "/",
    children: [
      {
        index: 11,
        listname: "Children1",
        to: "/somewhere",
      },
      {
        index: 12,
        listname: "Children2",
        to: "/somewhere2",
      },
    ],
  },
  {
    index: 4,
    listname: "Navigation Item",
    to: "/",
    children: [],
  },
];
const Navbar: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <nav className="container px-0">
      {/* Desktop */}
      <div
        style={{
          top: "0",
          zIndex: "99999",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "0 -40px 10px white",
        }}
        className="border d-none d-sm-flex px-3 p-3 mt-3  position-fixed container "
      >
        <img
          className=""
          src="/assets/images/figma-icon/Logo.svg"
          alt="Logo"
        ></img>
        <ul className="d-flex align-self-center ">
          {navigationList.map((item, index) => {
            // If has children
            if (
              typeof item.children !== "undefined" &&
              item.children.length > 0
            ) {
              return (
                <Dropdown key={index}>
                  <MenuButton
                    sx={{ marginLeft: "5px" }}
                    variant="plain"
                    color="neutral"
                  >
                    {item.listname}
                    <span className="ml-1 feather-chevron-down"></span>
                  </MenuButton>
                  <Menu variant="plain" color="neutral">
                    {item.children.map((children, index) => {
                      return (
                        <MenuItem key={index}>
                          <Link
                            style={{ color: "inherit", textDecoration: "none" }}
                            to={children.to}
                          >
                            {children.listname}
                          </Link>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Dropdown>
              );
            }

            return (
              // Conditional rendering , if a item has children create them as Menubutton and locate children underneath it

              <Dropdown key={index}>
                <MenuButton
                  sx={{ marginLeft: "5px" }}
                  variant="plain"
                  color="neutral"
                >
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to={item.to}
                  >
                    {item.listname}
                  </Link>
                </MenuButton>
              </Dropdown>
            );
          })}
        </ul>

        <div className="d-flex align-self-center ml-auto">
          <BorderlessButton className="mr-3">Log In</BorderlessButton>
          <PrimaryButton
            onClick={() => {
              navigate("/register");
            }}
            className="px-3"
          >
            Get Started For Free
          </PrimaryButton>
        </div>
      </div>

      {/* Mobile */}

      <div
        style={{
          top: "0",
          zIndex: "99999",
          backgroundColor: "white",
        }}
        className="position-fixed container"
      >
        <div className="row border d-sm-none text-center py-2">
          <div className="col-12 d-flex justify-content-center position-relative">
            <Button
              onClick={() => {
                // toggle it
                setOpen((previous) => !previous);
              }}
              className="position-absolute start-0 "
              sx={{ left: "5%", top: "10%" }}
              variant="plain"
            >
              <MenuIcon></MenuIcon>
            </Button>

            <Drawer
              open={open}
              component="div"
              anchor="left"
              color="neutral"
              size="sm"
              variant="outlined"
              onClose={() => setOpen(false)}
              invertedColors={true}
            >
              {/* Drawer inside  */}

              {/* Drawer Header */}
              <div className="d-flex justify-content-center mt-3">
                <img
                  className=""
                  src="/assets/images/figma-icon/Logo.svg"
                  alt="Logo"
                ></img>

                <ModalClose></ModalClose>
              </div>

              {/* Drawer Main Content */}

              <ul className="d-flex flex-column mt-4 ">
                {navigationList.map((item, index) => {
                  // If has children
                  if (
                    typeof item.children !== "undefined" &&
                    item.children.length > 0
                  ) {
                    return (
                      <Dropdown key={index}>
                        <MenuButton
                          sx={{ marginLeft: "5px" }}
                          variant="plain"
                          color="neutral"
                        >
                          {item.listname}
                          <span className="ml-1"> &darr;</span>
                        </MenuButton>
                        <Menu
                          sx={{ zIndex: "999" }}
                          variant="plain"
                          color="neutral"
                        >
                          {item.children.map((children, index) => {
                            return (
                              <MenuItem key={index}>
                                <Link
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                  to={children.to}
                                >
                                  {children.listname}
                                </Link>
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      </Dropdown>
                    );
                  }

                  return (
                    // Conditional rendering , if a item has children create them as Menubutton and locate children underneath it

                    <Dropdown key={index}>
                      <MenuButton
                        sx={{ marginLeft: "5px" }}
                        variant="plain"
                        color="neutral"
                      >
                        <Link
                          style={{ color: "inherit", textDecoration: "none" }}
                          to={item.to}
                        >
                          {item.listname}
                        </Link>
                      </MenuButton>
                    </Dropdown>
                  );
                })}
              </ul>

              <PrimaryButton>Purple Button</PrimaryButton>
            </Drawer>

            <img
              className=""
              src="/assets/images/figma-icon/Logo.svg"
              alt="Logo"
            ></img>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
