import React from 'react';
import './App.css';
import {  Route, Routes } from 'react-router-dom';
import routes from './core/config/routes';
import RequireAuth from './presentation/components/RequireAuth';
import Layout from "./presentation/pages/layout/layout";

//import i18next from './core/config/i18n';


function App() {

  // if (window.performance) {
  //   if (performance.navigation.type == 1) {
  //     alert( "This page is reloaded" );
  //   } else {
  //     alert( "This page is not reloaded");
  //   }
  // }
  
  return (
    <Routes>
      {/* <Layout></Layout> */}
       {routes.map((route, index) => {
        if (route.requireAuth) {
          return (
            <Route key={index} element={<RequireAuth allowedRoles={route.allowedRoles} />} path={route.path}>
              <Route path={route.path} element={React.createElement(route.component)} />
            </Route>
          );
        } else {
          return <Route key={index} path={route.path} element={React.createElement(route.component)} />;
        }
      })}
      
      
    </Routes>

  );


    
}

export default App;
