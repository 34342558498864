import React from "react";

// Dummy Data Section

const dummydata = [
  {
    id: 1001,
    author: "Sir Alex Ferguson",
    lessonQuantity: 9,
    courseDuration: {
      minutes: 30,
      hours: 3,
    },
    courseContent: {
      header: "100 Days of Code: The Complete Python Pro Bootcamp",
      detail:
        "Master Python by building 100 projects in 100 days. Learn data science, automation, build websites, games and apps!",
      courseGains: [
        "You will master the Python programming language by building 100 unique projects over 100 days.",
        "You will learn automation, game, app and web development, data science and machine learning all using Python.",
        "You will be able to program in Python professionally",
      ],
    },
    audience: ["10", "11", "12"],
    registeredStudent: 40,
    price: 540,
  },
  {
    id: 2002,
    author: "Sir Alex Ferguson",
    lessonQuantity: 9,
    courseDuration: {
      minutes: 30,
      hours: 3,
    },
    courseContent: {
      header: "100 Days of Code: The Complete Python Pro Bootcamp",
      detail:
        "Master Python by building 100 projects in 100 days. Learn data science, automation, build websites, games and apps!",
      courseGains: [
        "You will master the Python programming language by building 100 unique projects over 100 days.",
        "You will learn automation, game, app and web development, data science and machine learning all using Python.",
        "You will be able to program in Python professionally",
      ],
    },
    audience: ["10", "11", "12"],
    registeredStudent: 40,
    price: 540,
  },
  {
    id: 3003,
    author: "Sir Alex Ferguson",
    lessonQuantity: 9,
    courseDuration: {
      minutes: 30,
      hours: 3,
    },
    courseContent: {
      header: "100 Days of Code: The Complete Python Pro Bootcamp",
      detail:
        "Master Python by building 100 projects in 100 days. Learn data science, automation, build websites, games and apps!",
      courseGains: [
        "You will master the Python programming language by building 100 unique projects over 100 days.",
        "You will learn automation, game, app and web development, data science and machine learning all using Python.",
        "You will be able to program in Python professionally",
      ],
    },
    audience: ["10", "11", "12"],
    registeredStudent: 40,
    price: 540,
  },
  {
    id: 4004,
    author: "Sir Alex Ferguson",
    lessonQuantity: 9,
    courseDuration: {
      minutes: 30,
      hours: 3,
    },
    courseContent: {
      header: "100 Days of Code: The Complete Python Pro Bootcamp",
      detail:
        "Master Python by building 100 projects in 100 days. Learn data science, automation, build websites, games and apps!",
      courseGains: [
        "You will master the Python programming language by building 100 unique projects over 100 days.",
        "You will learn automation, game, app and web development, data science and machine learning all using Python.",
        "You will be able to program in Python professionally",
      ],
    },
    audience: ["10", "11", "12"],
    registeredStudent: 40,
    price: 540,
  },
];

const Icon: React.FunctionComponent = () => {
  return (
    <img
      style={{ width: "18px", height: "18px" }}
      className="mr-2"
      src="/assets/images/figma-icon/icon-card-explanation.svg"
      alt="detail"
    ></img>
  );
};

const CourseSearchBar = () => {
  return <>{/* <p>Course Search Bar</p> */}</>;
};

interface ICourseCardDetails {
  id: number;
  author: string;
  lessonQuantity: number;
  courseDuration: {
    minutes: number;
    hours: number;
  };
  courseContent: {
    header: string;
    detail: string;
    courseGains: string[];
  };
  audience: string[];
  registeredStudent: number;
  price: number;
}

type Style = {
  classname?: string;
};

const CourseCardDetails: React.FunctionComponent<
  ICourseCardDetails & Style
> = ({
  id,
  lessonQuantity,
  courseDuration,
  courseContent,
  author,
  audience,
  registeredStudent,
  price,
  classname,
}) => {
  // Destructure
  const { minutes, hours } = courseDuration;
  const { header, detail } = courseContent;

  return (
    <>
      {/* Cors Mouse Over Pop-Up Will be Located here */}

      {/* Course Main Card */}
      <div className={classname}>
        <div
          style={{ maxWidth: "443px", maxHeight: "240px", overflow: "hidden" }}
        >
          <img
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            src="/assets/images/figma-icon/course-example-cover-image.svg"
            alt="courseImg"
          ></img>
        </div>

        {/* Labeling */}
        <div
          style={{ cursor: "pointer" }}
          className="d-flex font-xssss text-primary-600 mt-3"
        >
          <div
            style={{ borderRadius: "16px", maxWidth: "content" }}
            className="d-flex border p-1 pr-2 bg-light"
          >
            <div style={{ borderRadius: "16px" }} className="border bg-white">
              <p className="px-2 text-center">
                <span>{lessonQuantity}</span> ders
              </p>
            </div>
            <p className="ml-2 align-self-center text-center  ">
              Toplam {hours ? `${hours} saat` : ""}{" "}
              {minutes ? `${minutes} dakika` : ""}
            </p>
          </div>
        </div>

        {/* Content */}

        <div className="mt-3">
          <h2 className="font-xs fw-500"> {header}</h2>
          <span className="font-xsss text-neutral-600 fw-500">
            <Icon />
            {author}
          </span>

          <div className="font-xsss text-neutral-600 fw-500 d-flex align-items-center mt-1">
            <ul className="d-flex">
              <Icon />
              {audience.map((item, index) => {
                return (
                  <li className="mr-2 " key={index}>
                    {item}.
                  </li>
                );
              })}

              {/* Dynamic texing  */}
              {audience.length > 1 ? "sınıflar" : "sınıf"}
            </ul>

            <span className="d-flex ml-3">
              <Icon />
              {registeredStudent}
              {" öğrenci"}
            </span>
          </div>
        </div>
        {/* Price */}
        <p className="mt-2 fw-600">₺{price}</p>
      </div>
    </>
  );
};

interface Props {
  courseCardDetails: ICourseCardDetails[];
}

const CourseCards: React.FunctionComponent<Props> = ({ courseCardDetails }) => {
  return (
    <>
      <div className="row d-flex justify-content-center">
        {courseCardDetails.map((item) => {
          return (
            <CourseCardDetails
              key={item.id}
              {...item}
              classname="col-12 col-sm-5 mt-3"
            />
          );
        })}
      </div>
    </>
  );
};

const Courses = () => {
  return (
    <>
      <CourseSearchBar />
      <CourseCards courseCardDetails={dummydata} />
    </>
  );
};

export default Courses;
