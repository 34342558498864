import React, { memo } from "react";
import { Avatar, Divider } from "@mui/joy";
import { useState } from "react";
import { HomePageSpan } from "../home";

// Types&InterFaces
export type TUser = {
  name: string;
  surname:string;
  usertype: string;
  school: string;
  expertness?: string[];
  target?: string[];
  about: string;
  socialLink: {
    x: string;
    linkedin: string;
    website: string;
  };
  location: {
    city: string;
    country: string;
  };
  experience: {
    month: number;
    year: number;
  };
  email: string;
  imgSource: string;
};

interface Props {
  user?: TUser;
  shadowInitial: boolean;
}
const UserInfo: React.FunctionComponent<Props> = ({ user, shadowInitial }) => {
  // Divider
  const [shadow, setShadow] = useState(shadowInitial);
  const [clip, setClip] = useState(true);

  return (
    <>
      <div>
        {/* Header */}
        <div style={{ transform: "translate(0,-30%)" }} className="d-flex">
          <Avatar
            alt={user?.name}
            src={user?.imgSource}
            sx={{ width: 164, height: 164, border: "4px solid white" }}
          ></Avatar>

          <div className="align-self-center ml-4 mt-4">
            <h2 className="fw-600 fs-30 ls-1 m-0">{user?.name} {user?.surname}</h2>
            <span className="">
              {user?.usertype} / {user?.school}
            </span>
            <span className="">{/* {user.title} */}</span>
          </div>
        </div>

        {/* Overview */}
        <div>
          <h3 className="fw-600 font-xs">Overview</h3>
          <p className="text-neutral-600">
            {/* Custom rendering depens on usertype */}
            {user?.usertype === "Teacher"
              ? "Uzmanlık Alanı"
              : "Ilgilendigi alanlar"}

            {user?.usertype === "Teacher"
              ? user.expertness?.map((item, index) => {
                  // conditional rendering allows first 4 experience render rest of them inside of informer
                  //   find out length of array then if index is the last one
                  //  render one and finish
                  const currentIndex = Number(index + 1);
                  const threshold = 3;
                  const arrayLength = user.expertness?.length;
                  if (currentIndex <= threshold) {
                    return (
                      <HomePageSpan className="ml-1" key={index}>
                        {item}
                      </HomePageSpan>
                    );
                  } else if (currentIndex === arrayLength) {
                    // if index is the last one
                    return (
                      <HomePageSpan key={index}>
                        +{arrayLength - threshold}
                      </HomePageSpan>
                    );
                  } else {
                    // For non-first 3 and non-last item, return an empty element
                    return <React.Fragment key={index}></React.Fragment>;
                  }
                })
              : // Student
                user?.target?.map((item, index) => {
                  // conditional rendering allows first 4 experience render rest of them inside of informer
                  //   find out length of array then if index is the last one
                  //  render one and finish
                  const currentIndex = Number(index + 1);
                  const threshold = 3;
                  const arrayLength = user.target?.length;
                  if (currentIndex <= threshold) {
                    return (
                      <HomePageSpan className="ml-1" key={index}>
                        {item}
                      </HomePageSpan>
                    );
                  } else if (currentIndex === arrayLength) {
                    // if index is the last one
                    return (
                      <HomePageSpan key={index}>
                        +{arrayLength - threshold}
                      </HomePageSpan>
                    );
                  } else {
                    // For non-first 3 and non-last item, return an empty element
                    return <React.Fragment key={index}></React.Fragment>;
                  }
                })}
          </p>
        </div>

        {/* Divider  */}
        <Divider
          sx={{
            marginTop: "10px",
            boxShadow: shadow
              ? "0 0 0 100vmax var(--Divider-lineColor)"
              : "initial",
            clipPath: clip ? "inset(0px -100vmax)" : "initial",
          }}
        />

        {/* About me */}

        <div className="mt-2">
          <span className="fw-600">About me</span>
          <p className="mt-1">{user?.about}</p>
        </div>
      </div>
    </>
  );
};

export default memo(UserInfo);
