import { ProfileInstructorDescriptionDetailUpdateReq } from "../../../domain/entities/profile/profile_instructor_description_detail_update_req"

export class ProfileInstructorDescriptionDetailUpdateReqModel implements ProfileInstructorDescriptionDetailUpdateReq {
    
    description: string;
    biography: string;
    

    constructor(description:string,biography:string)
    {
      this.biography=biography;
      this.description=description;
    }
  }