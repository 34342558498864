import React from "react";
import IPage from "../../core/interfaces/page";

import Header from "./layout/header";
import Footer from "./layout/footer";
import { Hero, Metric, HomeFeatures } from "../components/home";

const HomePage: React.FunctionComponent<IPage> = (props) => {
  return (
    <>
      <Header />
      <div style={{ minHeight: "100vh" }}>
        <Hero />
        <Metric />
        {/* <Promotion /> */}
        <HomeFeatures />
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
