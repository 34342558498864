import React, { useState } from "react";
import { EpisodePresentationProps } from "./EpisodePresentation";
import { BorderlessButton, IconButton, PrimaryButton } from "../../Button";
import { CourseEpisodeType } from "../../../pages/course/create-course";

import { Modal, ModalClose, ModalDialog } from "@mui/joy";

const options1: Intl.DateTimeFormatOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "2-digit",
};

const options2: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
};

interface EposideDetailProps {
  episodeContent: CourseEpisodeType;
  editEpisodeHandle?: () => void;
  deleteEpisodeHandle: (id: number) => void;
}

const EpisodeDetail: React.FunctionComponent<EposideDetailProps> = ({
  episodeContent,
  deleteEpisodeHandle,
}) => {
  const [modal, setModal] = useState<boolean>(false);
  // Distructure
  const { id, title, subtitle, date, duration } = episodeContent;

  let day = "";
  let time = "";

  const displayDayAndTime = (date: Date): { day: string; time: string } => {
    const day = date.toLocaleDateString("tr-TR", options1);
    const time = date.toLocaleDateString("tr-TR", options2).split(" ")[1];
    return { day, time };
  };

  if (date) {
    ({ day, time } = displayDayAndTime(date));
  }

  const contentForPopUp = (
    <div className="d-flex flex-column">
      <div
        style={{ gap: "4px" }}
        className="d-flex"
        onClick={() => {
          setModal(true);
        }}
      >
        <span className="align-self-center">Düzenle</span>
        <img
          src="/assets/images/figma-icon/icon-episode-edit.svg"
          alt="edit"
        ></img>
      </div>
      <div
        style={{ gap: "4px" }}
        className="d-flex"
        onClick={() => {
          if (typeof id === "number") deleteEpisodeHandle(id);
        }}
      >
        <span className="align-self-center">Kaldır</span>

        <img
          className="ml-auto"
          src="/assets/images/figma-icon/icon-episode-delete.svg"
          alt="edit"
        ></img>
      </div>
    </div>
  );

  return (
    <>
      <div
        style={{ borderRadius: "12px", gap: "20px", cursor: "pointer" }}
        className="border p-3 d-flex "
      >
        {/* Img */}
        <div style={{ width: "260px", height: "152px", overflow: "hidden" }}>
          <img
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              borderRadius: "12px",
            }}
            src="/assets/images/Hero/illustration.svg"
            alt="episodes"
          ></img>
        </div>
        {/* Content */}
        <div className="d-flex flex-column justify-content-center">
          <div className="mb-3">
            <h6 className="fw-600 text-primary-600 font-xsss">Bölüm-{id}</h6>
            <h5 className="fw-500 font-xs">{title}</h5>
            <p className="font-xss text-neutral-600">{subtitle}</p>
          </div>

          {/* Time detail etc. */}
          <div className="episode-detail-wrapper">
            <div style={{ gap: "24px" }} className="d-flex">
              {/* Calendar */}
              <div style={{ gap: "8px" }} className="d-flex">
                <img
                  style={{ width: "20px", height: "20px" }}
                  className="align-self-center"
                  src="/assets/images/figma-icon/icon-episode-calendar.svg"
                  alt="calendar"
                ></img>
                <span className="text-neutral-600 fw-500">{day}</span>
              </div>
              {/* Date */}
              <div style={{ gap: "8px" }} className="d-flex">
                <img
                  style={{ width: "20px", height: "20px" }}
                  className="align-self-center"
                  src="/assets/images/figma-icon/icon-episode-clock.svg"
                  alt="calendar"
                ></img>
                <span className="text-neutral-600 fw-500">{time}</span>
              </div>
              {/* Duration */}
              <div style={{ gap: "8px" }} className="d-flex">
                <img
                  style={{ width: "20px", height: "20px" }}
                  className="align-self-center"
                  src="/assets/images/figma-icon/icon-episode-duration.svg"
                  alt="calendar"
                ></img>
                <span className="text-neutral-600 fw-500">{duration.name}</span>
              </div>
            </div>
          </div>
        </div>
        {/* Settings Buton Group */}
        <div className="ml-auto">
          <IconButton
            className="border p-2"
            src="/assets/images/figma-icon/icon-episode-setting.svg"
            height="20px"
            width="20px"
            onPopUp
            onPopUpContent={contentForPopUp}
          />
        </div>
      </div>

      {/* Edit Modal */}
      <Modal
        open={modal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        onClose={() => {
          setModal(false);
        }}
      >
        <ModalDialog layout="center">
          <div>
            <ModalClose variant="plain" />
          </div>

          <div>
            <p>Episode Edit Modal</p>
          </div>

          {/* Settings Buton Group */}
          <div className="">
            <div style={{ gap: "12px" }} className="d-flex justify-content-end">
              <BorderlessButton
                onClick={() => {
                  setModal(false);
                }}
                className="border p-2"
              >
                Vazgeç
              </BorderlessButton>
              <PrimaryButton className="p-2">Tamam</PrimaryButton>
            </div>
          </div>
        </ModalDialog>
      </Modal>
    </>
  );
};

const EpisodeList: React.FunctionComponent<EpisodePresentationProps> = ({
  episodesDispatch,
  episodesState,
  setIsCreateNewSectionActive,
}) => {
  // Render the Episode list
  const episodeList = episodesState;
  // Show-Up Quantity
  const episodeQuantity = episodeList.length;

  // Edit And Delete Handler

  const deleteEpisodeHandle = (id: number): void => {
    // Filter out the deleted episode
    const remainEpsiodes = episodeList.filter((episode) => {
      return episode.id !== id;
    });

    // Update the IDs of remaining episodes
    const updatedEpisodes = remainEpsiodes.map((episode, index) => {
      return { ...episode, id: index + 1 };
    });

    episodesDispatch(updatedEpisodes);
  };

  return (
    <>
      <div className="row classic-transition">
        {/* Header */}
        <div className="col-12 d-flex justify-content-between mb-2">
          <h2 className="fw-600 font-xs align-self-center ">
            Bölümler ({episodeQuantity})
          </h2>
          <BorderlessButton
            onClick={() => {
              setIsCreateNewSectionActive(true);
            }}
            className="border text-neutral-700 py-2 px-3"
          >
            + Yeni bölüm ekle
          </BorderlessButton>
        </div>
        {/* List Section */}
        <div style={{ gap: "16px" }} className="col-12 d-flex flex-column">
          {episodeList.map((episode) => {
            return (
              <EpisodeDetail
                key={episode.id}
                episodeContent={episode}
                deleteEpisodeHandle={deleteEpisodeHandle}
              ></EpisodeDetail>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EpisodeList;
