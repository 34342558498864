import { Tabs, TabList, TabPanel } from "@mui/joy";
import Tab, { tabClasses } from "@mui/joy/Tab";
import React, { useEffect, useState } from "react";
import {
  CreateCourseDetails,
  CreateCourseMedia,
  CreateCourseSection,
  CreateCoursePrice,
  CreateCourseSettings,
} from "../../components/course";

enum StepTitleEnum {
  "Seri Hakkinda",
  "Medya",
  "Eğitim Planı",
  "Fiyatlandırma",
  "Ayarlar",
}

// Tab list represent Tab Selector , Tab panel is the component which is located in selected panel

export type InputType = {
  name: string;
  value: string | number;
};

// series
export type SeriesStateType = {
  title: string;
  subtitle: string;
  description: string;
  category: string;
  subcategory: string;
  gains: InputType[];
  requirements: InputType[];
  audiences: InputType[];
  msgwelcome: string;
  msgcongrat: string;
};
const initialSeriesState: SeriesStateType = {
  title: "",
  subtitle: "",
  description: "",
  category: "",
  subcategory: "",
  gains: [
    {
      name: "input-1",
      value: "",
    },
    {
      name: "input-2",
      value: "",
    },
    {
      name: "input-3",
      value: "",
    },
  ],
  requirements: [
    {
      name: "input-1",
      value: "",
    },
  ],
  audiences: [
    {
      name: "input-1",
      value: "",
    },
  ],
  msgwelcome: "",
  msgcongrat: "",
};

// media
export type MediaStateType = {
  coverpicture: File | null;
  promovideo: File | null;
};
const initialMediaState: MediaStateType = {
  coverpicture: null,
  promovideo: null,
};

// course - episode
export type CourseEpisodeType = {
  id: number | undefined;
  title: string;
  subtitle: string;
  date: Date | undefined;
  duration: InputType;
  // source: string[];
};

const initialEpisodeStates: CourseEpisodeType[] | [] = [];

// prices

// settings
export type CourseSettingsType = {
  capacity: number;
  closureDate: Date | undefined;
  closureType: "capacityBase" | "dateBase";
};
const initialCourseSettings: CourseSettingsType = {
  capacity: 20,
  closureDate: undefined,
  closureType: "capacityBase",
};

const CreateCoursePage: React.FunctionComponent = () => {
  const [tabstepper, setTabStepper] = useState<number>(0);

  // Clasified state series
  const [series, setSeries] = useState<SeriesStateType>(initialSeriesState);
  const [media, setMedia] = useState<MediaStateType>(initialMediaState);
  const [episodes, setEpisodes] =
    useState<CourseEpisodeType[]>(initialEpisodeStates);
  const [price, setPrice] = useState<number | undefined>();
  const [settings, setSettings] = useState(initialCourseSettings);

  // MainState
  const [formData, setFormData] = useState({
    series: series,
    media: media,
    episodes: episodes,
    price: price,
    settings: settings,
  });

  const handleSubmit = () => {
    setFormData({
      series: series,
      media: media,
      episodes: episodes,
      price: price,
      settings: settings,
    });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  type TabElementType = {
    tabList: string;
    tabPanel: JSX.Element;
  };
  const tabDataSource: TabElementType[] = [
    {
      tabList: "Seri Detayları",
      tabPanel: (
        <CreateCourseDetails seriesState={series} seriesDispatch={setSeries} />
      ),
    },
    {
      tabList: "Medya",
      tabPanel: (
        <CreateCourseMedia mediaState={media} mediaDispatch={setMedia} />
      ),
    },
    {
      tabList: "Eğitim Planı",
      tabPanel: (
        <CreateCourseSection
          episodesState={episodes}
          episodesDispatch={setEpisodes}
        />
      ),
    },
    {
      tabList: "Fiyatlandırma",
      tabPanel: <CreateCoursePrice priceDispatch={setPrice} />,
    },
    {
      tabList: "Ayarlar",
      tabPanel: <CreateCourseSettings settingsDispacth={setSettings} />,
    },
  ];

  // all form states will be located here , children components which located inside of tab will mutate them

  // Seperation of tablist from DataSource Object and gather together in order to render
  const destructuredTabList = tabDataSource.map((item) => {
    return item.tabList;
  });
  const destructuredTabPanel = tabDataSource.map((item) => {
    return item.tabPanel;
  });

  return (
    <>
      <div className="container">
        <div className="row mx-0">
          <h2 className="fw-600 fs-30">{StepTitleEnum[tabstepper]}</h2>
        </div>
        <hr />
        <div className="row mt-4">
          <div className="tab-general-wrapper col-12">
            <Tabs
              value={tabstepper}
              className="row"
              aria-label="tabs"
              defaultValue={0}
              sx={{ bgcolor: "transparent" }}
            >
              <div className="d-flex">
                {/* Tab List */}
                <TabList
                  disableUnderline
                  sx={{
                    // Tab Customation
                    display: "flex",
                    flexDirection: "column",
                    // Tab Customation
                    p: 0.5,
                    gap: 0.5,
                    borderRadius: "xl",
                    bgcolor: "background.level0",

                    // When tab is active
                    [`& .${tabClasses.root}[aria-selected="true"]`]: {
                      boxShadow: "md",
                      bgcolor: "rgba(245, 248, 255, 1)",
                      color: "rgba(69, 65, 198, 1)",
                    },

                    fontWeight: "600",
                  }}
                >
                  {/* Tab List Elements */}
                  {destructuredTabList.map((listItem, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setTabStepper(index);
                        }}
                      >
                        <Tab
                          key={index}
                          orientation="vertical"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            color: "rgba(102, 112, 133, 1)",
                          }}
                          disableIndicator
                        >
                          {listItem}
                        </Tab>
                      </div>
                    );
                  })}
                </TabList>
                {/* Tab Panel */}
                <div className="panel-wrapper flex-fill ml-3 ">
                  {destructuredTabPanel.map((panelItem, index) => {
                    return (
                      <TabPanel
                        key={index}
                        value={index}
                        sx={{
                          minHeight: "500px",
                          backgroundColor: "transparent",
                        }}
                      >
                        {panelItem}
                      </TabPanel>
                    );
                  })}
                </div>
              </div>
            </Tabs>
          </div>
        </div>

        {/* Tab Navigation Buttons */}
        {/* Tab Control Component will be created */}
        <div className="row">
          <div className="col-12 d-flex justify-content-around">
            {/* Button represents backward */}
            <button
              style={{ minWidth: "200px", whiteSpace: "nowrap" }}
              className={`${
                destructuredTabList[tabstepper - 1] ? "visible" : "invisible"
              }`}
              onClick={() => {
                setTabStepper((previous) => previous - 1);
              }}
            >
              <span>
                Onceki: {tabstepper}. {destructuredTabList[tabstepper - 1]}
              </span>
            </button>

            <div className="forward-button-wrapper position-relative">
              {/* Button represents forward */}
              <button
                style={{ minWidth: "200px", whiteSpace: "nowrap" }}
                className={`position-absolute ${
                  destructuredTabList[tabstepper + 1] ? "visible" : "invisible"
                }`}
                onClick={() => {
                  setTabStepper((previous) => previous + 1);
                }}
              >
                <span>
                  Sonraki: {tabstepper + 2}.{" "}
                  {destructuredTabList[tabstepper + 1]}
                </span>
              </button>
              {/* If user on the last step complete button will be appear */}
              <button
                onClick={handleSubmit}
                style={{ minWidth: "200px", whiteSpace: "nowrap" }}
                className={`position-absolute ${
                  !destructuredTabList[tabstepper + 1] ? "visible" : "invisible"
                }`}
              >
                Tamamla
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCoursePage;
