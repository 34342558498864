import { Skeleton } from "@mui/joy";
import React from "react";

type ThemeColor = "BLUE" | "PINK" | "PURPLE" | "YELLOW";

type TColorPalette = {
  header: string;
  content: string;
  background: string;
  border: string;
};

const COLORPALETTE: Record<ThemeColor, string | TColorPalette> = {
  PINK: {
    header: "hsla(328, 72%, 30%, 1)",
    content: "hsla(327, 80%, 42%, 1)",
    background: "hsla(323, 80%, 98%, 1)",
    border: "rgba(252, 231, 246, 1)",
  },
  BLUE: {
    header: "hsla(218, 68%, 31%, 1)",
    content: "hsla(218, 80%, 46%, 1)",
    background: "hsla(210, 100%, 98%, 1)",
    border: "rgba(209, 233, 255, 1)",
  },
  PURPLE: {
    header: "hsla(257, 69%, 35%, 1)",
    content: "hsla(257, 72%, 50%, 1)",
    background: "hsla(240, 100%, 99%, 1)",
    border: "rgba(235, 233, 254, 1)",
  },
  YELLOW: {
    header: "hsla(26, 73%, 26%, 1)",
    content: "hsla(33, 92%, 33%, 1)",
    background: "hsla(56, 88%, 97%, 1)",
    border: "rgba(254, 247, 195, 1)",
  },
};

const StatisticDetail = ({
  theme,
  content,
  contentDetail,
  className,
  loading,
}: {
  theme: ThemeColor;
  content: string;
  contentDetail: string;
  className?: string;
  loading: boolean;
}) => {
  //   Background Color Assigment
  const themeConfig = COLORPALETTE[theme];

  const backgroundColor =
    typeof themeConfig === "string" ? themeConfig : themeConfig.background;
  const headerColor =
    typeof themeConfig === "string" ? themeConfig : themeConfig.header;
  const contentColor =
    typeof themeConfig === "string" ? themeConfig : themeConfig.content;
  const borderColor =
    typeof themeConfig === "string" ? themeConfig : themeConfig.border;

  return (
    <div
      style={{
        minWidth: "150px",
        border: `1px solid`,
        borderRadius: "10px",
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      }}
      className={`p-2 d-flex flex-column ${className}`}
    >
      {loading ? (
        <>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={48}
            height={48}
          />
          <Skeleton animation="wave" variant="text" />
          <Skeleton animation="wave" variant="text" width={80} />
        </>
      ) : (
        <>
          <div>
            <img
              src={`/assets/images/figma-icon/profile-statistic-${theme}.svg`}
              alt="thene-color"
            />
          </div>
          <div className="mt-3 ml-1">
            <span style={{ color: headerColor }} className="mt-3 fw-500">
              {content}
            </span>
            <p
              style={{ color: contentColor, whiteSpace: "nowrap" }}
              className="font-xsss"
            >
              {contentDetail}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

// Prop Interface needs to be created for Statistic
// Each StatisticDetail accept content and contentdetail property this informations will come from data array or obj

interface Props {
  loading: boolean;
}

const ProfileStatistics: React.FunctionComponent<Props> = ({ loading }) => {
  return (
    <>
      <div
        style={{ gap: "13px" }}
        className="d-flex flex-wrap justify-content-center justify-sm-content-left"
      >
        <StatisticDetail
          loading={loading}
          theme="PINK"
          content="6.400 mins"
          contentDetail="Total mentoring time"
        ></StatisticDetail>
        <StatisticDetail
          loading={loading}
          theme="PURPLE"
          content="2.867"
          contentDetail="Toplam öğrenci"
        ></StatisticDetail>
        <StatisticDetail
          loading={loading}
          theme="YELLOW"
          content="181"
          contentDetail="Sessions completed"
        ></StatisticDetail>
        <StatisticDetail
          loading={loading}
          theme="BLUE"
          content="%100"
          contentDetail="Average attendance"
        ></StatisticDetail>
      </div>
    </>
  );
};

export default ProfileStatistics;
