import React from "react";
import { useState } from "react";

const gradientColorPalet = [
  "linear-gradient(45deg, #E9DEFA 0%, #FFF6EB 100%)",
  "linear-gradient(45deg, #b3d1ff 0%, #66a3ff 100%)",
  "linear-gradient(45deg, #a4f4b8 0%, #5ad4c5 100%)",
  "linear-gradient(45deg, #ffdab9 0%, #ff6347 100%)",
  "black",
  "white",
];

const CoverColor: React.FunctionComponent = () => {
  const [selectedColorPalette, setSelectedColorPalette] = useState<number>(0);

  const colorRowStyle = {
    height: "185px",
    background: gradientColorPalet[selectedColorPalette],
  };

  return <div style={colorRowStyle}></div>;
};

export default CoverColor;
