import React, { createContext, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type LoadingContextrops={
    children:React.ReactNode;
}

interface loadingStep{
    isLoading:boolean;
    isError:boolean | any;
    message:string;
}


type LoadingContext={
    isLoadingObj:loadingStep | null;
    setLoading:React.Dispatch<React.SetStateAction<loadingStep | null>>
}
const notify = (message:string) => toast.error(message);

const LoadingContext = createContext<LoadingContext | null>(null);

export const LoadingContextPro = ({ children}:LoadingContextrops) => {
    const [isLoadingObj, setLoading] = useState<loadingStep | null>({isLoading:false,isError:false,message:""});
    if(isLoadingObj?.isError&&!isLoadingObj?.isLoading)
    {
        notify(isLoadingObj?.message);
        setLoading({isLoading:false,isError:false,message:""});
    }
     
    return (
        <LoadingContext.Provider value={{ isLoadingObj, setLoading }}>
            {<>
            
            <div className="" style={{display: isLoadingObj?.isLoading?"block ":"none "}}><span className="loader"></span>
            </div>
            
            <ToastContainer />

            {children}
            </>
                  }             
            
        </LoadingContext.Provider>
    )
}

export default LoadingContext;