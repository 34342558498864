import {
  RegisterModel,
  RegisterRecordCheckReqModel,
} from "../../models/auth/register_model";
import { LoginReponseModel } from "../../models/auth/login_reponse_model";
import {
  LoginReqModel,
  LoginWithOtpReqModel,
} from "../../models/auth/login_req_model";
import useAxiosPrivate from "../../../hooks/Auth/useAxiosPrivate";
import { useState } from "react";
import axios from "../../../core/network/axios";
import useLoading from "../../../hooks/userLoading";

const RemoteAuthDataSource = () => {
  //const { setLoading } = useLoading();

  return {
    async login(model: LoginReqModel): Promise<LoginReponseModel> {
      // setLoading( true);
      const res = await axios.post<LoginReponseModel>(
        "/Authentication/Login",
        model
      );
      console.log(res);

      return res.data;
      // res.data.map((x) => this.mapToModel(x));
    },

    async loginOtp(model: LoginWithOtpReqModel): Promise<LoginReponseModel> {
      const res = await axios.post<LoginReponseModel>(
        "/Authentication/LoginWithOtp",
        model
      );
      console.log(res);
      return res.data;
      // res.data.map((x) => this.mapToModel(x));
    },

    async createRegisterInstructor(model: RegisterModel): Promise<any> {
      const res = await axios.post<any>("/Teacher/PostFirstRecord", model);
      console.log(res);
      return res.data;
      // res.data.map((x) => this.mapToModel(x));
    },

    async checkRegisterInstructor(
      model: RegisterRecordCheckReqModel
    ): Promise<LoginReponseModel> {
      const res = await axios.post<LoginReponseModel>(
        "/Teacher/PostFirstRecordCheck",
        model
      );
      //const res = await axiosPrivate.post<LoginReponseModel>("/Teacher/PostFirstRecordCheck",model);
      console.log(res);
      return res.data;
      // res.data.map((x) => this.mapToModel(x));
    },

    async createRegisterStudent(model: RegisterModel): Promise<any> {
      const res = await axios.post<any>("/Student/PostFirstRecord", model);
      console.log(res);
      return res.data;
      // res.data.map((x) => this.mapToModel(x));
    },

    async checkRegisterStudent(
      model: RegisterRecordCheckReqModel
    ): Promise<LoginReponseModel> {
      const res = await axios.post<LoginReponseModel>(
        "/Student/PostFirstRecordCheck",
        model
      );
      console.log(res);
      return res.data;
      // res.data.map((x) => this.mapToModel(x));
    },
  };
};

export default RemoteAuthDataSource;
