import React from "react";
import { CreateCourseSectionProps } from "./CreateCourseSection";
import { CourseEpisodeType } from "../../../pages/course/create-course";

import EpisodeList from "./EpisodeList";
import { NoEpisode } from "./NoEpisode";

export interface EpisodePresentationProps
  extends Omit<CreateCourseSectionProps, "episodesDispatch"> {
  episodesDispatch: React.Dispatch<
    React.SetStateAction<CourseEpisodeType[] | []>
  >;
  setIsCreateNewSectionActive: React.Dispatch<React.SetStateAction<boolean>>;
}

// Main Presentation

export const EpisodePresentation: React.FunctionComponent<
  EpisodePresentationProps
> = ({ episodesState, setIsCreateNewSectionActive, episodesDispatch }) => {
  const episodeList = episodesState;

  //   show if there is episode or else show no episode component
  return (
    <>
      {episodeList.some((episode) => episode !== undefined) ? (
        <EpisodeList
          episodesDispatch={episodesDispatch}
          episodesState={episodesState}
          setIsCreateNewSectionActive={setIsCreateNewSectionActive}
        />
      ) : (
        <NoEpisode setIsCreateNewSectionActive={setIsCreateNewSectionActive} />
      )}
    </>
  );
};
