import React, { useState } from "react";

export type TDynamicFeatures = {
  icon: string;
  header: string;
  content: string;
  imgLink: string;
};

interface Props {
  contentArray: TDynamicFeatures[];
}

const DynamicFeatures: React.FunctionComponent<Props> = ({ contentArray }) => {
  const [selectedItemId, setSelectedItemId] = useState(0);

  // Img finder depens on selected context
  const selectedItem = contentArray.find(
    (item, index) => index === selectedItemId
  );
  const imgLink = selectedItem?.imgLink;

  return (
    <>
      {/* List comes here */}
      <div className="col-12 col-sm-6 px-sm-0 dynamic-features-parent pr-sm-5 text-center text-sm-left">
        {contentArray.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                setSelectedItemId(index);
              }}
              className={`pl-sm-3 pt-4 ${
                index === selectedItemId
                  ? "dynamic-features-child-active"
                  : "dynamic-features-child"
              }`}
            >
              <img
                style={{ borderRadius: "10px" }}
                src={item.icon}
                className="border p-2"
                alt="icon"
              />
              <h1 className="mt-3 fw-600 font-sm">{item.header}</h1>
              <p>{item.content}</p>
            </div>
          );
        })}
      </div>

      {/* Img Comes Here */}
      <div
        style={{ borderRadius: "10px" }}
        className="col-12 col-sm-6 bg-light mt-3 mt-sm-0 d-none d-sm-block"
      >
        <img className="w-100 h-100 " src={imgLink} alt="content"></img>
      </div>
    </>
  );
};

export default DynamicFeatures;
