import React, { useState } from "react";
import { MediaStateType } from "../../pages/course/create-course";

enum FileTypeEnum {
  IMAGE = "image",
  VIDEO = "video",
}

interface FileInputProps {
  inputID: string; // Give unique id to each file input in order to distinguish each other when they are in same html page
  content: string;
  fileType: "image" | "video";
  mediaState: MediaStateType;
  mediaDispatch: React.Dispatch<React.SetStateAction<MediaStateType>>;
  stateAddress: keyof MediaStateType;
}

const FileInput: React.FunctionComponent<FileInputProps> = ({
  inputID,
  content,
  fileType,
  mediaState,
  mediaDispatch,
  stateAddress,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  //   bring the state from parent
  const uploadedFile = mediaState[stateAddress];

  let filemb = "";
  let filetype = "";

  if (uploadedFile) {
    // rounded conver to mb
    filemb = (uploadedFile.size / (1024 * 1024)).toFixed(2);
    filetype = uploadedFile.type.split("/")[1];

    // find the type
  } else if (true) {
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      console.log(file);
      setSelectedFile(file);

      //   update parent state
      mediaDispatch((previous) => {
        return { ...previous, [stateAddress]: file };
      });
    }
  };

  const infoContent = (
    <div className="classic-transition d-flex flex-column align-items-start p-2">
      <label className="m-0">
        Dosya tipi: <span>{filetype}</span>
      </label>
      <label className="m-0">
        Dosya büyüklüğü: <span>{filemb}</span> MB
      </label>
    </div>
  );

  return (
    <>
      <div
        style={{ borderRadius: "12px" }}
        className={`text-center h-100
      ${!selectedFile ? "form-input" : "focused-form-input"}
      `}
      >
        <img
          className="mx-auto mt-3"
          style={{ display: "block" }}
          src="/assets/images/figma-icon/icon-file-upload.svg"
          alt="file-upload"
        ></img>
        <p>
          {content}{" "}
          <strong
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
            className={`file-input fw-600 ${
              isHover ? "text-primary-800" : "text-primary-600"
            }`}
          >
            <input
              id={inputID}
              type="file"
              accept={`${fileType}/*`}
              onChange={handleFileChange}
            />
            <label style={{ cursor: "pointer" }} htmlFor={inputID}>
              yükleyin
            </label>
          </strong>
        </p>

        <img
          className="mx-auto mt-3"
          style={{ display: "block" }}
          src="/assets/images/figma-icon/icon-file-upload.svg"
          alt="file-upload"
        ></img>

        {/* Uploaded File Information  */}
        {uploadedFile && infoContent}
      </div>
    </>
  );
};

interface CreateCourseMediaProps {
  mediaState: MediaStateType;
  mediaDispatch: React.Dispatch<React.SetStateAction<MediaStateType>>;
}

const CreateCourseMedia: React.FunctionComponent<CreateCourseMediaProps> = ({
  mediaState,
  mediaDispatch,
}) => {
  return (
    <>
      <div className="row classic-transition">
        {/* Cover Picture */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Kapak Fotoğrafı</label>
              <p className="w-75">
                Kapak fotoğrafı, kursunuzun öğrenciler tarafından tanınmasını
                sağlayacak görselliği oluşturan temel unsurlardan biridir. İyi
                tasarlanmış bir kapak fotoğrafı, öğrencilerin daha çok dikkatini
                çekecektir.
              </p>
            </div>
            <div className="col-7">
              <FileInput
                inputID="fileinput-1"
                fileType={FileTypeEnum.IMAGE}
                content="Özel kapak fotoğrafı"
                stateAddress="coverpicture"
                mediaState={mediaState}
                mediaDispatch={mediaDispatch}
              />
            </div>
          </div>
          <hr />
        </div>
        {/* Promo Video */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Tanıtım Videosu (isteğe bağlı)</label>
              <p className="w-75">
                Tanıtım videonuz, öğrencilerin içeriğinizde neler
                öğreneceklerini ön izlemesini sağlayan hızlı ve ilgi çekici bir
                yöntemdir. İyi hazırlanmış bir tanıtım videonuz varsa
                içeriğinize katılmayı düşünen öğrencilerin kaydolması daha
                olasıdır. Videonuz 3 dakikadan kısa olmalıdır.
              </p>
            </div>
            <div className="col-7">
              <FileInput
                inputID="fileinput-2"
                fileType={FileTypeEnum.VIDEO}
                content="Tanıtım videosu"
                stateAddress="promovideo"
                mediaState={mediaState}
                mediaDispatch={mediaDispatch}
              />
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default CreateCourseMedia;
