import React, { useState, useEffect, useReducer } from "react";
// Types
import { TUserType } from "../../../core/types";
// Components
import OnBoardingForm from "./OnBoardingForm";
import CoverColor from "../profile/CoverColor";
import UserInfo from "../profile/UserInfo";
import useAuth from "../../../hooks/Auth/useAuth";

const userInitialStates = {
  name: "",
  surname: "",
  usertype: "",
  school: "",
  expertness: [],
  about: ``,
  socialLink: {
    x: "",
    linkedin: "",
    website: "",
  },
  location: {
    city: "",
    country: "",
  },
  experience: {
    month: 0,
    year: 0,
  },
  email: "",
  imgSource: "",
};

type ProgressValue = 0 | 25 | 50 | 100;

interface Props {
  children?: React.ReactNode;
  // onClick: (arg: number) => void;
}

// Dynamic Labeling are produced by accountType
const getDynamicLabel = (accountType: string): string => {
  switch (accountType) {
    case "Student":
      return "Öğrenci";
    case "Teacher":
      return "Öğretmen";
    default:
      return "";
  }
};

// usereducer leverage the state
// const userInitialState = {
//   name: "",
//   title: "Product Designer",
//   company: "Simpra",
//   expertness: [
//     "UX,UI Design",
//     "Brand Strategy",
//     "Product Design",
//     "Product Design",
//     "Product Design",
//     "Product Design",
//     "Product Design",
//   ],
//   about: `I'm a Product Designer based in Melbourne, Australia.
//    I specialise in UX/UI design, brand strategy, and Webflow development. I'm always striving to grow and I'm passionate about helping startups grow, improve their customer experience, and to raise venture capital through good design.

//     My work has been featured on Typewolf, Mindsparkle Magazine, Webflow, Fonts In Use, CSS Winner, httpster, Siteinspire, and Best Website Gallery.`,
//   socialLink: {
//     x: "",
//     linkedin: "",
//     website: "rileyonoore.com",
//   },
//   location: {
//     city: "Melbourne",
//     country: "Australia",
//   },
//   experience: {
//     month: 1,
//     year: 13,
//   },
//   email: "hello@rileyomoore.com",
//   imgSource: "/assets/images/figma-icon/avatar.svg",
// };

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "UPDATE_NAME":
      return { ...state, name: action.payload.name };
    case "UPDATE_SURNAME":
      return { ...state, surname: action.payload.surname };
    case "UPDATE_SCHOOL":
      return { ...state, school: action.payload.school };
    case "UPDATE_ABOUT":
      return { ...state, about: action.payload.about };
    default:
      return state;
  }
};

const OnBoardingMain: React.FunctionComponent<Props> = () => {
  // Depends on usertype only couple label and form post method will be change
  const auth  = useAuth();
  const [accountType, setAccountType] = useState<TUserType>("Student");
  // update user type
  const roles=auth?.auth?.roles!=null?auth?.auth!.roles[0]:"";
  userInitialStates.usertype = roles;

  // For Progress Bar
  const [progress, setProgress] = useState<ProgressValue>(0);
  // Stepper For Form Pagination
  const [currentStep, setCurrentStep] = useState<number>(1);

  // Proggress Bar Trigger
  useEffect(() => {
    // Handlers
    const ProgressBarUpdaterHandler = (): void => {
      // Switch case
      switch (currentStep) {
        case 0:
          setProgress(0);
          break;
        case 1:
          setProgress(25);
          break;
        case 2:
          setProgress(50);
          break;
        case 3:
          setProgress(100);
          break;
      }
    };
    // Invoke
    ProgressBarUpdaterHandler();
  }, [currentStep]);

  // Form Reducer
  const [userPreviewStates, dispatch] = useReducer(reducer, userInitialStates);

  return (
    <>
      <div className=" onboar-wrapper vh-100 classic-transition">
        {/* Progres Bar Area 
        Page Header */}
        <div className="">
          <div className="d-flex pl-sm-5 mt-4 justify-content-center justify-content-sm-start ">
            <div className=" ">
              <img src="/assets/images/figma-icon/Logo.svg" alt="Logo"></img>
            </div>
          </div>
          <div
            style={{ height: "5px" }}
            className="progress mt-3"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            <div
              className="progress-bar bg-primary-600"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>

        {/* Main */}
        <div className="container-fluid px-0">
          <div className="row">
            {/* FormSide  */}
            <div className="col-12 col-md-6 d-flex flex-column mt-5 ">
              <div className="col-10 col-lg-9 mx-auto">
                {/* Step Info */}
                <div className="step-info fw-500 font-xsss">
                  {/* Step deeper context */}
                  <div className="d-flex">
                    <div
                      style={{ borderRadius: "16px", maxWidth: "content" }}
                      className="d-flex border p-1 pr-2 bg-light"
                    >
                      <div
                        style={{ borderRadius: "16px" }}
                        className="border bg-white"
                      >
                        <p className="px-2 text-center">
                          Adım <span>{currentStep}/3</span>
                        </p>
                      </div>
                      <p className="ml-2 align-self-center text-center  ">
                        {getDynamicLabel(roles)} olarak kayıt oluyorsunuz
                      </p>
                    </div>
                  </div>

                  <div className="mt-4">
                    <h2 className="fw-600 fs-30">Hoş Geldiniz!</h2>
                    <p className="text-neutral-600">
                      Profilinizi ayarlama aşamasındasınız
                    </p>
                  </div>
                </div>

                {/* Form */}
                <div className="form mt-4">
                  <OnBoardingForm
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    dispatch={dispatch}
                  ></OnBoardingForm>
                </div>
              </div>
            </div>

            {/* Dynamic Content Side */}
            <div className="d-none d-md-block col-12 col-md-6 bg-light vh-100">
              {/* Preview Profile Wrapper  */}
              <div
                style={{
                  borderRadius: "16px",
                  marginTop: "10%",
                  marginLeft: "5%",
                  overflow: "hidden",
                }}
                className="preview-wrapper bg-white px-3 shadow-md"
              >
                <div className="row px-0">
                  <div className="col-12 px-0 ">
                    <CoverColor />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 my-5">
                    <UserInfo shadowInitial={false} user={userPreviewStates} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnBoardingMain;
