import React, { useState } from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
  sx?: React.CSSProperties;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}

const Button: React.FunctionComponent<Props> = ({
  children,
  className,
  onClick,
  onMouseOver,
  onMouseLeave,
  sx,
}) => {
  return (
    <button
      style={{ borderRadius: "10px", ...sx }}
      className={`${className}`}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
};

interface ButtonExtendedProps {
  onPopUp?: boolean;
  onPopUpContent?: React.ReactNode;
  isBorderAllowed?: boolean;
}

export const PrimaryButton: React.FC<Props & ButtonExtendedProps> = (props) => {
  const [isHover, setIsHover] = useState(false);
  const { onPopUp, onPopUpContent, sx, isBorderAllowed } = props;

  return (
    <div className="borderless-button position-relative">
      {/* Pop-up */}
      <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: "-150%",
          borderRadius: "10px",
        }}
        className={`triangle bg-black text-white p-2 fw-700 classic-transition ${
          isHover ? "d-inline" : "d-none"
        }`}
      >
        <p style={{ whiteSpace: "nowrap" }} className="text-center">
          {onPopUpContent}
        </p>
      </div>
      <Button
        {...props}
        sx={sx}
        className={`text-white fw-600 p-1 ${
          !isBorderAllowed ? "border-0" : ""
        } ${props.className}  ${isHover ? "bg-primary-700" : "bg-primary-600"}`}
        onMouseOver={() => {
          if (onPopUp) setIsHover(true);
        }}
        onMouseLeave={() => {
          if (onPopUp) setIsHover(false);
        }}
      />
    </div>
  );
};

export const BorderlessButton: React.FunctionComponent<
  Props & ButtonExtendedProps
> = (props) => {
  const [isHover, setIsHover] = useState(false);

  // Distructure on popup for conditional rendering
  const { onPopUp, onPopUpContent } = props;

  return (
    <div className="borderless-button position-relative">
      {/* Pop-up   onPopUp property's trigger entire system */}
      <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: "-150%",
          borderRadius: "10px",
        }}
        className={`triangle bg-black text-white p-2 fw-700 classic-transition ${
          isHover ? "d-inline" : "d-none"
        }`}
      >
        <p style={{ whiteSpace: "nowrap" }} className="text-center">
          {onPopUpContent}
        </p>
      </div>
      <Button
        {...props}
        sx={{ border: "none" }}
        className={`fw-600 p-1 ${props.className}  ${
          isHover ? "bg-neutral-100" : "bg-transparent"
        }`}
        onMouseOver={() => {
          if (onPopUp) setIsHover(true);
        }}
        onMouseLeave={() => {
          if (onPopUp) setIsHover(false);
        }}
      />
    </div>
  );
};

interface IconButtonProps extends Omit<Props, "children">, ButtonExtendedProps {
  src: string;
  width: string;
  height: string;
}

export const IconButton: React.FunctionComponent<IconButtonProps> = (props) => {
  const [isHover, setIsHover] = useState(false);
  // Distructure on popup for conditional rendering
  const { onPopUp, onPopUpContent, src, width, height, className } = props;

  return (
    <div className="borderless-button position-relative">
      {/* Customized Pop-up */}
      <div
        style={{
          position: "absolute",
          top: "90%",
          transform: "translateX(-100%)",
          borderRadius: "10px",
        }}
        className={`border bg-white p-2 fw-300 classic-transition ${
          isHover ? "d-inline" : "d-none"
        }`}
      >
        {/* onpopup content can be string and also a react.node */}
        {onPopUpContent}
      </div>
      <button
        style={{ borderRadius: "8px" }}
        className={`${className} ${
          isHover ? "bg-neutral-100" : "bg-transparent"
        } `}
        onClick={() => {
          if (onPopUp) setIsHover((previous) => !previous);
        }}
        onBlur={() => {
          // make the button clickable inside of popupcontent
          setTimeout(() => {
            if (onPopUp) setIsHover(false);
          }, 100);
        }}
      >
        <div
          className="overflow-hidden d-flex"
          style={{ width: width, height: height }}
        >
          <img
            className="w-100 h-100 object-fit-cover"
            src={src}
            alt="iconbutton"
          ></img>
        </div>
      </button>
    </div>
  );
};

export default Button;
