import React from "react";

import { PrimaryButton, BorderlessButton } from "../Button";

const PasswordResetForm: React.FunctionComponent = (props) => {
  return (
    <>
      <div className="row request-wrapper classic-transition">
        <div className="col-12 col-md-6 col-lg-4 mx-auto text-center">
          {/* Icon */}
          <div className="d-flex">
            <div className="col-3 col-md-2  mx-auto">
              <img
                style={{ borderRadius: "10px" }}
                className="border p-2 w-100"
                src="/assets/images/figma-icon/icon-password.svg"
                alt="key"
              ></img>
            </div>
          </div>
          {/* Headers */}
          <div className="my-4">
            <h2 className="fw-600 fs-30">Yeni bir parola ayarlayın</h2>
            <p>Yeni parolanız öncekinden farklı olmalı.</p>
          </div>

          {/* Inputs */}
          <div className="col-12 col-md-8 mx-auto">
            <label className="m-0 mb-1 d-block text-left font-xsss fw-600  ">
              Yeni Şifre
            </label>
            <div
              style={{ borderRadius: "10px" }}
              className="d-flex border p-2 shadow-sm"
            >
              <input
                id="phone-input"
                type="password"
                placeholder="Şifre"
                className="border-0 ml-2 bg-transparent w-100 "
              ></input>
            </div>
          </div>
          <div className="col-12 col-md-8 mx-auto mt-3">
            <label className="m-0 mb-1 d-block text-left font-xsss fw-600">
              Şifreyi Tekrarlayınız
            </label>
            <div
              style={{ borderRadius: "10px" }}
              className="d-flex border p-2 shadow-sm"
            >
              <input
                id="phone-input"
                type="password"
                placeholder="*****"
                className="border-0 ml-2 bg-transparent w-100 "
              ></input>
            </div>
          </div>
          {/* Buttons */}
          <div className="col-12 col-md-8 mx-auto d-flex flex-column mt-4">
            <PrimaryButton> Şifreyi yenile </PrimaryButton>
            <BorderlessButton className="mt-3 pr-3 text-neutral-600">
              ← Lobiye geri dön
            </BorderlessButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetForm;
