import { ProfileStudentClassDetailUpdateReq } from "../../../domain/entities/profile/profile_student_class_detail_update_req";

export class ProfileStudentClassDetailUpdateReqModel implements ProfileStudentClassDetailUpdateReq {
    classLevel: number;
    classDepartment: string;
    schoolName: string;


     constructor(classLevel:number,classDepartment:string,schoolName:string)
     {
      this.classDepartment=classDepartment;
      this.classLevel=classLevel;
      this.schoolName=schoolName;
     }
  

  }