import React from "react";

import { PrimaryButton, BorderlessButton } from "../Button";

const PasswordResetNav: React.FunctionComponent = (props) => {
  return (
    <>
      <div className="row request-wrapper classic-transition">
        <div className="col-12 col-md-6 col-lg-4 mx-auto text-center">
          {/* Icon */}
          <div className="d-flex">
            <div className="col-3 col-md-2  mx-auto">
              <img
                style={{ borderRadius: "10px" }}
                className="border p-2 w-100"
                src="/assets/images/figma-icon/icon-letter.svg"
                alt="key"
              ></img>
            </div>
          </div>
          {/* Headers */}
          <div className="my-4">
            <h2 className="fw-600 fs-30">Mailinizi Kontrol Edin?</h2>
            <p>Email adresinize şifre yenileme linki gönderdik</p>
          </div>

          {/* Buttons */}
          <div className="col-12 col-md-9 mx-auto d-flex flex-column mt-4">
            <PrimaryButton> Şifreyi yenile </PrimaryButton>
            <div className="font-xsss mt-3">
              <p>
                Herhangi bir mail almadınız mı?{" "}
                <span className="text-primary-600 fw-600">Yeniden gönder?</span>
              </p>
            </div>
            <BorderlessButton className="mt-3 pr-3 text-neutral-600">
              ← Lobiye geri dön
            </BorderlessButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetNav;
