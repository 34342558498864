import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useLoading from "../../../../hooks/userLoading";
import { LoginReponseModel,LoginReponseModel2 } from "../../../../infrastructure/models/auth/login_reponse_model";
import { LoginReqModel, LoginWithOtpReqModel } from "../../../../infrastructure/models/auth/login_req_model";
import { RegisterModel, RegisterRecordCheckReqModel } from "../../../../infrastructure/models/auth/register_model";
import { AuthRepositoryImpl } from "../../../../infrastructure/repositories/auth_repository_impl";

export default function LoginViewModel() {
   
    const [callLoginState, callLogin] = useState<LoginReponseModel2>({isError:false,message:""});
    const [callLoginOtpState, callLoginWithOtp] = useState<LoginReponseModel>();

    const auth = new AuthRepositoryImpl();
    const { setLoading } = useLoading();
    const navigate = useNavigate();
    //const { setLoading } = useLoading();

    async function login(model:LoginReqModel) {
    
      setLoading({isLoading: true,isError: false,message:""} );
        return await auth.login(model).then((res)=>{
          setLoading({isLoading: false,isError: res.isError,message:res.message });
          !res.isError ? navigate("/onboarding") : navigate("/Login");
          return res;
        });
       
    }

    async function loginWithOtp(model:LoginWithOtpReqModel) {
        return await auth.loginOtp(model);
      
      }

    
  
    return {
        login,
        loginWithOtp,
        callLoginState
    };
  }