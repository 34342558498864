import React, { useState } from "react";

// TS Types
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
// Types
import { TUserType } from "../../../core/types";
import useAuth from "../../../hooks/Auth/useAuth";

import { FormValues } from "./OnBoardingForm";

interface Props {
  register: UseFormRegister<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  watch: UseFormWatch<FormValues>;
  errors: Record<string, any>;
  currentStep: number;
}

const Step1: React.FunctionComponent<Props> = ({
  register,
  setValue,
  watch,
  errors,
  currentStep,
}) => {
  return (
    <div className="step-1">
      <div className="d-flex flex-column ">
        <label className="m-0">Avatar Will be modified</label>
        <input type="file"></input>
      </div>

      <div className="d-flex flex-column mt-3">
        <label className="fw-500 m-0 font-xsss">Ad</label>
        <div className="mb-4">
          <div style={{ borderRadius: "10px" }} className="border p-1">
            <input
              {...register("name_", {
                required: "İsim boş geçilemez",
                pattern: {
                  value: /^[A-Za-z\s'-]+$/,
                  message: "İsim geçersiz karekter içeriyor",
                },
              })}
              onChange={(e) => {
                setValue("name_", e.target.value);
              }}
              type="text"
              placeholder="Ad"
              className="border-0 px-2 w-100"
            />
          </div>
          <p
            className={`text-danger font-xsss m-0 h-0 ${
              errors.name_?.message ? "classic-transition" : ""
            }`}
          >
            {errors.name_?.message}
          </p>
        </div>
      </div>
      <div className="d-flex flex-column">
        <label className="fw-500 m-0 font-xsss">Soyad</label>
        <div className="mb-4">
          <div style={{ borderRadius: "10px" }} className="border p-1">
            <input
              {...register("surname", {
                required: "Soyad boş geçilemez",
                pattern: {
                  value: /^[A-Za-z\s'-]+$/,
                  message: "Soyad geçersiz karekter içeriyor",
                },
              })}
              onChange={(e) => {
                setValue("surname", e.target.value);
              }}
              type="text"
              placeholder="Soyad"
              className="border-0 px-2 w-100"
            />
          </div>
          <p
            className={`text-danger font-xsss m-0 h-0 ${
              errors.surname?.message ? "classic-transition" : ""
            }`}
          >
            {errors.surname?.message}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column">
        <label className="fw-500 m-0 font-xsss">Vatandaşlık Numarası</label>
        <div className="mb-4">
          <div style={{ borderRadius: "10px" }} className="border p-1">
            <input
              {...register("tckn", {
                required: "TCKN boş geçilemez",
                pattern: {
                  value: /^[0-9\b]+$/,
                  message: "TCKN geçersiz karekter içeriyor",
                },
                minLength:{
                  value:11,
                  message:"Geçersiz"
                },
                maxLength:{
                  value:11,
                  message:"Geçersiz"
                }
              })}
              onChange={(e) => {
                setValue("tckn", Number(e.target.value));
              }}
              type="number"
              placeholder="TCKN"
              className="border-0 px-2 w-100"
            />
          </div>
          <p
            className={`text-danger font-xsss m-0 h-0 ${
              errors.tckn?.message ? "classic-transition" : ""
            }`}
          >
            {errors.tckn?.message}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column">
        <label className="fw-500 m-0 font-xsss">Doğum Tarihi</label>
        <div className="mb-4">
          <div style={{ borderRadius: "10px" }} className="border p-1">
            <input
              {...register("birhtDay", {
                required: "Doğum Günü boş geçilemez",
                // pattern: {
                //   value: /^[0-9\b]+$/,
                //   message: "TCKN geçersiz karekter içeriyor",
                // },
                // minLength:{
                //   value:11,
                //   message:"Geçersiz"
                // },
                // maxLength:{
                //   value:11,
                //   message:"Geçersiz"
                // }
              })}
              onChange={(e) => {
                setValue("birhtDay",e.target.value);
              }}
              type="date"
              placeholder="TCKN"
              className="border-0 px-2 w-100"
            />
          </div>
          <p
            className={`text-danger font-xsss m-0 h-0 ${
              errors.birhtDay?.message ? "classic-transition" : ""
            }`}
          >
            {errors.birhtDay?.message}
          </p>
        </div>
      </div>
      {/* <div className="d-flex flex-column">
        <label className="fw-500 m-0 font-xsss">Email</label>
        <div className="mb-4">
          <div style={{ borderRadius: "10px" }} className="border p-1 ">
            <input
              {...register("email", {
                required: "Email gerekli",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Email uygun formatta değil",
                },
              })}
              onChange={(e) => {
                setValue("email", e.target.value);
              }}
              type="email"
              placeholder="E-posta Adresi"
              className="border-0 px-2 w-100"
            />
          </div>
          <p
            className={`text-danger font-xsss m-0  h-0 p-0 ${
              errors.email?.message ? "classic-transition" : ""
            }`}
          >
            {errors.email?.message}
          </p>
        </div>
      </div> */}
      <div className="d-flex flex-column">
        <label className="fw-500 m-0 font-xsss">Ülke</label>
        <div className="mb-4">
          <div style={{ borderRadius: "10px" }} className="border p-1">
            <select
              {...register("country", { required: "Ülke seçimi gereklidir" })}
              style={{ width: "100%", border: "none" }}
              className=""
              defaultValue=""
            >
              <option value="" disabled>
                Ülke seçiniz
              </option>
              <option value="2">Türkiye</option>
              <option value="3">Almanya</option>
              <option value="4">Hollanda</option>
            </select>
          </div>
          <p
            className={`text-danger font-xsss m-0  h-0 p-0 ${
              errors.country?.message ? "classic-transition" : ""
            }`}
          >
            {errors.country?.message}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column">
        <label className="fw-500 m-0 font-xsss">Cinsiyet</label>
        <div className="mb-4">
          <div style={{ borderRadius: "10px" }} className="border p-1">
            <select
              {...register("gender", {
                required: "Cinsiyet seçimi gereklidir",
              })}
              style={{ width: "100%", border: "none" }}
              className=""
              defaultValue=""
            >
              <option value="" disabled>
                Cinsiyet Seçin
              </option>
              <option value="0">Kız</option>
              <option value="1">Erkek</option>
            </select>
          </div>
          <p
            className={`text-danger font-xsss m-0  h-0 p-0 ${
              errors.gender?.message ? "classic-transition" : ""
            }`}
          >
            {errors.gender?.message}
          </p>
        </div>
      </div>
      {/* <div className="d-flex flex-column mt-3">
        <label className="fw-500 m-0 font-xsss">Şifre</label>
        <div style={{ borderRadius: "10px" }} className="border p-1">
          <input
            name="sifre"
            placeholder="Şifre oluşturun"
            className="border-0 px-2"
          ></input>
        </div>
      </div> */}
    </div>
  );
};

const Step2: React.FunctionComponent<Props> = ({
  register,
  setValue,
  watch,
  errors,
}) => {
  const [accountType, setAccountType] = useState<TUserType>("Teacher");
  const auth  = useAuth();

  // Experience Steps

  const years = Array.from({ length: 31 }, (_, index) => index); // 0 to 30
  const months = Array.from({ length: 12 }, (_, index) => index + 1); // 1 to 12

  return (
    <div className="step-2">
      <div className="d-flex flex-column mt-3">
        <label className="fw-500 m-0 font-xsss">Okul</label>
        <div className="mb-4">
          <div style={{ borderRadius: "10px" }} className="border p-1">
            <input
              {...register("school", {
                required: "Okul boş geçilemez",
                pattern: {
                  value: /^[A-Za-z\s'-]+$/,
                  message: "Okul geçersiz karekter içeriyor",
                },
              })}
              onChange={(e) => {
                setValue("school", e.target.value);
              }}
              type="text"
              placeholder="Okul ismi"
              className="border-0 px-2 w-100"
            />
          </div>
          <p
            className={`text-danger font-xsss m-0 h-0 ${
              errors.school?.message ? "classic-transition" : ""
            }`}
          >
            {errors.school?.message}
          </p>
        </div>
      </div>

      {/* Conditional Rendering Depends on usertype */}

      {auth.auth?.roles[0] === "Student" ? (
        <>
          {/* Class */}
          <div className="d-flex flex-column">
            <label className="fw-500 m-0 font-xsss">Sınıf</label>
            <div className="mb-4">
              <div style={{ borderRadius: "10px" }} className="border p-1 ">
                <input
                  {...register("class", {
                    required: "Sınıf Gerekli",
                  })}
                  onChange={(e) => {
                    setValue("class", e.target.value);
                  }}
                  type="text"
                  placeholder="Sınıf seçin"
                  className="border-0 px-2 w-100"
                />
              </div>
              <p
                className={`text-danger font-xsss m-0  h-0 p-0 ${
                  errors.class?.message ? "classic-transition" : ""
                }`}
              >
                {errors.class?.message}
              </p>
            </div>
          </div>

          {/* Field */}

          <div className="d-flex flex-column">
            <label className="fw-500 m-0 font-xsss">Alan</label>
            <div className="mb-4">
              <div style={{ borderRadius: "10px" }} className="border p-1 ">
                <input
                  {...register("field", {
                    required:
                      "Sizi daha iyi anlayabilmemiz için alan bilgisi gereklidir.",
                  })}
                  onChange={(e) => {
                    setValue("field", e.target.value);
                  }}
                  type="text"
                  placeholder="Alan Bilgisi"
                  className="border-0 px-2 w-100"
                />
              </div>
              <p
                className={`text-danger font-xsss m-0  h-0 p-0 ${
                  errors.field?.message ? "classic-transition" : ""
                }`}
              >
                {errors.field?.message}
              </p>
            </div>
          </div>
        </>
      ) : (
        // Special renderin for Teacher
        <>
          {/* title */}
          <div className="d-flex flex-column">
            <label className="fw-500 m-0 font-xsss">Unvan</label>
            <div className="mb-4">
              <div style={{ borderRadius: "10px" }} className="border p-1 ">
                <input
                  {...register("title", {
                    required: "Unvan Gerekli",
                  })}
                  onChange={(e) => {
                    setValue("title", e.target.value);
                  }}
                  type="text"
                  placeholder="Unvan seçin"
                  className="border-0 px-2 w-100"
                />
              </div>
              <p
                className={`text-danger font-xsss m-0  h-0 p-0 ${
                  errors.title?.message ? "classic-transition" : ""
                }`}
              >
                {errors.title?.message}
              </p>
            </div>
          </div>

          {/* Experience */}

          <div className="d-flex flex-column">
            <label className="fw-500 m-0 font-xsss">Profesyonel Deneyim</label>
            <div className="row">
              {/* Year */}
              <div className="col-6">
                <label className="fw-500 m-0 font-xssss">Yıl</label>
                <div style={{ borderRadius: "10px" }} className="border p-1">
                  <select
                    {...register("experience.year", {
                      required: "Year is required",
                    })}
                    className="border-0 px-2 w-100"
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <p
                  className={`text-danger font-xsss m-0 h-0 p-0 ${
                    errors.experience?.year?.message ? "classic-transition" : ""
                  }`}
                >
                  {errors.experience?.year?.message}
                </p>
              </div>
              {/* Months */}
              <div className="col-6">
                <label className="fw-500 m-0 font-xssss">Ay</label>
                <div style={{ borderRadius: "10px" }} className="border p-1">
                  <select
                    {...register("experience.month", {
                      required: "Month is required",
                    })}
                    className="border-0 px-2 w-100"
                  >
                    {months.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                <p
                  className={`text-danger font-xsss m-0 h-0 p-0 ${
                    errors.experience?.month?.message
                      ? "classic-transition"
                      : ""
                  }`}
                >
                  {errors.experience?.month?.message}
                </p>
              </div>

              {/* // */}
            </div>
          </div>

          {/* About Me */}

          <div className="d-flex flex-column mt-3">
            <label className="fw-500 m-0 font-xsss">About Me</label>
            <div className="mb-4">
              <div style={{ borderRadius: "10px" }} className="border p-1">
                <textarea
                  {...register("about", {
                    required: "About me is required",
                    maxLength: {
                      value: 500,
                      message: "About me should not exceed 500 characters",
                    },
                  })}
                  onChange={(e) => {
                    setValue("about", e.target.value);
                  }}
                  placeholder="Tell us something about yourself..."
                  className="border-0 px-2 w-100"
                />
              </div>
              <p
                className={`text-danger font-xsss m-0 h-0 ${
                  errors.about?.message ? "classic-transition" : ""
                }`}
              >
                {errors.about?.message}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Step3: React.FunctionComponent<Props> = ({
  register,
  setValue,
  watch,
  errors,
}) => {
  const [accountType, setAccountType] = useState<TUserType>("Teacher");
  const auth  = useAuth();

  return (
    <div className="step-3">
      {auth.auth?.roles[0] === "Student" ? (
        // Target Field
        <div className="d-flex flex-column">
          <label className="fw-500 m-0 font-xsss">
            Geliştirmek istediğiniz ders ya da uzmanlık alanları nelerdir?
          </label>
          <div className="mb-4">
            <div style={{ borderRadius: "10px" }} className="border p-1 ">
              <input
                onChange={(e) => {
                  // setValue("field", e.target.value);
                }}
                type="text"
                placeholder=""
                className="border-0 px-2 w-100"
              />
            </div>
            {/* <p
    className={`text-danger font-xsss m-0  h-0 p-0 ${
      errors.field?.message ? "classic-transition" : ""
    }`}
  >
    {errors.field?.message}
  </p> */}
          </div>
        </div>
      ) : null}

      <div className="d-flex flex-column">
        <label className="fw-500 m-0 font-xsss">
          Hobi ya da ilgi alanlarınız nelerdir?
        </label>
        <div className="mb-4">
          <div style={{ borderRadius: "10px" }} className="border p-1 ">
            <input
              onChange={(e) => {
                // setValue("field", e.target.value);
              }}
              type="text"
              // placeholder="Alan Bilgisi"
              className="border-0 px-2 w-100"
            />
          </div>
          {/* <p
            className={`text-danger font-xsss m-0  h-0 p-0 ${
              errors.field?.message ? "classic-transition" : ""
            }`}
          >
            {errors.field?.message}
          </p> */}
        </div>
      </div>
    </div>
  );
};

const OnBoardingFormSteps: React.FunctionComponent<Props> = ({
  register,
  setValue,
  watch,
  errors,
  currentStep,
}) => {
  // Components
  const steps = [
    <Step1
      register={register}
      setValue={setValue}
      watch={watch}
      errors={errors}
      currentStep={currentStep}
    />,
    <Step2
      register={register}
      setValue={setValue}
      watch={watch}
      errors={errors}
      currentStep={currentStep}
    />,
    <Step3
      register={register}
      setValue={setValue}
      watch={watch}
      errors={errors}
      currentStep={currentStep}
    />,
  ];

  return (
    <>
      {steps.map((step, index) =>
        // Render only the step corresponding to the current step
        index + 1 === currentStep
          ? React.cloneElement(step, { key: index })
          : null
      )}
    </>
  );
};

export default OnBoardingFormSteps;
