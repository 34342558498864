import React from "react";
import IPage from "../../../../core/interfaces/page";

import OnBoardingMain from "../../../components/onboarding/OnBoardingMain";

const OnBoardingPage: React.FunctionComponent<IPage> = (props) => {
  return (
    <>
      <OnBoardingMain></OnBoardingMain>
    </>
  );
};

export default OnBoardingPage;
