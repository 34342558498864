import React from "react";

import { PrimaryButton } from "../Button";

const PasswordResetSuccess: React.FunctionComponent = (props) => {
  return (
    <>
      <div className="row request-wrapper classic-transition">
        <div className="col-12 col-md-6 mx-auto text-center">
          {/* Icon */}
          <div className="d-flex">
            <div className="col-3 col-md-2  mx-auto">
              <img
                style={{ borderRadius: "10px" }}
                className="border p-2 w-100"
                src="/assets/images/figma-icon/icon-check.svg"
                alt="key"
              ></img>
            </div>
          </div>
          {/* Headers */}
          <div className="my-4">
            <h2 className="fw-600 fs-30">Şifreniz yenilendi</h2>
            <p>
              Şifreniz başarılı bir şekilde yenilendi.
              <br />
              Giriş yapmak için aşağıdaki butonu tıklayın.
            </p>
          </div>

          {/* Buttons */}
          <div className="col-12 col-md-8 mx-auto d-flex flex-column mt-4">
            <PrimaryButton> Giriş Yap </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetSuccess;
