import React, { useContext, useEffect } from "react";

import IStep from "../../../core/interfaces/stepper";

import UserType from "./UserType";
import SignUp from "./SignUp";
import UserVerification from "./UserVerification";
import { RegisterModel } from "../../../infrastructure/models/auth/register_model";
import RegisterViewModel from "../../pages/auth/view_model/registerViewModel";

// Context Architecture

import { RegisterContext } from "../../../context/useRegisterContext";

const RegisterMain = () => {
  // Intermediate Layer

  //   Connect two api here
  // Locate Verification Api her and navigate user depens on result.

  //   data(phonenumber,password) is ready in form state

  // otp can be used with state otp
  const handlerSubmit = RegisterViewModel();

  const {
    state: {
      stepper,
      // form: { userType, gsmNO, password },
      // OTP,
    },
    // StepDicrement,
    // StepIncrement,
  } = useContext(RegisterContext);

  //   Test-Check
  // useEffect(() => {
  //   console.log("stepper:", stepper);
  //   console.log("usertype:", userType);
  //   console.log("userdata:", gsmNO, password);
  //   console.log("OTP number:", OTP);
  //   if (gsmNO && password) {
  //     console.log("Ben gsmno ve password bilgisini gonderen api callum");
  //     console.log(gsmNO, password);

  //   }
  // }, [stepper, userType, OTP, gsmNO, password]);

  const steps: IStep[] = [
    { component: <UserType />, key: 1 },
    { component: <SignUp />, key: 2 },
    { component: <UserVerification />, key: 3 },
  ];

  return (
    <>
      <div className="register-wrapper">
        {steps.find((step) => step.key === stepper)?.component}
      </div>
    </>
  );
};

export default RegisterMain;
