import React from "react";

type WorkExperienceDetails = {
  position: string;
  company: string;
  stardate: string;
  enddate: string;
};

const WorkExperienceDetailsCard: React.FunctionComponent<
  WorkExperienceDetails
> = ({ position, company, stardate, enddate }) => {
  return (
    <>
      <div
        style={{
          //   minWidth: "400px",
          minWidth: "316px",
          minHeight: "140px",
          borderRadius: "10px",
        }}
        className={` p-3 d-flex flex-column border`}
      >
        <div className="d-flex">
          <img
            src={`/assets/images/figma-icon/work-experience.svg`}
            alt="thene-color"
          ></img>
          <div className="ml-2">
            <h2 className="fw-600 font-xss">{position}</h2>
            <span className="font-xsss"> {company}</span>
          </div>
        </div>
        <div className="mt-4 font-xsss">
          <p>
            {stardate} - {enddate}
          </p>
        </div>
      </div>
    </>
  );
};

interface Props {
  workExperienceDetailsCard: WorkExperienceDetails[];
}

const WorkExperience: React.FunctionComponent<Props> = ({
  workExperienceDetailsCard,
}) => {
  return (
    <>
      {workExperienceDetailsCard.map((props, index) => {
        return (
          <WorkExperienceDetailsCard
            key={index}
            {...props}
          ></WorkExperienceDetailsCard>
        );
      })}
    </>
  );
};

export default WorkExperience;
