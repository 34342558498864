import { ProfileInstructorUpdateReq } from "../../../domain/entities/profile/profile_instructor_update_req";

export class ProfileInstructorReqModel implements ProfileInstructorUpdateReq {
   
    name: string;
    surname: string;
    tckn: string;
    birthdate: Date;
    countryid: number;
    genderid: number;
    
    constructor(name:string,surname:string,tckn:string,brirthdate:Date,countryid:number,genderid:number)
    {
        this.name=name;
        this.surname=surname;
        this.tckn=tckn;
        this.birthdate=brirthdate;
        this.countryid=countryid;
        this.genderid=genderid;
    }
    
    
    
}