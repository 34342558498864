import React, { useEffect, useState } from "react";
import { RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import { BpRadio } from "./BpRadio";
import { EduikoSwitch } from "./EduikoSwitch";

// Material Data-Picker Kit
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
// Classic Data-picker
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CourseSettingsType } from "../../../pages/course/create-course";

interface InputProps {
  state: string | number;
  setState: React.Dispatch<React.SetStateAction<string | number>>;
  disable: boolean;
  placeholder: string;
}

const Input: React.FunctionComponent<InputProps> = ({
  state,
  setState,
  disable,
  placeholder,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div
      style={{ borderRadius: "8px" }}
      className={`d-flex  p-1 w-100 shadow-sm ${
        !isFocus ? "form-input " : "focused-form-input"
      }`}
    >
      <input
        onChange={(e) => {
          setState(Number(e.target.value));
        }}
        onFocus={(e) => {
          setIsFocus(true);
        }}
        onBlur={(e) => {
          setIsFocus(false);
        }}
        value={state}
        disabled={disable}
        type="number"
        placeholder={placeholder}
        className={`border-0 px-1 w-100`}
      />
    </div>
  );
};

interface CreateCourseSettingsProps {
  settingsDispacth: React.Dispatch<React.SetStateAction<CourseSettingsType>>;
}

const CreateCourseSettings: React.FunctionComponent<
  CreateCourseSettingsProps
> = ({ settingsDispacth }) => {
  //  Radio Group - Internal Logic
  const [radiovalue1, setRadioValue1] = useState<number>(-1);
  const [firstdisable, setFirstDisable] = useState<boolean>(true);
  const [radiovalue2, setRadioValue2] = useState<number>(-1);
  const [seconddisable, setSecondDisable] = useState<boolean>(true);

  // Switcher - Internal Logic
  const [switcher, setSwitcher] = useState<boolean>(false);

  // Inputs - External
  const [capacity, setCapacity] = useState<string | number>(20);

  // Closure data is selected by user
  const [closureDate, setClosureDate] = useState<Date | undefined>();

  // We need some handler function , understand the new registration condition and send it back to main state

  // Condition For First RadioGroup
  useEffect(() => {
    // User select manuel capacity adjustment
    if (radiovalue1 === 1) {
      setFirstDisable(false);
      setCapacity("");
    } else {
      setFirstDisable(true);
      setCapacity(20);
    }
  }, [radiovalue1]);

  // Condition For Second RadioGroup
  useEffect(() => {
    // User select manuel capacity adjustment
    if (radiovalue2 === 1) {
      setSecondDisable(false);
      // setCapacity("");
    } else {
      setSecondDisable(true);
      // setCapacity(25);
    }
  }, [radiovalue2]);

  // Switcher Conditions
  useEffect(() => {
    if (!switcher || seconddisable) {
      setClosureDate(undefined);
    }

    console.log(closureDate);
  }, [closureDate, seconddisable, switcher]);

  // To the Main State
  useEffect(() => {
    let closureType: "capacityBase" | "dateBase" = "capacityBase";
    if (closureDate === undefined) {
      closureType = "capacityBase";
    } else {
      closureType = "dateBase";
    }

    const newSettingValue: CourseSettingsType = {
      capacity: Number(capacity),
      closureDate: closureDate,
      closureType: closureType,
    };

    settingsDispacth((previous) => {
      return { ...previous, ...newSettingValue };
    });
  }, [capacity, closureDate, settingsDispacth]);

  return (
    <>
      <div className="row classic-transition">
        {/* Capacity */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Kapasite Ayarı</label>
              <p className="w-75">
                Kursunuz için kalite standartlarini belirleme ve her bir
                öğrenciye gerekli vakti ayırabilme adına arzu ettiğiniz bir
                kapasite limiti belirleyin. Eduiko standartları gereği bir ders
                için en fazla 20 öğrenci kabul edebilirsiniz.(Kursunuzu alan
                öğrenci sayısı kapasite sınırına ulaştığında yeni alımlar
                sistemce durdurulur.)
              </p>
            </div>
            <div className="col-7 ">
              <FormControl>
                <RadioGroup
                  aria-labelledby=""
                  defaultValue="-1"
                  name="radio-buttons-group"
                  value={radiovalue1}
                  onChange={(e) => {
                    setRadioValue1(Number(e.target.value));
                  }}
                >
                  <FormControlLabel
                    value="-1"
                    control={<BpRadio />}
                    label="Sistem Ayarlarını kullan"
                    sx={{
                      ".MuiTypography-root": {
                        fontFamily: "inherit",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: `${
                          radiovalue1 === -1 && "rgba(86, 86, 217, 1)"
                        }`,
                      },
                    }}
                  />
                  <FormControlLabel
                    value="1"
                    control={<BpRadio />}
                    label="Kapasiteyi Kendin Belirle"
                    sx={{
                      ".MuiTypography-root": {
                        fontFamily: "inherit",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: `${radiovalue1 === 1 && "rgba(86, 86, 217, 1)"}`,
                      },
                    }}
                  />
                  <Input
                    state={capacity}
                    setState={setCapacity}
                    disable={firstdisable}
                    placeholder="Kapasite Miktari"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <hr />
        </div>
        {/* Closure */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Yeni Kayıt Ayarı</label>
              <p className="w-75">
                Yeni kayit alımını belirli bir tarih veya kapasite şartına göre
                durdurabilirsiniz. Eğer bir seçim yapmaz iseniz , yeni alımlar
                kurs başlama günü sistemce kapanır.
              </p>
            </div>
            <div className="col-7 d-flex flex-column">
              <div style={{ gap: "5px" }} className="d-flex">
                <EduikoSwitch
                  checked={switcher}
                  onChange={() => {
                    setSwitcher((previous) => !previous);
                  }}
                />
                <div className="font-xsss">
                  <label className="fw-500 m-0 p-0">Yeni kayıtları kapat</label>
                  <p className="text-neutral-600">
                    Etkinlik kapasitesini veya etkinliğe son kayıt tarihini
                    belirleyin.
                  </p>
                </div>
              </div>
              {/* Dynamic Content */}
              {switcher ? (
                <div className="classic-transition flex-fill d-flex">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby=""
                      defaultValue="-1"
                      name="radio-buttons-group"
                      value={radiovalue2}
                      onChange={(e) => {
                        setRadioValue2(Number(e.target.value));
                      }}
                    >
                      <FormControlLabel
                        value="-1"
                        control={<BpRadio />}
                        label="Maksimum kapasiteye ulaşıldıktan sonra"
                        sx={{
                          ".MuiTypography-root": {
                            fontFamily: "inherit",
                            fontWeight: "500",
                            fontSize: "14px",
                            color: `${
                              radiovalue2 === -1 && "rgba(86, 86, 217, 1)"
                            }`,
                          },
                        }}
                      />
                      <FormControlLabel
                        value="1"
                        control={<BpRadio />}
                        label="Kayıtları belirli bir tarihte kapat"
                        sx={{
                          ".MuiTypography-root": {
                            fontFamily: "inherit",
                            fontWeight: "500",
                            fontSize: "14px",
                            color: `${
                              radiovalue2 === 1 && "rgba(86, 86, 217, 1)"
                            }`,
                          },
                        }}
                      />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disabled={seconddisable}
                          orientation="portrait"
                          // sx={cssSetting.DatePicker}
                          label="Tarih seçin"
                          onChange={(newValue: any) => {
                            setClosureDate(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </RadioGroup>
                  </FormControl>
                </div>
              ) : (
                <div className="classic-transition flex-fill d-flex justify-content-center align-items-center">
                  <p className="font-xsss text-neutral-600">
                    Kurs başlama tarihinde alımlar duracaktır.
                  </p>
                </div>
              )}
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default CreateCourseSettings;
