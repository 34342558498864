import { Register, RegisterRecordCheckReq } from "../../../domain/entities/auth/register_req";

export class RegisterModel implements Register{
   
    gsmNo: string;
    password: string;
    
    constructor(gmsNo:string,password:string){
         this.gsmNo=gmsNo;
         this.password=password;
       
    }
   
    // mapToModel(dto: RegisterTeacherFirst): RegisterTeacherFirstModel {
    //     return {
    //       gmsNo: dto.gmsNo,
    //       password: dto.password
         
    //     };
    // }
}


export class RegisterRecordCheckReqModel implements RegisterRecordCheckReq {
   
    gsmNo: string;
    scCode: string;
    
    constructor(gmsNo:string,scCode:string){
         this.gsmNo=gmsNo;
         this.scCode=scCode;
       
    }
    
   
}