interface Props {
  size: string;
}

const Logo: React.FunctionComponent<Props> = ({ size }) => {
  return (
    <img
      style={{ width: size, height: size }}
      src="/assets/images/figma-icon/Logo.svg"
      alt="Logo"
    ></img>
  );
};

export default Logo;
