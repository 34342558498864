import React from "react";
// Custom Toogle Button Hooks export , one state , and one Jsx Element
import { Button, ToggleButtonGroup } from "@mui/joy";
import { useState } from "react";

import { TChildrenToogleButtons } from "../core/types/commonTypes";

interface IToogleButton {
  (arg: TChildrenToogleButtons[]): ToogleButtonReturnType;
}

interface Props {
  toggleButtonArray: TChildrenToogleButtons[];
}

// custom hook will accept one array and return one react state which (can be usable in consumer component ) and jsx.element in order to render buttons

type ToogleButtonReturnType = {
  toogleValue: string | null;
  ToogleGroupButton: React.FunctionComponent<Props>;
};

export const useToogleGroupButton: IToogleButton = (
  toggleButtonArray: TChildrenToogleButtons[]
): ToogleButtonReturnType => {
  const [toogleValue, setToogleValue] = useState<string | null>("");

  //   Component
  const ToogleGroupButton: React.FunctionComponent<Props> = ({
    toggleButtonArray,
  }) => {
    return (
      <ToggleButtonGroup
        value={toogleValue}
        onChange={(e, newValue) => {
          setToogleValue(newValue);
        }}
      >
        {toggleButtonArray.map((button: TChildrenToogleButtons, index) => {
          return (
            <Button key={index} value={button.value}>
              {" "}
              {button.buttonName}
            </Button>
          );
        })}
      </ToggleButtonGroup>
    );
  };

  return { toogleValue, ToogleGroupButton };
};
