import { LoginReq, LoginWithOtpReq } from "../../../domain/entities/auth/login_req";

export class LoginReqModel implements LoginReq{
   
    gsmNo: string;
    password: string;
    
    constructor(gmsNo:string,password:string){
         this.gsmNo=gmsNo;
         this.password=password;
       
    }
   
    // mapToModel(dto: RegisterTeacherFirst): RegisterTeacherFirstModel {
    //     return {
    //       gmsNo: dto.gmsNo,
    //       password: dto.password
         
    //     };
    // }
}

export class LoginWithOtpReqModel implements LoginWithOtpReq{
   
    gsmNo: string;
    password: string;
    otp: string;
    
    constructor(gmsNo:string,password:string,otp:string){
         this.gsmNo=gmsNo;
         this.password=password;
         this.otp=otp;
       
    }
   
   
    // mapToModel(dto: RegisterTeacherFirst): RegisterTeacherFirstModel {
    //     return {
    //       gmsNo: dto.gmsNo,
    //       password: dto.password
         
    //     };
    // }
}