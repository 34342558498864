import React, { useEffect, useState, CSSProperties } from "react";
import { CourseEpisodeType } from "../../../pages/course/create-course";
import { PrimaryButton, BorderlessButton } from "../../Button";

// Material Data-Picker Kit
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
// Classic Data-picker
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

const timeAdjuster = (
  date: Date,
  time: Date,
  cb: (value: React.SetStateAction<CourseEpisodeType>) => void
) => {
  // Extract hours and minutes from the time Date object
  const sourceHours = time.getHours();
  const sourceMinutes = time.getMinutes();

  // Create a new Date object with the same date as the original but with updated hours and minutes
  const updatedDate = new Date(date);
  updatedDate.setHours(sourceHours);
  updatedDate.setMinutes(sourceMinutes);

  // Call the provided callback function with the updated state
  cb((previous) => ({
    ...previous,
    date: updatedDate,
  }));
};

const cssSetting = {
  DatePicker: {
    ".MuiInputBase-root": {
      borderRadius: "12px",
    },
  },
  Timepicker: {
    ".MuiInputBase-root": {
      borderRadius: "12px",
    },
  },
  Option: {
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "rgba(86, 86, 217, 1)!important",
      color: "white !important",
    },
  },
};
const optionDataSource = [
  { name: "30 dk", value: "30" },
  { name: "45 dk", value: "45" },
  { name: "1 saat", value: "1" },
  { name: "1.5 saat", value: "1.5" },
  { name: "2 saat", value: "2" },
  { name: "2.5 saat", value: "2.5" },
  { name: "3 saat", value: "3" },
];

enum InputTypeEnum {
  INPUT,
  TEXTAREA,
}

interface InputProps {
  placeholder: string;
  characterLimitation?: number;
  stateAddress: keyof CourseEpisodeType;
  state: CourseEpisodeType;
  dispatch: React.Dispatch<React.SetStateAction<CourseEpisodeType>>;
  className?: string;
  sx?: CSSProperties;
  elementType: InputTypeEnum;
}

const initialEpisodeState = {
  id: undefined,
  title: "",
  subtitle: "",
  date: undefined,
  duration: {
    name: "",
    value: "",
  },
};

const Input: React.FunctionComponent<InputProps> = ({
  placeholder,
  characterLimitation,
  stateAddress,
  state,
  dispatch,
  className,
  sx,
  elementType,
}) => {
  // Focus Feature
  const [isFocus, setIsFocus] = useState<boolean>(false);

  // Remaining Chracter state defination
  const [remainingLimit, setRemainingLimit] = useState<number | undefined>(
    characterLimitation
  );

  // addressed state in formState
  const addressedStateMember = state[stateAddress];
  function isStateString(state: any): string {
    if (typeof state === "string") {
      return state;
    }
    return "";
  }
  const onlyStateMembers = isStateString(addressedStateMember);

  //   showing remaning chracter
  useEffect(() => {
    // calculate remaining chracter
    if (characterLimitation) {
      const remaining = characterLimitation - onlyStateMembers.length;
      setRemainingLimit(remaining);
    }
  }, [characterLimitation, onlyStateMembers]);

  // mutate the state
  const handleChange = (newValue: string) => {
    dispatch((previous: any) => {
      return { ...previous, [stateAddress]: newValue };
    });
  };

  // mutate the state number if it is member of an array

  return (
    <>
      <div
        style={{ borderRadius: "8px" }}
        className={`p-1 w-75 shadow-sm ${
          !isFocus ? "form-input " : "focused-form-input"
        }`}
      >
        {/* If Element Type is Input */}
        {InputTypeEnum.INPUT === elementType && (
          <input
            style={sx}
            onChange={(e) => handleChange(e.target.value)}
            onFocus={(e) => {
              setIsFocus(true);
            }}
            onBlur={(e) => {
              setIsFocus(false);
            }}
            value={onlyStateMembers}
            type="text"
            placeholder={placeholder}
            className={`border-0 px-2 w-100 ${className}`}
            maxLength={characterLimitation}
          />
        )}
        {InputTypeEnum.TEXTAREA === elementType && (
          <textarea
            style={sx}
            onChange={(e) => handleChange(e.target.value)}
            onFocus={(e) => {
              setIsFocus(true);
            }}
            onBlur={(e) => {
              setIsFocus(false);
            }}
            value={onlyStateMembers}
            placeholder={placeholder}
            className={`border-0 px-2 w-100 ${className}`}
            maxLength={characterLimitation}
          />
        )}
      </div>

      {characterLimitation ? (
        <label className="font-xsss text-neutral-600">
          {remainingLimit} karakter hakkınız kaldı.
        </label>
      ) : null}
    </>
  );
};

interface CreateNewEposideProps {
  setIsCreateNewSectionActive: React.Dispatch<React.SetStateAction<boolean>>;
  episodesState: CourseEpisodeType[];
  episodesDispatch: React.Dispatch<
    React.SetStateAction<CourseEpisodeType[] | []>
  >;
}

export const CreateNewEposide: React.FunctionComponent<
  CreateNewEposideProps
> = ({ setIsCreateNewSectionActive, episodesState, episodesDispatch }) => {
  // Local state for per episode
  const [episode, setEpisode] =
    useState<CourseEpisodeType>(initialEpisodeState);
  // Date
  const [localdate, setLocalDate] = useState<Date | null>(null);
  // Time
  const [localtime, setLocalTime] = useState<Date | null>(null);

  // Time Management
  useEffect(() => {
    // timeparser function works return one type of dating and update relevant part of state
    if (localdate && localtime) timeAdjuster(localdate, localtime, setEpisode);
  }, [localtime, localdate]);
  // consistent id assignment
  useEffect(() => {
    const uniqueId = episodesState.length + 1;
    setEpisode((previous) => {
      return { ...previous, id: uniqueId };
    });
  }, [episodesState.length]);

  // useEffect(() => {
  //   console.log(episode);
  // }, [episode]);

  const sendEpisodeToParrentHandler = () => {
    episodesDispatch((previous) => {
      return [...previous, episode];
    });
    setEpisode(initialEpisodeState);
    setIsCreateNewSectionActive(false);
  };
  // close the tab reset the local states
  const giveupHandler = (): void => {
    setIsCreateNewSectionActive(false);
    setEpisode(initialEpisodeState);
  };

  return (
    <>
      <div className="row border py-3 classic-transition">
        {/* Title */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Başlık</label>
              <p className="w-75">
                Başlığınız ilgi çekici, bilgilendirici ve arama için uygun
                olmalıdır. Daha sonra değiştirebilirsiniz.
              </p>
            </div>
            <div className="col-7">
              <Input
                placeholder="Kumeler"
                characterLimitation={60}
                stateAddress="title"
                state={episode}
                dispatch={setEpisode}
                elementType={InputTypeEnum.INPUT}
              />
            </div>
          </div>
          <hr />
        </div>
        {/* Subtitle */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">İçerik Açıklaması</label>
              <p className="w-75">Kısa bir tanıtım yazın.</p>
            </div>
            <div className="col-7">
              <Input
                placeholder="Açıklama"
                characterLimitation={60}
                stateAddress="subtitle"
                state={episode}
                dispatch={setEpisode}
                sx={{ height: "154px" }}
                elementType={InputTypeEnum.TEXTAREA}
              />
            </div>
          </div>
          <hr />
        </div>
        {/* Date-Time */}
        <div className="col-12">
          <div className="d-flex row mb-4">
            <div className="col-5 d-flex flex-column font-xsss">
              <label className="fw-600">Tarih ve Saat</label>
              {/* <p className="w-75">
                Başlığınız ilgi çekici, bilgilendirici ve arama için uygun
                olmalıdır. Daha sonra değiştirebilirsiniz.
              </p> */}
            </div>
            <div className="col-7">
              <div
                className="w-75"
                style={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    orientation="portrait"
                    sx={cssSetting.DatePicker}
                    label="Tarih seçin"
                    onChange={(newValue: any) => {
                      setLocalDate(newValue);
                    }}
                  />
                  <TimePicker
                    sx={cssSetting.Timepicker}
                    label="Saat seçin"
                    ampm={false}
                    onChange={(newValue: any) => {
                      setLocalTime(newValue);
                    }}
                    views={["hours", "minutes"]}
                    format="HH:mm"
                    minutesStep={3}
                  />
                </LocalizationProvider>
              </div>
              <div className="d-flex mt-2 w-75">
                <Select
                  onChange={(e: any, newValue: any) => {
                    setEpisode((previous) => {
                      return { ...previous, duration: newValue };
                    });
                  }}
                  placeholder="Süre seçin"
                  sx={{
                    width: "100%",
                  }}
                >
                  {optionDataSource.map((option, index) => {
                    return (
                      <Option key={index} sx={cssSetting.Option} value={option}>
                        {option.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>
          <hr />
        </div>

        {/* button sending episode to main state */}
        <div className="col-12">
          <div style={{ gap: "12px" }} className="d-flex justify-content-end">
            <BorderlessButton onClick={giveupHandler} className="border p-3">
              Vazgeç
            </BorderlessButton>
            <PrimaryButton
              onClick={sendEpisodeToParrentHandler}
              className="p-3"
            >
              Oluştur
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
};
