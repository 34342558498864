import React from "react";
import { PrimaryButton } from "../../../components/Button";

interface NoEpisodeProps {
  setIsCreateNewSectionActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NoEpisode: React.FunctionComponent<NoEpisodeProps> = ({
  setIsCreateNewSectionActive,
}) => {
  return (
    <>
      <div
        className="classic-transition d-flex flex-column align-items-center"
        style={{ width: "max-content", gap: "10px" }}
      >
        <img
          style={{ display: "block" }}
          className="mx-auto"
          src="/assets/images/figma-icon/icon-no-episode.svg"
          alt="no-episode"
        ></img>
        <h2 className="font-xs fw-600 m-0">Henüz bir bölüm bulunmamaktadır</h2>
        <p className="w-50 text-center m-0">
          Bir seriyi profilinizde yayınlamak ve kayıt olmaya açık hale getirmek
          için serinize en az 1 bölüm ekleyin.
        </p>
        <PrimaryButton
          onClick={() => {
            setIsCreateNewSectionActive(true);
          }}
          sx={{ border: "3px solid #e1e1f0" }}
          className="px-3 py-2"
          isBorderAllowed={true}
        >
          + Yeni bölüm ekle
        </PrimaryButton>
      </div>
    </>
  );
};
