import { PrimaryButton, BorderlessButton } from "../Button";

const ProfileInteraction: React.FunctionComponent = () => {
  return (
    <>
      <div className="font-xsss d-flex">
        <BorderlessButton
          onPopUp
          onPopUpContent={`Genelde ${1} saat içerisinde yanıt verir`}
          className="text-neutral-600 border px-2 "
        >
          <img
            src="/assets/images/figma-icon/comment-text.svg"
            alt="chat"
          ></img>
        </BorderlessButton>
        <BorderlessButton className="text-neutral-600 border px-2 ml-2 ">
          <img src="/assets/images/figma-icon/heart.svg" alt="heart"></img>
        </BorderlessButton>
        <BorderlessButton className="text-neutral-600 border px-3 ml-2">
          <p>Ders Paketi Satın Al</p>
        </BorderlessButton>
        <PrimaryButton className="px-3 ml-2">
          <p>Deneme Dersi Ayırt</p>
        </PrimaryButton>
      </div>
    </>
  );
};

export default ProfileInteraction;
