
import { useEffect } from "react";
import useAuth from "./useAuth";
import { instance } from "../../core/network/network_manager";
import useRefreshToken from "./useRefreshToken";
import { axiosPrivate } from "../../core/network/axios";

const useAxiosPrivate = () => {
  //  const refresh = useRefreshToken();
    const auth  = useAuth();

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                console.log(localStorage.getItem('accessToken'));
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = "Bearer "+ auth?.auth?.accessToken;
                    console.log(config.headers['Authorization']);

                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                // if (error?.response?.status === 403 && !prevRequest?.sent) {
                //     prevRequest.sent = true;
                //     const newAccessToken = await refresh();
                //     prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                //     return instance(prevRequest);
                // }
                return Promise.reject(error);
            }
        );

        return () => {
            console.log(requestIntercept);
          console.log(auth?.auth?.accessToken);

          axiosPrivate.interceptors.request.eject(requestIntercept);
          axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth])

    return axiosPrivate;
}

export default useAxiosPrivate;