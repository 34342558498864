import { Http } from "../../core/network/network_manager";
import { Register, RegisterRecordCheckReq } from "../../domain/entities/auth/register_req";
import { AuthRepository } from "../../domain/repositories/auth_repository";
import remoteAuthDataSource from "../data_sources/auth/remote_auth_data_source";
import { RegisterModel, RegisterRecordCheckReqModel } from "../models/auth/register_model";
import { LoginReponse } from "../../domain/entities/auth/login_reponse";
import { LoginReponseModel, LoginReponseModel2 } from "../models/auth/login_reponse_model";
import { LoginReq, LoginWithOtpReq } from "../../domain/entities/auth/login_req";
import { LoginReqModel, LoginWithOtpReqModel } from "../models/auth/login_req_model";

export class AuthRepositoryImpl implements AuthRepository{
   async login(model: LoginReqModel): Promise<LoginReponseModel> {
       return remoteAuthDataSource().login(model);
   }
   async loginOtp(model: LoginWithOtpReqModel): Promise<LoginReponseModel> {
      return remoteAuthDataSource().loginOtp(model);
   }

   async createRegisterStudent(model: RegisterModel): Promise<any> {
    return remoteAuthDataSource().createRegisterStudent(model);
   }
   async checkRegisterStudent(model: RegisterRecordCheckReqModel): Promise<LoginReponseModel> {
    return remoteAuthDataSource().checkRegisterStudent(model);
   }


   async checkRegisterInstructor(model: RegisterRecordCheckReqModel): Promise<LoginReponseModel> {
       return remoteAuthDataSource().checkRegisterInstructor(model);
   }
   async createRegisterInstructor(model: RegisterModel): Promise<any> {
       return remoteAuthDataSource().createRegisterInstructor(model);
   }
   
    
}

export default AuthRepositoryImpl;
