import React, {
  ReactElement,
  createContext,
  useCallback,
  useReducer,
} from "react";

// Types
import { TUserType } from "../core/types";

// Reducers
const enum REDUCER_ACTION_TYPE {
  STEPPER_DOWN, // increase step
  STEPPER_UP, // dicrese step
  USER_TYPE_PICKER, // cach the userstype
  USER_DATA_PICKER, // cach the data gsmno and password
  OTP_PICKER, // cach otp
}

type ReducerAction = {
  type: REDUCER_ACTION_TYPE;
  payload?: {
    userType?: string;
    gsmNO?: string;
    password?: string;
    OTP?: string;
  };
};

type State = {
  stepper: number;
  form: {
    userType: string | undefined;
    gsmNO: string | undefined;
    password: string | undefined;
  };
  OTP: string | undefined;
};

export const initialState: State = {
  stepper: 1,
  form: {
    userType: "",
    gsmNO: "",
    password: "",
  },
  OTP: "",
};

const reducer = (state: State, action: ReducerAction): State => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.STEPPER_DOWN:
      if (state.stepper > 1 && state.stepper <= 3) {
        return { ...state, stepper: state.stepper - 1 };
      } else {
        return { ...state };
      }
    case REDUCER_ACTION_TYPE.STEPPER_UP:
      if (state.stepper >= 1 && state.stepper < 3) {
        return { ...state, stepper: state.stepper + 1 };
      } else {
        return { ...state };
      }
    case REDUCER_ACTION_TYPE.USER_TYPE_PICKER:
      return {
        ...state,
        form: { ...state.form, userType: action.payload?.userType },
      };
    case REDUCER_ACTION_TYPE.USER_DATA_PICKER:
      return {
        ...state,
        form: {
          ...state.form,
          gsmNO: action.payload?.gsmNO,
          password: action.payload?.password,
        },
      };
    case REDUCER_ACTION_TYPE.OTP_PICKER:
      return {
        ...state,
        OTP: action.payload?.OTP,
      };

    default:
      throw new Error();
  }
};

const useRegisterContext = (initialState: State) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const StepIncrement = useCallback(() => {
    return dispatch({ type: REDUCER_ACTION_TYPE.STEPPER_UP });
  }, []);

  const StepDicrement = useCallback(() => {
    return dispatch({ type: REDUCER_ACTION_TYPE.STEPPER_DOWN });
  }, []);

  const TypePicker = useCallback((userType: TUserType) => {
    return dispatch({
      type: REDUCER_ACTION_TYPE.USER_TYPE_PICKER,
      payload: { userType: userType },
    });
  }, []);

  const DataPicker = useCallback((gsmNO: string, password: string) => {
    return dispatch({
      type: REDUCER_ACTION_TYPE.USER_DATA_PICKER,
      payload: { gsmNO: gsmNO, password: password },
    });
  }, []);

  const OTPPicker = useCallback((OTP: string) => {
    return dispatch({
      type: REDUCER_ACTION_TYPE.OTP_PICKER,
      payload: { OTP: OTP },
    });
  }, []);

  return {
    state,
    StepIncrement,
    StepDicrement,
    TypePicker,
    DataPicker,
    OTPPicker,
  };
};

type UseRegisterContextType = ReturnType<typeof useRegisterContext>;

// Context Comprehensive Initial States (Pure State and Functions All together)

const initialContextState: UseRegisterContextType = {
  state: initialState,
  StepIncrement: () => {},
  StepDicrement: () => {},
  TypePicker: (userType: TUserType) => {},
  DataPicker: (gsmNO: string, password: string) => {},
  OTPPicker: (OTP: string) => {},
};

export const RegisterContext =
  createContext<UseRegisterContextType>(initialContextState);

interface Props {
  children: ReactElement | undefined;
  initialState: State;
}

// Context Provider Launch

export const RegisterProvider: React.FunctionComponent<Props> = ({
  children,
  initialState,
}) => {
  return (
    <RegisterContext.Provider value={useRegisterContext(initialState)}>
      {children}
    </RegisterContext.Provider>
  );
};
