import React from "react";
import { useEffect, useState, useContext } from "react";

import { useNavigate } from "react-router-dom";

// Interfaces & Types
import IPage from "../../../../core/interfaces/page";

// Context Architecture
import {
  RegisterProvider,
  initialState,
} from "../../../../context/useRegisterContext";
import RegisterMain from "../../../components/register/RegisterMain";

const RegisterPage: React.FunctionComponent<IPage> = (props) => {

        
  return (
    <>
      {/* Register context provider will be located here  */}

      <RegisterProvider initialState={initialState}>
        <RegisterMain></RegisterMain>
      </RegisterProvider>
    </>
  );
};
export default RegisterPage;
