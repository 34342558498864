import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { ProfileInstructorReqModel } from "../../../infrastructure/models/profile/profile_instructor_update_req_model";
import { ProfileStudentReqModel } from "../../../infrastructure/models/profile/profile_student_update_req_model";
import OnBoardingViewModel from "../../pages/onboarding/view_model/onboarding_view_model";

// Components
import OnBoardingFormSteps from "./OnBoardingFormSteps";
import StepControlButtons from "./StepControlButtons";

// Input type Source
export type FormValues = {
  name_: string;
  surname:string;
  tckn:number;
  country: string;
  gender: "Kız" | "Erkek";
  birhtDay:string;
  school: string;
  email: string;

  // It may require dedicated list type
  class: string;
  title: string;
  // It may require dedicated list type
  field: string;
  experience: {
    year: number;
    month: number;
  };
  about: string;
};

const OnBoardingForm = ({
  currentStep,
  setCurrentStep,
  dispatch,
}: {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  // will be refactored
  dispatch: React.Dispatch<any>;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  // default value will be available from backend using defaultvalue attribute

  // State Laverage to Form Main

  const watchedName = watch("name_");
  const watchedSurname = watch("surname");
  const watchedSchool = watch("school");
  const watchedAbout = watch("about");
  useEffect(() => {
    dispatch({ type: "UPDATE_NAME", payload: { name: watchedName } });
    dispatch({ type: "UPDATE_SURNAME", payload: { surname: watchedSurname } });
    dispatch({ type: "UPDATE_SCHOOL", payload: { school: watchedSchool } });
    dispatch({ type: "UPDATE_ABOUT", payload: { about: watchedAbout } });
  }, [watchedName, watchedSurname,watchedSchool, watchedAbout, dispatch]);

  // Api call method
  var handlerSubmit=OnBoardingViewModel();
  const onSubmit: SubmitHandler<FormValues> = (data) => {
    // For student
    console.log("Data will send to the api call", data);

    handlerSubmit.updateProfile(data
    );
    //api call
    // For Teacher
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OnBoardingFormSteps
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        currentStep={currentStep}
      ></OnBoardingFormSteps>
      <StepControlButtons
        handleSubmit={handleSubmit}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      ></StepControlButtons>
    </form>
  );
};

export default OnBoardingForm;
