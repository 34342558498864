import { useContext, useDebugValue } from "react";
import AuthContext from "../../core/providers/auth_provider";

const useAuth = () => {
    const auth = useContext(AuthContext);
    if(!auth)
    {
        throw new Error('Auth context error!!')
    }
    // useDebugValue(auth, auth => auth?.user ? "Logged In" : "Logged Out");
     console.log(auth);
    auth?.auth?.accessToken!=null?localStorage.setItem('accessToken', JSON.stringify(auth?.auth?.accessToken)):console.log(auth);
    auth?.auth?.roles!=null?localStorage.setItem('roles', JSON.stringify(auth?.auth?.roles)):console.log(auth);
    
    // if(localStorage.getItem('roles')!=null)
    // {
    //     const roles=[localStorage.getItem('roles')!];
    //     const accessToken=localStorage.getItem('accessToken')!;
    //     auth?.setAuth({roles,accessToken});

    // }
    return auth;
}

export default useAuth;