import React from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormValues } from "./OnBoardingForm";

type ButtonType = "Onceki" | "Sonraki" | "Tamamla";

const StepControlButtons = ({
  currentStep,
  setCurrentStep,
  handleSubmit,
}: {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: UseFormHandleSubmit<FormValues, FormValues>;
}) => {
  const CurrentStepHandlersByButtons = (buttontype: ButtonType): void => {
    if (buttontype === "Onceki" && currentStep > 1) {
      setCurrentStep((previous) => previous - 1);
    } else if (buttontype === "Sonraki" && currentStep < 3) {
      setCurrentStep((previous) => previous + 1);
    } else if (buttontype === "Tamamla") {
      console.log("After validation send form to the backend");
    }
  };

  const Button = ({
    label,
    className,
    onClick,
  }: {
    label: string;
    className?: string;
    onClick: () => void;
  }) => (
    <button
      className={className}
      style={{
        borderRadius: "10px",
      }}
      type="submit"
      onClick={onClick}
    >
      {label}
    </button>
  );

  const PreviousButton = () => (
    <Button
      className="bg-color-white   fw-600 border px-3 py-2  mt-3 mb-3 "
      label="←  Önceki"
      onClick={() => CurrentStepHandlersByButtons("Onceki")}
    />
  );

  const NextButton = () => (
    <Button
      label={`Sonraki → `}
      className="bg-primary-600 text-white  fw-600 border px-3 py-2  mt-3 mb-3 "
      onClick={
        () =>
          // Handle Submit Works when all required inputs excepted
          //   this handlesubmit works locally
          handleSubmit((data) => {
             console.log(currentStep);
            CurrentStepHandlersByButtons("Sonraki");
          })()

        //
      }
    />
  );

  const FormCompleteButton = () => (
    //   this button doesnt have local handlesubmit therefore triggers to general form
    <button
      style={{ borderRadius: "10px" }}
      className="bg-primary-600 text-white  fw-600 border px-3 py-2  mt-3 mb-3 "
      type="submit"
    >
      Tamamla
    </button>
  );

  const steps = [
    <div className="d-flex justify-content-end mt-2">{<NextButton />}</div>,
    <div className="d-flex justify-content-between mt-2">
      {<PreviousButton />}
      {<NextButton />}
    </div>,
    <div className="d-flex justify-content-between mt-2">
      {<PreviousButton />}
      {<FormCompleteButton />}
    </div>,
  ];

  return <>{steps[currentStep - 1]}</>;
};

export default StepControlButtons;