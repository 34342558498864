import React from "react";
import { HomePageSpan, DynamicFeatures } from "../home";
import { PrimaryButton } from "../Button";
import Accordion from "../Accordion";

// hooks

const dynamicContentArray = [
  {
    icon: "/assets/images/figma-icon/icon-features.svg",
    header: "Alanında üst düzey binlerce uzmana ücretsiz erişim",
    content:
      "250+ kategoride özel ders alabileceğin bir öğretmene ihtiyacın mı var? Eduiko seni aradığın kriterlerde, ihtiyaçlarına en uygun öğretmene ulaşmanı sağlar. Ders alabileceğin en iyi öğretmenleri gelişmiş arama motorumuz sayesinde dakikalar içinde keşfedebilirsin. Tüm kategorilerde x öğretmene 5 yıldızlı x onaylı üye yorumu geldi. ",
    imgLink: "/assets/images/SignUp/slider-1.svg",
  },
  {
    icon: "/assets/images/figma-icon/icon-features.svg",
    header: "Başarınızı hızlandıracak kişiselleştirilmiş öneriler",
    content:
      "Book 1:1 mentorship session & get advice, insights to move faster with your work. Öğretmenlerin tecrübleri, uzman olduğu alanlar, ders verdiği yerler, tanıtım videosu, saatlik olarak ders ücreti vb. gibi bilgilerine profil üzerinden ulaşabilirsiniz. Birçok kritere göre karşılaştırma yapıp aradığın öğretmeni keşfedebilirsin. İhtiyaçlarına en uygun olduğunu düşündüğün öğretmene ders talebini ilet. Eduiko’da her bütçeye uygun öğretmen bulunmaktadır.",
    imgLink: "/assets/images/SignUp/slider-2.svg",
  },
  {
    icon: "/assets/images/figma-icon/icon-features.svg",
    header: "Uzun vadeli hedeflerinize kolayca ulaşın",
    content: `Tekrarlanan oturumlar için öğretmenlerle bağlantı kurun ve uzun vadeli bir hedefe doğru çalışın.
    “Genel Ders Talebi” oluşturma kısmından sistemin sizi yönlendirdiği şekilde isteklerinizi net bir şekilde ifade ederek genel bir talebi oluşturabilirsiniz. Talebinize en uygun öğretmenler ⚡ gün içerisinde sizinle iletişime geçecektir. Öğretmenlerle görüşme sağlayıp, alternatif oluşturabilir ve aradığınız öğretmeni bulabilirsiniz.`,
    imgLink: "/assets/images/SignUp/slider-3.svg",
  },
];

const accordionContentArray = [
  {
    summary: "Is there a free trial available?",
    details:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    summary: "Can I change my plan later?",
    details:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    summary: "What is your cancellation policy?",
    details:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    summary: "Can other info be added to an invoice?",
    details:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    summary: "How does billing work?",
    details:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    summary: "How do I change my account email?",
    details:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
];

const HomeFeatures: React.FunctionComponent = () => {
  return (
    <>
      <section className="features-wrapper container mt-5 mt-sm-0">
        {/* Header */}
        <div className="row mb-3">
          <div className="col-12 text-center text-sm-left">
            <HomePageSpan>Nasıl Çalışır?</HomePageSpan>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-8 text-center text-sm-left">
            <h1 className=" display1-size fw-600 ">
              Accelerate your skills & career growth
            </h1>
            <p className="mt-3 font-sm ">
              Access a lineup of extraordinary teachers from various backgrounds
              with just a click, designed to provide quick wins and valuable
              insights.
            </p>
          </div>
        </div>
        {/* Header */}

        {/* Dynamic Content */}
        <div className="row mt-5">
          <DynamicFeatures contentArray={dynamicContentArray} />
        </div>

        {/* Private Lesson Section  */}
        <div className="row mt-5 pb-5 pt-5 bg-light full-width ">
          <div className="col-12 col-sm-6 px-sm-0 text-center text-sm-left">
            <HomePageSpan>Özel Ders Ver</HomePageSpan>
            <h1 className="display1-size fw-600 mt-2 ">
              Lorem ipsum dolor sit amet
            </h1>
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <PrimaryButton className="mt-4 px-3 py-2">
              Özel Ders Vermeye Başla/Bize Katılın
            </PrimaryButton>
          </div>

          <div className="col-12 col-sm-6  mt-5 mt-sm-0 px-sm-0 text-center text-sm-left">
            {/* On of them */}
            <div className=" d-flex flex-column flex-sm-row align-items-center">
              <img
                style={{ borderRadius: "10px" }}
                src="/assets/images/figma-icon/icon-check.svg"
                className="border p-2 align-self-sm-start"
                alt="icon"
              />
              <div className="ml-sm-3">
                <h1 className="mt-3 fw-600 font-sm">
                  Eğitimci Profili Oluştur
                </h1>
                <p>
                  Ders vermek için eğitimci profili oluşturmak ücretsizdir.
                  Kendinizi en iyi şekilde ifade edebileceğiniz ve
                  profesyonelliğinizi en iyi şekilde yansıtacak öğretmen profili
                  oluştur ve özel ders verme yolculuğunun ilk adımını tamamla.
                  Kendi ders ücretinizi, ders verdiğini alanları ve yerleri
                  kendiniz belirleyin.{" "}
                </p>
              </div>
            </div>
            {/* On of them */}
            {/* On of them */}
            <div className="mt-3 pt-4 d-flex flex-column flex-sm-row align-items-center">
              <img
                style={{ borderRadius: "10px" }}
                src="/assets/images/figma-icon/icon-check.svg"
                className="border p-2 align-self-sm-start"
                alt="icon"
              />
              <div className="ml-sm-3">
                <h1 className="mt-3 fw-600 font-sm">Özgürce Ders Ver</h1>
                <p>
                  Herhangi bir aracı kuruma bağlı kalmadan öğrenci portfolyonuzu
                  oluşturun. İster yüz yüze ister online olarak ders vermeye
                  başlayın. Hem kendinize gelen talepler ile, hem sistem
                  üzerindeki genel ders istekleriyle görüşme sağlayıp onlarca
                  yeni öğrenciye ulaşabilirsin.
                </p>
              </div>
            </div>
            {/* On of them */}
            {/* On of them */}
            <div className="mt-3 pt-4 d-flex flex-column flex-sm-row align-items-center">
              <img
                style={{ borderRadius: "10px" }}
                src="/assets/images/figma-icon/icon-check.svg"
                className="border p-2 align-self-sm-start"
                alt="icon"
              />
              <div className="ml-sm-3">
                <h1 className="mt-3 fw-600 font-sm">
                  Arama Motorlarında En İyi Sonuç
                </h1>
                <p>
                  Tüm iller, ilçeler, dersler, branşlarda ozeldersalani.com
                  arama motorlarında en iyi sonuca sahiptir. Aradığı öğretmeni
                  bulmak için her gün on binlerce kişi ozeldersalani.com’u
                  ziyaret ediyor.
                </p>
              </div>
            </div>
            {/* On of them */}
          </div>
        </div>

        {/* Service Section */}
        <div className="row mt-5">
          <div className="col-12 col-sm-4">
            <HomePageSpan>Hizmetler</HomePageSpan>
            <h1 className="fw-600 display1-size mt-2">
              Lorem ipsum dolor sit amet.
            </h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
          <div className="col-12 col-sm-8">
            <div className="row">
              {/* One of them */}
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <div
                  className="d-flex flex-column p-3"
                  style={{ backgroundColor: "hsla(244, 100%, 92%, 1)" }}
                >
                  <img
                    className="align-self-start"
                    src="/assets/images/figma-icon/icon-feature-letter.svg"
                    alt="check"
                  ></img>
                  <div className="mt-5">
                    <h1 className="font-sm fw-600">Özel Dersler</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* One of them */}

              {/* One of them */}
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <div
                  className="d-flex flex-column p-3"
                  style={{ backgroundColor: "hsla(189, 100%, 93%, 1)" }}
                >
                  <img
                    className="align-self-start"
                    src="/assets/images/figma-icon/icon-feature-letter.svg"
                    alt="check"
                  ></img>
                  <div className="mt-5">
                    <h1 className="font-sm fw-600">Grup Dersleri</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* One of them */}
              {/* One of them */}
              <div className="col-12 col-sm-6 mt-4">
                <div
                  className="d-flex flex-column p-3"
                  style={{ backgroundColor: "hsla(58, 100%, 93%, 1)" }}
                >
                  <img
                    className="align-self-start"
                    src="/assets/images/figma-icon/icon-feature-letter.svg"
                    alt="check"
                  ></img>
                  <div className="mt-5">
                    <h1 className="font-sm fw-600">Ödev ve Essay Yardımı</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* One of them */}
              {/* One of them */}
              <div className="col-12 col-sm-6 mt-4">
                <div
                  className="d-flex flex-column p-3"
                  style={{ backgroundColor: "hsla(306, 69%, 88%, 1)" }}
                >
                  <img
                    className="align-self-start"
                    src="/assets/images/figma-icon/icon-feature-letter.svg"
                    alt="check"
                  ></img>
                  <div className="mt-5">
                    <h1 className="font-sm fw-600">Uzman Soru Çözümü</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* One of them */}
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="row mt-5">
          {/* Header */}
          <div className="col-12 col-sm-8  px-sm-0 text-center text-sm-left">
            <HomePageSpan>Özel Ders Ver</HomePageSpan>
            <h1 className="display1-size fw-600 mt-2 ">
              Lorem ipsum dolor sit amet
            </h1>
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <div className="col-12 px-sm-0 mt-sm-5 ">
            <div className="row ">
              {/* One of them */}
              <div className="col-12 px-sm-0 col-sm-4 mt-4 mt-sm-0">
                <div className="d-flex flex-column p-3">
                  <img
                    className="align-self-start "
                    src="/assets/images/figma-icon/icon-featured-messenger.svg"
                    alt="check"
                  ></img>
                  <div className="mt-3">
                    <h1 className="font-sm fw-600">Özel Eğitmenler</h1>
                    <p>
                      Eduiko’da yer alan tüm eğitmenler için yeterlilik testleri
                      ve mülakat süreci uygulanır. Eğitmen ağımız dünyanın en
                      iyi okullarından, yüksek derecelere sahip mezunlardan
                      oluşmaktadır.
                    </p>
                  </div>
                </div>
              </div>
              {/* One of them */}
              {/* One of them */}
              <div className="col-12 col-sm-4 mt-4 mt-sm-0">
                <div className="d-flex flex-column p-3">
                  <img
                    className="align-self-start "
                    src="/assets/images/figma-icon/icon-featured-lightning.svg"
                    alt="check"
                  ></img>
                  <div className="mt-3">
                    <h1 className="font-sm fw-600">%100 Güvenli</h1>
                    <p>
                      Platformumuz üzerinden aldığınız hizmetler, en güvenli
                      ödeme alt yapıları ve Eduiko güvencesiyle gerçekleşir.
                      Süreç boyunca sizinle ilgilenecek eğitim danışmanına her
                      zaman ulaşabilirsin.
                    </p>
                  </div>
                </div>
              </div>
              {/* One of them */}
              {/* One of them */}
              <div className="col-12 col-sm-4 mt-4 mt-sm-0">
                <div className="d-flex flex-column p-3">
                  <img
                    className="align-self-start "
                    src="/assets/images/figma-icon/icon-featured-tab.svg"
                    alt="check"
                  ></img>
                  <div className="mt-3">
                    <h1 className="font-sm fw-600">
                      Raporlar ile Başarı Takibi
                    </h1>
                    <p>
                      Measure what matters with Product name’s easy-to-use
                      reports. You can filter, export, and drilldown on the data
                      in a couple clicks.
                    </p>
                  </div>
                </div>
              </div>
              {/* One of them */}
            </div>
          </div>
        </div>

        {/* FAQs ACCORDION */}
        <div className="row mt-5 h-100">
          <div className="col-12 col-sm-5">
            <HomePageSpan>Destek/Yardım</HomePageSpan>
            <h1 className="display1-size fw-600 mt-2 ">FAQs</h1>
            <p className="">
              Everything you need to know about the product and billing. Can’t
              find the answer you’re looking for? Please chat to our friendly
              team.
            </p>
          </div>
          <div className="col-12 col-sm-7">
            <Accordion sourceArray={accordionContentArray}></Accordion>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeFeatures;
