import { Link } from "react-router-dom";





const SearchCard = () => {

    return (
        <>
     <div className="col-12 col-md-12">
        <div className="card card-new" >
        <div className="d-flex justify-content-between mt-3 ml-3 mr-3">
          <a className="d-flex justify-content-start" href="/search">
      
            <span className="text-search-card-top">Öğretmenler</span>
            
            </a>
           <a className="d-flex justify-content-end" href="/search">
           <span className="text-search-card-top">Tümünü gör</span>
           </a>
          </div>
           <div className="card-body">
           <div className="d-flex flex-column bd-highlight">
              <a className="bd-highlight serach-mouse-move-top" href="/search">
              <div className="d-flex justify-content-between pl-2 pt-2">

                 <div className="d-flex justify-content-start"><img
                      src="https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?h=350&auto=compress&cs=tinysrgb"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Phoenix Baker</span>
                       <span className="text-search-ex">Matematik kategorisinde en çok tercih edilen</span>

                     </div>
                    </div>
                     
                </div>
                </a>
                <a className="bd-highlight serach-mouse-move-top" href="/search">
              <div className="d-flex justify-content-between pl-2 pt-2">

                 <div className="d-flex justify-content-start"><img
                      src="https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?h=350&auto=compress&cs=tinysrgb"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Phoenix Baker</span>
                       <span className="text-search-ex">Matematik kategorisinde en çok tercih edilen</span>

                     </div>
                    </div>
                     
                </div>
                </a>
                <a className="bd-highlight serach-mouse-move-top" href="/search">
              <div className="d-flex justify-content-between pl-2 pt-2">

                 <div className="d-flex justify-content-start"><img
                      src="https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?h=350&auto=compress&cs=tinysrgb"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Phoenix Baker</span>
                       <span className="text-search-ex">Matematik kategorisinde en çok tercih edilen</span>

                     </div>
                    </div>
                     
                </div>
                </a>
            </div>
         
             </div>
             
             <ul className="list-group overflow-auto pl-3">
             <li className=""><span className="text-search-card-top">Kategoriler</span></li>
             <Link
                    
                    to={"/search"}
                  >
                    
                 <li className="catalog"> <div className="d-flex justify-content-start"><img
                      src="/assets/images/figma-icon/Featured_icon.svg"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Matematik</span>
                       <span className="text-search-ex">234 öğretmen</span>

                     </div>
                    </div>
                </li>
                </Link>
                <Link
                    
                    to={"/search"}
                  >
                    
                 <li className="catalog"> <div className="d-flex justify-content-start"><img
                      src="/assets/images/figma-icon/Featured_icon.svg"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Matematik</span>
                       <span className="text-search-ex">234 öğretmen</span>

                     </div>
                    </div>
                </li>
                </Link>
                <Link
                    
                    to={"/search"}
                  >
                    
                 <li className="catalog"> <div className="d-flex justify-content-start"><img
                      src="/assets/images/figma-icon/Featured_icon.svg"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Matematik</span>
                       <span className="text-search-ex">234 öğretmen</span>

                     </div>
                    </div>
                </li>
                </Link>
                <Link
                    
                    to={"/search"}
                  >
                    
                 <li className="catalog"> <div className="d-flex justify-content-start"><img
                      src="/assets/images/figma-icon/Featured_icon.svg"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Matematik</span>
                       <span className="text-search-ex">234 öğretmen</span>

                     </div>
                    </div>
                </li>
                </Link>
                <Link
                    
                    to={"/search"}
                  >
                    
                 <li className="catalog"> <div className="d-flex justify-content-start"><img
                      src="/assets/images/figma-icon/Featured_icon.svg"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Matematik</span>
                       <span className="text-search-ex">234 öğretmen</span>

                     </div>
                    </div>
                </li>
                </Link>
                <Link
                    
                    to={"/search"}
                  >
                    
                 <li className="catalog"> <div className="d-flex justify-content-start"><img
                      src="/assets/images/figma-icon/Featured_icon.svg"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Matematik</span>
                       <span className="text-search-ex">234 öğretmen</span>

                     </div>
                    </div>
                </li>
                </Link>
                <Link
                    
                    to={"/search"}
                  >
                    
                 <li className="catalog"> <div className="d-flex justify-content-start"><img
                      src="/assets/images/figma-icon/Featured_icon.svg"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Matematik</span>
                       <span className="text-search-ex">234 öğretmen</span>

                     </div>
                    </div>
                </li>
                </Link>
                <Link
                    
                    to={"/search"}
                  >
                    
                 <li className="catalog"> <div className="d-flex justify-content-start"><img
                      src="/assets/images/figma-icon/Featured_icon.svg"
                      alt="Verifiedtick1967"
                      className="search-instructor-image mr-2"
                    />
                     <div className="d-flex flex-column bd-highlight mb-2">
                      <span className="text-search-name">Matematik</span>
                       <span className="text-search-ex">234 öğretmen</span>

                     </div>
                    </div>
                </li>
                </Link>
            </ul>
     
         <div className="d-flex flex-row bd-highlight ">
          <div className="p-2 bd-highlight"> <a className="d-flex justify-content-start">
            <span className="text-search-card-top">	&uarr; &darr;to navigate</span>
            </a></div>
          <div className="p-2 bd-highlight"> <a className="d-flex justify-content-end">
           <span className="text-search-card-top"> &#8617;to select</span>
           </a></div>
           
        
    
          
       </div>
     </div>
     </div>
    
        </>
    );
};

export default SearchCard;
