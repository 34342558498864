import { useState } from "react";
import useLoading from "../../../../hooks/userLoading";
import { useNavigate } from "react-router-dom";
import ProfileRepositoryImpl from "../../../../infrastructure/repositories/profile_repository_impl";
import { ProfileInstructorReqModel } from "../../../../infrastructure/models/profile/profile_instructor_update_req_model";
import { ProfileStudentReqModel } from "../../../../infrastructure/models/profile/profile_student_update_req_model";
import useAuth from "../../../../hooks/Auth/useAuth";
import RemoteProfileDataSource from "../../../../infrastructure/data_sources/profile/remote_profile_data_source";
import useRemoteProfileDataSource from "../../../../infrastructure/data_sources/profile/remote_profile_data_source";

export default function ProfileViewModel() {
    const { setLoading } = useLoading();
    const navigate = useNavigate();
    const {auth}  = useAuth();
    const profile = useRemoteProfileDataSource();
     
    async function getMyProfileInstructor() {
      setLoading({isLoading: true,isError: false,message:""} );
      return await profile.getMyProfileInstructor().then((res)=>{
        setLoading({isLoading: false,isError: res.isError,message:res.message+" "+res.obj??"" });
        //!res.isError ? navigate("/profile") : navigate("/onboarding");
         return res;
      });
    }

    async function getMyProfileStudent() {
      setLoading({isLoading: true,isError: false,message:""} );
        return await profile.getMyProfileStudent().then((res)=>{
          setLoading({isLoading: false,isError: res.isError,message:res.message+" "+res.obj??"" });
          //!res.isError ? navigate("/profile",{state:res}) : navigate("/onboarding");
          return res;
        });
      }

   
    const getProfile=async ()=>{
          var res=auth?.roles[0]=="Teacher"?await getMyProfileInstructor():await getMyProfileStudent();
          return res;
      }

   
      
  
    return {
        getProfile

    };
  }