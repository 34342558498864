import React, { useContext } from "react";
import { PrimaryButton } from "../Button";

// Context Architecture
import { RegisterContext } from "../../../context/useRegisterContext";

const UserType: React.FunctionComponent = () => {
  const { StepIncrement, TypePicker } = useContext(RegisterContext);

  // Local Handlers
  const StudentHandler = () => {
    TypePicker("Student");
    StepIncrement();
  };
  const TeacherHandler = () => {
    TypePicker("Teacher");
    StepIncrement();
  };

  return (
    <>
      <div className="container-fluid  pt-10 classic-transition">
        <div className="row text-center">
          <div className="col">
            <h1 className="display1-size fw-600">Eduiko'ya hoş geldiniz!</h1>
            <span className="font-sm">Sizi Eduiko'ya getiren şey nedir?</span>
          </div>
        </div>
        {/* User Type Cards */}

        <div className="row row-cols-1 row-cols-md-2 mt-5  justify-content-center px-lg-5  ">
          {/* Student */}
          <div
            style={{ borderRadius: "12px" }}
            className="col col-lg-5 col-xl-3  border mx-3 p-0 shadow-md"
          >
            <div className="row"></div>
            {/* col title */}
            <div className="d-flex flex-column align-items-start px-4 pt-4 ">
              <img
                className="mb-3"
                src="/assets/images/figma-icon/login-student.svg"
                alt="student"
              ></img>
              <div>
                <span className="font-md fw-600">Öğrenci</span>
                <p className="font-xss">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </div>
            {/* col center */}
            <div>
              <ul className="border-top border-bottom p-4">
                <li className="d-flex mb-4 ">
                  <div className="flex-shrink-0">
                    <img
                      style={{ borderRadius: "10px", width: "100%" }}
                      className="border p-2 shadow-xs img-fluid"
                      src="/assets/images/figma-icon/message-chat-circle.svg"
                      alt="message-circle-icon"
                    ></img>
                  </div>
                  <div className="pl-3">
                    <h2 className="fw-600">
                      Öğretmenlerin ürettiği içerikleri izleyin
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore
                      magna...
                    </p>
                  </div>
                </li>
                <li className="d-flex mb-4">
                  <div className="flex-shrink-0">
                    <img
                      style={{ borderRadius: "10px", width: "100%" }}
                      className="border p-2 shadow-xs img-fluid"
                      src="/assets/images/figma-icon/zap.svg"
                      alt="message-circle-icon"
                    ></img>
                  </div>
                  <div className="pl-3">
                    <h2 className="fw-600">
                      Kariyer gelişimine hız kazandırın
                    </h2>
                    <p>
                      Lise, üniversiteye hazırlık, üniversite dersleri için
                      birçok alanda uzman öğretmen topluluğumuza erişin.
                    </p>
                  </div>
                </li>
                <li className="d-flex ">
                  <div className="flex-shrink-0">
                    <img
                      style={{ borderRadius: "10px", width: "100%" }}
                      className="border p-2 shadow-xs img-fluid"
                      src="/assets/images/figma-icon/chart-breakout-square.svg"
                      alt="message-circle-icon"
                    ></img>
                  </div>
                  <div className="pl-3">
                    <h2 className="fw-600">Etkileşimli oturumlara katılın</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore
                      magna...
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            {/* col button */}
            <div className="col-12 mx-auto">
              <PrimaryButton
                className="my-4 w-100 px-2"
                onClick={() => {
                  StudentHandler();
                }}
              >
                Öğrenci olarak katıl/devam et
              </PrimaryButton>
            </div>
          </div>

          {/* Teacher */}
          <div
            style={{ borderRadius: "12px" }}
            className="col col-lg-5 col-xl-3 border mx-3 p-0 shadow-md mt-4 mt-md-0 "
          >
            {/* col title */}
            <div className="d-flex flex-column align-items-start px-4 pt-4 ">
              <img
                className="mb-3"
                src="/assets/images/figma-icon/login-teacher.svg"
                alt="teacher"
              ></img>
              <div>
                <span className="font-md fw-600">Öğretmen</span>
                <p className="font-xss">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </div>
            {/* col center */}
            <div>
              <ul className="border-top border-bottom p-4">
                <li className="d-flex mb-4 ">
                  <div className="flex-shrink-0">
                    <img
                      style={{ borderRadius: "10px", width: "100%" }}
                      className="border p-2 shadow-xs img-fluid"
                      src="/assets/images/figma-icon/message-chat-circle.svg"
                      alt="message-circle-icon"
                    ></img>
                  </div>
                  <div className="pl-3">
                    <h2 className="fw-600">Öğrencilerinle canlı yayın yapın</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore
                      magna...
                    </p>
                  </div>
                </li>
                <li className="d-flex mb-4">
                  <div className="flex-shrink-0">
                    <img
                      style={{ borderRadius: "10px", width: "100%" }}
                      className="border p-2 shadow-xs img-fluid"
                      src="/assets/images/figma-icon/zap.svg"
                      alt="message-circle-icon"
                    ></img>
                  </div>
                  <div className="pl-3">
                    <h2 className="fw-600">
                      Online kurslar vererek para kazanın
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore
                      magna...
                    </p>
                  </div>
                </li>
                <li className="d-flex ">
                  <div className="flex-shrink-0">
                    <img
                      style={{ borderRadius: "10px", width: "100%" }}
                      className="border p-2 shadow-xs img-fluid"
                      src="/assets/images/figma-icon/chart-breakout-square.svg"
                      alt="message-circle-icon"
                    ></img>
                  </div>
                  <div className="pl-3">
                    <h2 className="fw-600">1:1 görüşmeler düzenleyin</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore
                      magna...
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            {/* col button */}
            <div className="col-12 mx-auto">
              <PrimaryButton
                className="my-4 w-100 px-2"
                onClick={() => {
                  TeacherHandler();
                }}
              >
                Öğretmen olarak katıl/devam et
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserType;
