import React from "react";
export type  TUserDetail = {
  location: {
    city: string;
    country: string;
  };
  experience: {
    month: number;
    year: number;
  };
  socialLink: {
    website: String;
  };
  email: String;
};

interface Props {
  userdetail?: TUserDetail;
}

const InfoPanel: React.FunctionComponent<Props> = ({ userdetail }) => {
  

  return (
    <>
      <div style={{ borderRadius: "16px" }} className="bg-light p-3">
        <ul className="d-flex flex-column text-center text-sm-left  flex-sm-row">
          {/* One of them  */}
          <li className="">
            <h6 className="text-neutral-500 fw-500 font-xsss">Location</h6>
            <p className="d-flex justify-content-center justify-sm-content-left">
              <img
                src="/assets/images/countries/au.svg"
                alt="country flag"
              ></img>
              <span className="fw-500 ml-2">
                {userdetail?.location.city},{userdetail?.location.country}
              </span>
            </p>
          </li>
          {/* One of them  */}
          <li className="ml-sm-4 mt-2 mt-sm-0">
            <h6 className="text-neutral-500 fw-500 font-xsss">Deneyim</h6>
            <span className="mr-1 fw-500">{userdetail?.experience.year} yıl</span>
          </li>

          {/* One of them  */}
          <li className="ml-sm-4 mt-2 mt-sm-0">
            <h6 className="text-neutral-500 fw-500 font-xsss">Website</h6>
            <a
              className="text-primary-600 fw-500"
              target="blank"
              href={`https://www.${userdetail?.socialLink.website}`}
            >
              {userdetail?.socialLink.website}
            </a>
          </li>

          {/* One of them  */}
          <li className="ml-sm-4 mt-2 mt-sm-0">
            <h6 className="text-neutral-500 fw-500 font-xsss">Email</h6>
            <a
              className="text-primary-600 fw-500"
              target="blank"
              href={`mailto:${userdetail?.email}`}
            >
              {userdetail?.email}
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default InfoPanel;
