import AboutPage from "../../presentation/pages/about";
import HomePage from "../../presentation/pages/home";
import RegisterPage from "../../presentation/pages/auth/view/register";
import OnBoardingPage from "../../presentation/pages/onboarding/view/onboarding";
import LoginPage from "../../presentation/pages/login/view/login_page";
import PasswordPage from "../../presentation/pages/password";

import IRoute from "../interfaces/route";
import  ProfilePage from "../../presentation/pages/profile/view/profile";
import SearchPage from "../../presentation/pages/search/view/search";
import CreateCoursePage from "../../presentation/pages/course/create-course";



const routes: IRoute[] = [
  {
    path: "/",
    name: "Home Page",
    component: HomePage,
    requireAuth: false,
    allowedRoles: ["user"],
  },
  {
    path: "/about",
    name: "About Page",
    component: AboutPage,
    requireAuth: false,
    allowedRoles: ["user"],
  },
  {
    path: "/register",
    name: "Kayıt SAyfası",
    component: RegisterPage,
    requireAuth: false,
    allowedRoles: ["user"],
  },
  {
    path: "/login",
    name: "Login Page",
    component: LoginPage,
    requireAuth: false,
    allowedRoles: ["user"],
  },
  {
    path: "/onboarding",
    name: "Onboarding Page",
    component: OnBoardingPage,
    requireAuth: false,
    allowedRoles:["Student","Teacher"]
  },
  {
    path: "/profile",
    name: "Profile Page",
    component: ProfilePage,
    requireAuth: false,
    allowedRoles:["Student","Teacher"]
  },

  {
    path: "/search",
    name: "Search Page",
    component: SearchPage,
    requireAuth: false,
    allowedRoles:["Student","Teacher"]
  },

  {
    path: "/createCourse",
    name: "Create Course Page",
    component: CreateCoursePage,
    requireAuth: false,
    allowedRoles:["Student","Teacher"]
  },



  {
    path: "/password",
    name: "Password Reset Page",
    component: PasswordPage,
    requireAuth: false,
    allowedRoles: ["user"],
    // children: [
    //   {
    //     path: "",
    //     name: "Password Reset Request",
    //     component: PasswordResetRequestPage,
    //     exact: true,
    //   },
    //   {
    //     path: "sent",
    //     name: "Password Reset Sent",
    //     component: PasswordResetSentPage,
    //     exact: true,
    //   },
    //   {
    //     path: ":token",
    //     name: "Password Reset",
    //     component: PasswordResetPage,
    //     exact: true,
    //   },
    //   {
    //     path: "success",
    //     name: "Password Reset Success",
    //     component: PasswordResetSuccessPage,
    //     exact: true,
    //   },
    // ],
  },

  // {
  //   path: "/password",
  //   name: "Password Reset Page",
  //   element: PasswordPage,

  //   children: [
  //     {
  //       path: "",
  //       name: "Password Reset Request",
  //       element: PasswordResetRequest,
  //     },
  //     {
  //       path: "sent",
  //       name: "Password Reset Sent",
  //       element: PasswordResetSent,
  //     },
  //     {
  //       path: ":token",
  //       name: "Password Reset",
  //       element: PasswordResetNav,
  //     },
  //     {
  //       path: "success",
  //       name: "Password Reset Success",
  //       element: PasswordResetSuccess,

  //     },
  //   ],
  // },
];

// const routes: IRoute[] = [
//   {
//     path: "/",
//     name: "Home Page",
//     component: HomePage,
//     exact: true,
//   },
//   {
//     path: "/about",
//     name: "About Page",
//     component: AboutPage,
//     exact: true,
//   },
//   {
//     path: "/register",
//     name: "Kayıt SAyfası",
//     component: RegisterPage,
//     exact: true,
//   },
//   {
//     path: "/login",
//     name: "Login Page",
//     component: LoginPage,
//     exact: true,
//   },
//   {
//     path: "/onboarding",
//     name: "Onboarding Page",
//     component: OnBoardingPage,
//     exact: true,
//   },

//   {
//     path: "/profile",
//     name: "Profile Page",
//     component: ProfilePage,
//     exact: true,
//   },

//   // Password Parent and Nested Routes
//   {
//     path: "/password",
//     name: "Password Reset Page",
//     component: PasswordPage,
//     exact: true,
//     // children: [
//     //   {
//     //     path: "",
//     //     name: "Password Reset Request",
//     //     component: PasswordResetRequestPage,
//     //     exact: true,
//     //   },
//     //   {
//     //     path: "sent",
//     //     name: "Password Reset Sent",
//     //     component: PasswordResetSentPage,
//     //     exact: true,
//     //   },
//     //   {
//     //     path: ":token",
//     //     name: "Password Reset",
//     //     component: PasswordResetPage,
//     //     exact: true,
//     //   },
//     //   {
//     //     path: "success",
//     //     name: "Password Reset Success",
//     //     component: PasswordResetSuccessPage,
//     //     exact: true,
//     //   },
//     // ],
//   },
// ];

export default routes;
